import React from "react"
import 'react-multi-carousel/lib/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import "./bookAppointment.css"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";
// --------------------------------------------------------

const BookAppointment = () => {

  return (
    <>
      {/* <section className='py-5 border-top supportSe'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 text-center'>
              <h3>Support Us</h3>
            </div>
            <div className='col-md-6'>
              <a className="btnBookAppointment" href="tel:+911989898989">
                +91 1989898989
              </a>
            </div>
          </div>
        </div>
      </section > */}
      <section className='py-5 border-top appointment'>
        <div className='container'>
          <div className="bookAppointment">
            <div className='row'>
              <div className='col-md-6'>
                <h1>Explore All Packages</h1>
                <div className="mt-5">
                  <Link to="/packages" className="btnBookAppointment">All Packages<ArrowForwardIcon /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  )
}

export default BookAppointment
