import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Box, Button } from '@mui/material'
import img from '../images/bannerimg.jpg'
import Back from '../common/Back'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFTextField } from '../hook-form'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import './paymentLabs.css'
import 'bootstrap/dist/css/bootstrap.css'
import { getAvailability } from '../../api/availability'
import { createLabBooking } from '../../api/bookings'
import { useParams } from 'react-router-dom'
import { getSingleVender } from '../../api/singleVendor'
// ----------------------------------------------------------------------

export default function PaymentLabs() {
  const [alignment, setAlignment] = React.useState('')
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment)
  }
  const [singleVendor, setSingleVendor] = useState([])
  const [labBooking, setLabBooking] = useState()
  const [value, setValue] = React.useState(new Date())
  const history = useHistory()
  const [availability, setAvailability] = useState([])
  const params = useParams()
  const [weekDay, setWeekDay] = useState('')
  const [isAvailabilityLoaded, setIsAvailabilityLoaded] = useState(false)
  const RegisterSchema = Yup.object().shape({
    date: Yup.string(),
  })
  const defaultValues = {
    date: '',
  }
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = methods
  const values = watch()

  useEffect(() => {
    ;(async () => {
      const labLocalBooking = JSON.parse(localStorage.getItem('bookLabs'))
      console.log('we are here')
      const { data } = await getSingleVender(labLocalBooking.vendorId)
      setSingleVendor(data)
      console.log(data)
    })()
  }, [])

  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    ;(async () => {
      const labLocalBooking = JSON.parse(localStorage.getItem('bookLabs'))
      const { data } = await getAvailability({
        month: currentMonth,
        year: currentYear,
        vendorId: labLocalBooking.vendorId,
      })
      setAvailability(data)
      setIsAvailabilityLoaded(true)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const labLocalBooking = JSON.parse(localStorage.getItem('bookLabs'))
      setLabBooking(labLocalBooking)
    })()
  }, [])

  const weekSlot = singleVendor?.weekSlots?.find(
    (weekSlot) => weekSlot.day === weekDay,
  )

  console.log(weekSlot)

  const setSlotSelect = (from, to) => {
    // console.log('function reached')
    const isUnavailable = availability.find((unavaialabledate) => {
      return (
        new Date(unavaialabledate).toLocaleDateString() ===
        new Date(value).toLocaleDateString()
      )
    })
    if (isUnavailable) {
      alert('Date is not available')
      return
    }
    const date = new Date(value).toLocaleDateString('sv-SE')
    const slotFrom = new Date(date + 'T' + from + ':00Z')
    const slotTo = new Date(date + 'T' + to + ':00Z')

    console.log(slotFrom)

    setLabBooking({
      ...labBooking,
      slotFrom: slotFrom,
      slotTo: slotTo,
      sampleCollectionScheduleTime: slotFrom,
    })
  }

  const onSubmit = async (data) => {
    console.log(labBooking)
    if (labBooking.sampleCollectionScheduleTime) {
      const response = await createLabBooking(labBooking)
      if (response?.code === 201) {
        alert('Booking Successful')
        history.push('bookingHistory')
      } else {
        alert(response.message)
      }
    } else {
      alert('Please Select Slot')
    }
  }

  return (
    <section className="signInn">
      <Back title="Payment" cover={img} />
      <section className="formBg">
        <div className="container row justify-content-center">
          <div className="col-md-6 b-x p-4 register_page">
            <div className="maintitle pb-3">Payment</div>
            <p>Welcome back ! Please enter your details for continue</p>
            <Box sx={{ width: '100%' }}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3} className="py-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                                            onMonthChange={(date) => {
                        const month = new Date(date).getMonth() + 1
                        const year = new Date(date).getFullYear()
                        console.log(month, year)
                        ;(async () => {
                          const { data } = await getAvailability({
                            month: month,
                            year: year,
                            vendorId: labBooking.vendorId,
                          })
                          setAvailability(data)
                        })()
                      }}
                      openTo="day"
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue)
                        console.log(newValue)
                        console.log(new Date(newValue).getDay())
                        setWeekDay(
                          new Date(newValue).toLocaleString('en-us', {
                            weekday: 'long',
                          }),
                        )
                      }}
                      disablePast
                      shouldDisableDate={(date) => {
                        return availability.find(
                          (unavaialabledate) =>
                            new Date(unavaialabledate).toLocaleDateString() ===
                            new Date(date).toLocaleDateString(),
                        )
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    className="d-flex align-items-center justify-content-between"
                    onChange={handleChange}
                    aria-label="Platform"
                  >
                    <ToggleButton
                      value="slot1"
                      className="slotS"
                      // disabled={
                      //   new Date().getHours() * 60 + new Date().getMinutes() >
                      //   parseInt(weekSlot?.daySlots[0]?.to.split(':')[0]) * 60 +
                      //     parseInt(weekSlot?.daySlots[0]?.to.split(':')[1]) || !isAvailabilityLoaded
                      // }
                      disabled={!isAvailabilityLoaded}
                      onClick={() => {
                        setSlotSelect(
                          weekSlot?.daySlots[0]?.from,
                          weekSlot?.daySlots[0]?.to,
                        )
                      }}
                    >
                      {weekSlot?.daySlots[0]?.from} -{' '}
                      {weekSlot?.daySlots[0]?.to}
                    </ToggleButton>
                    <ToggleButton
                      value="slot2"
                      className="slotS"
                      disabled={!isAvailabilityLoaded}
                      // disabled={
                      //   new Date().getHours() * 60 + new Date().getMinutes() >
                      //   parseInt(weekSlot?.daySlots[1]?.to.split(':')[0]) * 60 +
                      //     parseInt(weekSlot?.daySlots[1]?.to.split(':')[1]) || !isAvailabilityLoaded
                      // }
                      onClick={() => {
                        setSlotSelect(
                          weekSlot?.daySlots[1]?.from,
                          weekSlot?.daySlots[1]?.to,
                        )
                      }}
                    >
                      {weekSlot?.daySlots[1]?.from} -{' '}
                      {weekSlot?.daySlots[1]?.to}
                    </ToggleButton>
                    {/* {weekSlot?.daySlots?.map((slot, index) => {
                        return (
                          <>
                            <ToggleButton
                              value={`${index}`}       
                              onClick={() => { 
                               
                                setSlotSelect(slot.from, slot.to)
                               }}
                            >
                              {slot.from} - {slot.to}
                            </ToggleButton>
                          </>
                        )
                      })} */}
                  </ToggleButtonGroup>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    className="signBtn"
                    loading={!isAvailabilityLoaded || isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </FormProvider>
            </Box>
          </div>
        </div>
      </section>
    </section>
  )
}
