import React from 'react'
import Header from '../common/header/Header'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from '../home/Home'
import Footer from '../common/footer/Footer'
import About from '../about/About'
import CheckOffers from '../checkOffers/CheckOffers'
import SignRegister from '../signRegister/SignRegister'
import SignIn from '../signIn/SignIn'
import Payment from '../payment/Payment'
import PaymentClinic from '../paymentClinic/PaymentClinic'
import PaymentAyurveda from '../paymentAyurveda/PaymentAyurveda'
import PaymentLabs from '../paymentLabs/PaymentLabs'
import PaymentPackage from '../paymentPackage/PaymentPackage'
import ForgotPassword from '../common/forgotPassword/ForgotPassword'
import ChangePassword from '../common/changePassword/ChangePassword'
import ChooseHospital from '../chooseHospital/ChooseHospital'
import HospitalDetailPage from '../hospitalDetailPage/HospitalDetailPage'
import CompareHospital from '../compareHospital/compareHospital'
import ClinicBooking from '../clinicBooking/ClinicBooking'
import PackageBooking from '../packageBooking/PackageBooking'
import AyurvedaBooking from '../ayurvedaBooking/AyurvedaBooking'
import LabBooking from '../labBooking/LabBooking'
import SurgeryBooking from '../surgeryBooking/SurgeryBooking'
import OrderProcess from '../orderProcess/OrderProcess'
import BookingHistory from '../bookingHistory/BookingHistory'
// import BookingHistoryCopy from "../bookingHistory/BookingHistoryCopy"
import EditProfile from '../editProfile/EditProfile'
import TestListing from '../testListing/TestListing'
import Packages from '../packages/Packages'
import LabsListing from '../labsListing/LabsListing'
import LabDetailPage from '../labDetailPage/LabDetailPage'
import RadiologyListing from '../radiologyListing/RadiologyListing'
import BookRadiologyTests from '../bookRadiologyTests/BookRadiologyTests'
import RadiologyDetailPage from '../radiologyDetailPage/RadiologyDetailPage'
import Clinic from '../clinic/Clinic'
import ClinicDetailPage from '../clinicDetailPage/ClinicDetailPage'
import Ayurveda from '../ayurveda/Ayurveda'
import AyurvedaDetailPage from '../ayurvedaDetailPage/AyurvedaDetailPage'
import BloodBank from '../bloodBank/BloodBank'
import JoinUs from '../joinUs/JoinUs'
import PrivacyPolicy from '../privacyPolicy/PrivacyPolicy'
import TermsConditions from '../termsConditions/TermsConditions'
import Faq from '../faq/Faq'
import HowToWork from '../howToWork/HowToWork'
import Blog from '../blog/Blog'
import RefundPolicy from '../refundPolicy/RefundPolicy'
import DeleteAccount from '../editProfile/DeleteAccount'

const Pages = () => {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/checkOffers" component={CheckOffers} />
          <Route exact path="/signRegister" component={SignRegister} />
          <Route exact path="/signIn" component={SignIn} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/paymentClinic" component={PaymentClinic} />
          <Route exact path="/paymentAyurveda" component={PaymentAyurveda} />
          <Route exact path="/paymentLabs" component={PaymentLabs} />
          <Route exact path="/paymentPackage" component={PaymentPackage} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route exact path="/changePassword/" component={ChangePassword} />
          <Route
            exact
            path="/changePassword/:token"
            component={ChangePassword}
          />
          <Route exact path="/chooseHospital" component={ChooseHospital} />
          <Route exact path="/chooseHospital/:id" component={ChooseHospital} />
          <Route
            exact
            path="/hospitalDetailPage"
            component={HospitalDetailPage}
          />
          <Route
            exact
            path="/hospitalDetailPage/:id"
            component={HospitalDetailPage}
          />
          <Route
            exact
            path="/hospitalDetailPage/:id/:id"
            component={HospitalDetailPage}
          />
          <Route exact path="/compareHospital" component={CompareHospital} />
          <Route exact path="/clinicBooking" component={ClinicBooking} />
          <Route exact path="/packageBooking" component={PackageBooking} />
          <Route exact path="/ayurvedaBooking" component={AyurvedaBooking} />
          <Route exact path="/labBooking" component={LabBooking} />
          <Route exact path="/surgeryBooking" component={SurgeryBooking} />
          <Route exact path="/orderProcess" component={OrderProcess} />
          <Route exact path="/bookingHistory" component={BookingHistory} />
          {/* <Route exact path='/bookingHistoryCopy' component={BookingHistoryCopy} /> */}
          <Route exact path="/editProfile" component={EditProfile} />
          <Route exact path="/testListing" component={TestListing} />
          <Route exact path="/packages" component={Packages} />
          <Route exact path="/labsListing" component={LabsListing} />
          <Route exact path="/labsListing/:id" component={LabsListing} />
          <Route exact path="/labDetailPage" component={LabDetailPage} />
          <Route exact path="/labDetailPage/:id" component={LabDetailPage} />
          <Route exact path="/radiologyListing" component={RadiologyListing} />
          <Route
            exact
            path="/bookRadiologyTests"
            component={BookRadiologyTests}
          />
          <Route
            exact
            path="/RadiologyDetailPage"
            component={RadiologyDetailPage}
          />
          <Route
            exact
            path="/RadiologyDetailPage/:id"
            component={RadiologyDetailPage}
          />
          <Route exact path="/clinic" component={Clinic} />
          <Route exact path="/clinicDetailPage" component={ClinicDetailPage} />
          <Route
            exact
            path="/clinicDetailPage/:id"
            component={ClinicDetailPage}
          />
          <Route exact path="/ayurveda" component={Ayurveda} />
          <Route
            exact
            path="/ayurvedaDetailPage"
            component={AyurvedaDetailPage}
          />
          <Route
            exact
            path="/ayurvedaDetailPage/:id"
            component={AyurvedaDetailPage}
          />
          <Route exact path="/bloodBank" component={BloodBank} />
          <Route exact path="/joinUs" component={JoinUs} />
          <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/termsConditions" component={TermsConditions} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/howToWork" component={HowToWork} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/refundPolicy" component={RefundPolicy} />
          <Route exact path="/deleteAccount/:token" component={DeleteAccount} />
          <Route exact path="/deleteAccount" component={DeleteAccount} />
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default Pages
