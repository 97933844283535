import { api } from '../utils/request'

export const generateOtp = async (mobile) => {
  const res = await api({
    endpoint: `/otp/generateOtp`,
    method: 'POST',
    data: { mobile },
  })
  return res
}

export const login = async ( dataLogin, otp) => {
  console.log(dataLogin)
  const res = await api({
    endpoint: `/users/login`,
    method: 'POST',
    data: dataLogin,
  })
  return res
}


export const deleteAccount = async (token) => {
  const res = await api({
    endpoint: `/users/deleteAccount`,
    method: 'DELETE',
    query: {token:token}
  })
  return res
}
