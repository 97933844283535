import { api } from '../utils/request';

export const getSurgery = async (query = {}) => {
    const res = await api({ endpoint: '/surgery', method: 'GET', query });
    return res;
};

export const getSurgeryById = async (id) => {
    const res = await api({ endpoint: `/surgery/${id}`, method: 'GET' });
    return res;
}