import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, TextField } from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import img from '../images/bannerimg.jpg'
import Autocomplete from '@mui/lab/Autocomplete'
import Back from '../common/Back'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFTextField, RHFSelect } from '../hook-form'
import './labBooking.css'
import 'bootstrap/dist/css/bootstrap.css'
import {
  getDistrictByState,
  getCityByDistrict,
  getAllState,
} from '../../api/locationApi'
import { createLabBooking } from '../../api/bookings'
import FormLabel from '@mui/material/FormLabel'
// ----------------------------------------------------------------------
const gender = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
]

export default function LabBooking() {
  const history = useHistory()
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [showError, setShowError] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const labBooking = JSON.parse(localStorage.getItem('labBooking'))
  const bookLabs = JSON.parse(localStorage.getItem('bookLabs'))
  const homeSampleCollection = JSON.parse(
    localStorage.getItem('homeSampleCollection'),
  )
  const labSampleCollection = JSON.parse(
    localStorage.getItem('labVisitCollection'),
  )
  const radiology = JSON.parse(localStorage.getItem('radiology'))

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Patient name is required'),
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    gender: Yup.string().required('Please select Gender'),
    age: Yup.number()
      .positive()
      .min(1, 'please enter age more than 0')
      .max(100, 'please enter age below 100')
      .required('Age is required'),
    address: Yup.object().shape({
      houseNo: Yup.string(),
      streetNo: Yup.string(),
      locality: Yup.string(),
      landmark: Yup.string(),
      city: Yup.string(),
      district: Yup.string(),
      state: Yup.string(),
      pinCode: Yup.number()
        .positive()
        .min(100000, 'Please enter valid PinCode')
        .max(999999, 'Please enter valid PinCode')
        .required('PinCode is required'),
    }),
    treatment: Yup.string(),
    price: Yup.string(),
    consultationTime: Yup.string(),
    status: Yup.string(),
    paymentStatus: Yup.string(),
    couponCode: Yup.string(),
    remark: Yup.string(),
    discountPrice: Yup.string(),
    vendorId: Yup.string(),
    referBy: Yup.string(),
    refersName: Yup.string(),
  })

  const defaultValues = {
    name: bookLabs?.name || '',
    mobile: bookLabs?.mobile || '',
    gender: bookLabs?.gender || '',
    age: bookLabs?.age || '',
    address: {
      houseNo: bookLabs?.address?.houseNo || '',
      streetNo: bookLabs?.address?.streetNo || '',
      locality: bookLabs?.address?.locality || '',
      landmark: bookLabs?.address?.landmark || '',
      city: bookLabs?.address?.city || '',
      district: bookLabs?.address?.district || '',
      state: bookLabs?.address?.state || '',
      pinCode: bookLabs?.address?.pinCode || 0,
    },
    pathLabTestId: labBooking?.pathLabTestId,
    sampleCollectionMode: labBooking?.sampleCollectionMode,
    referBy: 'Self',
    refersName: 'Self',
    sampleCollectionScheduleTime: '',
    price: labBooking?.price,
    consultationTime: '',
    status: 'pending',
    paymentStatus: 'unpaid',
    paymentType: 'postpaid',
    couponCode: '',
    remark: '',
    discountPrice: labBooking?.discountPrice,
    vendorId: labBooking?.vendorId,
    slotTo: '',
    slotFrom: '',
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,

    formState: { isSubmitting },
  } = methods
  const values = watch()

  useEffect(() => {
    ;(async () => {
      const { data } = await getAllState()
      setStateList(data.docs)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const { data: district } = await getDistrictByState(values.address.state)
      setDistrictList(district.docs)
    })()
  }, [values.address.state])

  useEffect(() => {
    ;(async () => {
      const { data: city } = await getCityByDistrict(values.address.district)
      setCityList(city.docs)
    })()
  }, [values.address.district])

  const onSubmit = async (data) => {
    if (
      data.address.state === '' ||
      data.address.district === '' ||
      data.address.city === ''
    ) {
      alert('Please select state, district and city')
      return
    }
    localStorage.setItem('bookLabs', JSON.stringify(data))
    history.push('/paymentLabs')
  }
  const handleChange = (event) => {
    setValue('sampleCollectionMode', event.target.value)
  }

  const handleChangeReferBy = (event) => {
    setValue('referBy', event.target.value)
  }

  const stateOptionsList = stateList.map((state) => ({
    label: state.name,
    value: state.name,
  }))
  const districtOptionsList = districtList.map((district) => ({
    label: district.name,
    value: district.name,
  }))
  const cityOptionsList = cityList.map((city) => ({
    label: city.name,
    value: city.name,
  }))

  return (
    <>
      <Back name="Lab Booking" title="Lab Booking" cover={img} />
      <section className="formBg py-5">
        <div className="container row justify-content-center">
          <div className="col-md-12 b-x p-5 register_page">
            <Box sx={{ width: '100%' }}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <h4 className="paymentTitle">Fill the Basic details</h4>
                  <Stack direction="row" spacing={2}>
                    <Stack spacing={2} sx={{ width: '70%' }}>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="name"
                          label="Patient Name"
                        />
                        <RHFTextField
                          size="small"
                          name="mobile"
                          label="Mobile No"
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFSelect
                          size="small"
                          name="gender"
                          label="Gender"
                          placeholder="Gender"
                        >
                          <option value="" />
                          {gender.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFSelect>
                        <RHFTextField
                          size="small"
                          type="number"
                          name="age"
                          label="Age"
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="address.houseNo"
                          label="Address Line 1"
                        />
                        <RHFTextField
                          size="small"
                          name="address.streetNo"
                          label="Address Line 2"
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="address.landmark"
                          label="Landmark"
                        />
                        <Autocomplete
                          name="address.state"
                          size="small"
                          id="combo-box-demo"
                          options={stateOptionsList}
                          key={stateOptionsList.length}
                          value={stateOptionsList.find(
                            (option) => option.value === values.address.state,
                          )}
                          // getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            setValue('address.state', newValue.value)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="State" />
                          )}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Autocomplete
                          name="address.district"
                          size="small"
                          id="combo-box-demo"
                          key={districtOptionsList.length + 1}
                          options={districtOptionsList}
                          value={districtOptionsList.find(
                            (option) =>
                              option.value === values.address.district,
                          )}
                          onChange={(event, newValue) => {
                            setValue('address.district', newValue.value)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="District" />
                          )}
                        />
                        <Autocomplete
                          name="address.city"
                          size="small"
                          id="combo-box-demo"
                          options={cityOptionsList}
                          key={cityOptionsList.length}
                          value={cityOptionsList.find(
                            (option) => option.value === values.address.city,
                          )}
                          onChange={(event, newValue) => {
                            setValue('address.city', newValue.value)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="Area" />
                          )}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          type="number"
                          name="address.pinCode"
                          label="PinCode"
                        />
                        <RHFTextField
                          size="small"
                          name="address.locality"
                          label="Locality"
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <FormControl>
                          <FormLabel id="demo-controlled-radio-buttons-group">
                            Refer By
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            row
                            value={values.referBy}
                            onChange={handleChangeReferBy}
                          >
                            <FormControlLabel
                              value="Self"
                              control={<Radio />}
                              label="Self"
                            />

                            <FormControlLabel
                              value="Doctor/Hospital"
                              control={<Radio />}
                              label="Doctor/Hospital"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                      {values.referBy === 'Doctor/Hospital' && (
                        <Stack direction="row" spacing={2}>
                          <RHFTextField
                            size="small"
                            name="refersName"
                            label="Refers Name"
                          />
                        </Stack>
                      )}
                      <Stack direction="row" spacing={2}>
                        <FormControl>
                          <FormLabel id="demo-controlled-radio-buttons-group">
                            Booking Type
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={values.sampleCollectionMode}
                            onChange={handleChange}
                          >
                            {homeSampleCollection && !radiology ? (
                              <FormControlLabel
                                value="home pick up"
                                control={<Radio />}
                                label="Home"
                              />
                            ) : null}

                            {labSampleCollection ? (
                              <FormControlLabel
                                value="lab visit"
                                control={<Radio />}
                                label="Lab Visit"
                              />
                            ) : null}
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                    </Stack>
                    <Stack spacing={2}>
                      <div className="totalPaySec">
                        <h4>Tests</h4>
                        <hr></hr>
                        <div className="totalPay">
                          <h6>Total Payable</h6>
                          <h5>₹ {labBooking.price}</h5>
                        </div>
                      </div>
                    </Stack>
                  </Stack>

                  <h4 className="paymentTitle">Payment Option</h4>

                  <FormControl className="m-0">
                    <RadioGroup
                      defaultValue="postpaid"
                      value={values.paymentType}
                      row
                    >
                      {/* <FormControlLabel value="payNow" control={<Radio />} label="Pay Now" /> */}
                      <FormControlLabel
                        value="postpaid"
                        name="paymentType"
                        control={<Radio />}
                        label="Cash On Lab (COL) "
                      />
                      {/* <FormControlLabel
                        value="prepaid"
                        name='paymentType'
                        control={<Radio />}
                        label="Pay Now"
                      /> */}
                    </RadioGroup>
                  </FormControl>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    className="signBtn"
                    loading={isSubmitting}
                  >
                    Book Now
                  </LoadingButton>
                </Stack>
              </FormProvider>
            </Box>
          </div>
        </div>
      </section>
    </>
  )
}
