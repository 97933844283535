import React from "react"
import Back from "../common/Back"
import img from "../images/bannerimg.jpg"
import "./orderProcess.css"

const OrderProcess = () => {
  return (
    <>
      <section className='orderProcess'>
        <Back name='Order process' title='Order process' cover={img} />
        <div className='container py-5'>
          <div className='row justify-content-center'>
            <div className='col-md-6'>
              <div className='orderProcess__content'>
                <img src='/images/order_process.svg' alt='orderProcess' />
                <p>Your order has been assigned to the<br></br> respective hospital</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OrderProcess
