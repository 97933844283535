import React from "react";
import Back from "../common/Back";
import img from "../images/bannerimg.jpg";
import "./compareHospital.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
// --------------------------------------------------------

const CompareHospital = () => {
  // console.log('compareHospitals', JSON.stringify(compareHospitals));
  const [attributeNameObj, setAttributeNameObj] = useState(null);
  const [extraPriceFirstHospital, setExtraPriceFirstHospital] = useState({});
  const [extraPriceSecondHospital, setExtraPriceSecondHospital] = useState({});
  const [extraPriceThirdHospital, setExtraPriceThirdHospital] = useState({});
  const [hospitalNames, setHospitalNames] = useState([]);
  const [isloading, setIsLoading] = useState(true)
  console.log("extraPriceFirstHospital", extraPriceFirstHospital);
  console.log("extraPriceSecondHospital", extraPriceSecondHospital);
  console.log("extraPriceThirdHospital", extraPriceThirdHospital);

  useEffect(() => {
    const compareHospital = JSON.parse(
      localStorage.getItem("compareHospitals")
    );
    const firsthospital = compareHospital[0];
    const secondhospital = compareHospital[1];
    const thirdhospital = compareHospital[2];

    setHospitalNames([
      firsthospital?.name,
      secondhospital?.name,
      thirdhospital?.name,
    ]);

    const maxAttributeCount = Math.max(
      firsthospital?.hospitalSurgeryDetails?.hospitalSurgeryAttributeData
        ?.length || 0,
      secondhospital?.hospitalSurgeryDetails?.hospitalSurgeryAttributeData
        ?.length || 0,
      thirdhospital?.hospitalSurgeryDetails?.hospitalSurgeryAttributeData
        ?.length || 0
    );
    const attributeNameObj = {};

    for (let i = 0; i < maxAttributeCount; i++) {
      const firstHospitalAttribute =
        firsthospital?.hospitalSurgeryDetails?.hospitalSurgeryAttributeData?.[
        i
        ];
      const secondHospitalAttribute =
        secondhospital?.hospitalSurgeryDetails?.hospitalSurgeryAttributeData?.[
        i
        ];
      const thirdHospitalAttribute =
        thirdhospital?.hospitalSurgeryDetails?.hospitalSurgeryAttributeData?.[
        i
        ];

      console.log("firstHospitalAttribute", firstHospitalAttribute);
      console.log("secondHospitalAttribute", secondHospitalAttribute);
      console.log("thirdHospitalAttribute", thirdHospitalAttribute);

      if (firstHospitalAttribute) {
        if (
          !attributeNameObj[firstHospitalAttribute.surgeryAttributeData.name]
        ) {
          attributeNameObj[firstHospitalAttribute.surgeryAttributeData.name] =
            [];
        }
        attributeNameObj[firstHospitalAttribute.surgeryAttributeData.name][0] =
          attributeNameObj[
          firstHospitalAttribute.surgeryAttributeData.name
          ][0] || [];
        attributeNameObj[
          firstHospitalAttribute.surgeryAttributeData.name
        ][0].push({
          value: firstHospitalAttribute.dataValues,
          price: firstHospitalAttribute.price || 0,
        });
      }
      if (secondHospitalAttribute) {
        if (
          !attributeNameObj[secondHospitalAttribute.surgeryAttributeData.name]
        ) {
          attributeNameObj[secondHospitalAttribute.surgeryAttributeData.name] =
            [];
        }
        attributeNameObj[secondHospitalAttribute.surgeryAttributeData.name][1] =
          attributeNameObj[
          secondHospitalAttribute.surgeryAttributeData.name
          ][1] || [];
        attributeNameObj[
          secondHospitalAttribute.surgeryAttributeData.name
        ][1].push({
          value: secondHospitalAttribute.dataValues,
          price: secondHospitalAttribute.price || 0,
        });
      }
      if (thirdHospitalAttribute) {
        if (
          !attributeNameObj[thirdHospitalAttribute.surgeryAttributeData.name]
        ) {
          attributeNameObj[thirdHospitalAttribute.surgeryAttributeData.name] =
            [];
        }
        attributeNameObj[thirdHospitalAttribute.surgeryAttributeData.name][2] =
          attributeNameObj[
          thirdHospitalAttribute.surgeryAttributeData.name
          ][2] || [];
        attributeNameObj[
          thirdHospitalAttribute.surgeryAttributeData.name
        ][2].push({
          value: thirdHospitalAttribute.dataValues,
          price: thirdHospitalAttribute.price || 0,
        });
      }
    }

    console.log("attributeNameObj", JSON.stringify(attributeNameObj));

    setAttributeNameObj(attributeNameObj);
    setIsLoading(false)
  }, []);

  return (
    <>
      <section className="compareHospital">
        <Back name="Compare Hospital" title="Compare Hospital" cover={img} />
        {isloading ? <LoadingPage /> :
          <div className="container py-5">
            <div className="text-center">
              <h3 className="pageTitle">You are comparing:</h3>
            </div>

            <TableContainer component={Paper} sx={{ mt: 5 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong></strong>
                    </TableCell>
                    <TableCell align="center">
                      <h5 key={"1"} className="hospitalName">
                        <strong>{hospitalNames[0]}</strong>
                      </h5>
                    </TableCell>
                    <TableCell align="center">
                      <h5 key={"2"} className="hospitalName">
                        <strong>{hospitalNames[1]}</strong>
                      </h5>
                    </TableCell>
                    <TableCell align="center">
                      <h5 key={"3"} className="hospitalName">
                        <strong>{hospitalNames[2]}</strong>
                      </h5>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attributeNameObj &&
                    /**attributeNameObj {"Private Room":[[{"value":"1","price":0},{"value":"2","price":1000}],[{"value":"1","price":0},{"value":"2","price":10000}]],"Price":[[{"value":"100000","price":0}],[{"value":"10000","price":0}]],"Admit Days ":[[{"value":"2","price":0}],[{"value":"3","price":0}]],"Medicine Included":[[{"value":"Yes","price":0}],[{"value":"Yes","price":1000}]]} */
                    Object.keys(attributeNameObj)
                      .filter((value) => value != "Price")
                      .map((key, index) => {
                        const firstHospitalAttribute =
                          attributeNameObj[key]?.[0]?.[0];
                        const secondHospitalAttribute =
                          attributeNameObj[key]?.[1]?.[0];
                        const thirdHospitalAttribute =
                          attributeNameObj[key]?.[2]?.[0];

                        return (
                          <TableRow
                            hover
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell className="p-3" scope="row">
                              {key}
                            </TableCell>
                            <TableCell align="center">
                              {attributeNameObj[key]?.[0]?.length > 1 ? (
                                <select
                                  className="comp"
                                  defaultValue={0}
                                  onChange={(e) => {
                                    setExtraPriceFirstHospital({
                                      ...extraPriceFirstHospital,
                                      [key]: e.target.value,
                                    });
                                  }}
                                >
                                  {attributeNameObj[key]?.[0]?.map(
                                    (attribute, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={attribute.price}
                                        >
                                          {attribute.value}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              ) : (
                                <span>{firstHospitalAttribute?.value}</span>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {attributeNameObj[key]?.[1]?.length > 1 ? (
                                <select
                                  className="comp"
                                  defaultValue={0}
                                  onChange={(e) => {
                                    setExtraPriceSecondHospital({
                                      ...extraPriceSecondHospital,
                                      [key]: e.target.value,
                                    });
                                  }}
                                >
                                  {attributeNameObj[key]?.[1]?.map(
                                    (attribute, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={attribute.price}
                                        >
                                          {attribute.value}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              ) : (
                                <span>{secondHospitalAttribute?.value}</span>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {attributeNameObj[key]?.[2]?.length > 1 ? (
                                <select
                                  className="comp"
                                  defaultValue={0}
                                  onChange={(e) => {
                                    setExtraPriceThirdHospital({
                                      ...extraPriceThirdHospital,
                                      [key]: e.target.value,
                                    });
                                  }}
                                >
                                  {attributeNameObj[key]?.[2]?.map(
                                    (attribute, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={attribute.price}
                                        >
                                          {attribute.value}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              ) : (
                                <span>{thirdHospitalAttribute?.value}</span>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {attributeNameObj &&
                    Object.keys(attributeNameObj)
                      .filter((value) => value == "Price")
                      .map((key, index) => {
                        const firstHospitalAttribute =
                          attributeNameObj[key]?.[0]?.[0];
                        const secondHospitalAttribute =
                          attributeNameObj[key]?.[1]?.[0];
                        const thirdHospitalAttribute =
                          attributeNameObj[key]?.[2]?.[0];

                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell className="p-3" scope="row">
                              {key}
                            </TableCell>
                            <TableCell align="center">
                              <span>
                                {Number(firstHospitalAttribute?.value) +
                                  Object.values(extraPriceFirstHospital).reduce(
                                    (total, item) => Number(total) + Number(item),
                                    0
                                  )}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span>
                                {secondHospitalAttribute?.value
                                  ? Number(secondHospitalAttribute?.value) +
                                  Object.values(
                                    extraPriceSecondHospital
                                  ).reduce(
                                    (total, item) =>
                                      Number(total) + Number(item),
                                    0
                                  )
                                  : null}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span>
                                {thirdHospitalAttribute?.value
                                  ? Number(thirdHospitalAttribute?.value) +
                                  Object.values(extraPriceThirdHospital).reduce(
                                    (total, item) =>
                                      Number(total) + Number(item),
                                    0
                                  )
                                  : null}
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        }
      </section>
    </>
  );
};
const LoadingPage = () => {
  return (
    <div style={{
      display: 'Grid',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src='../../../../images/giphy.gif' alt='' />
    </div>

  )
}
export default CompareHospital;

// --------------------------------------------------------
