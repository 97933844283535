import React from 'react'
import 'react-multi-carousel/lib/styles.css'
import 'bootstrap/dist/css/bootstrap.css'
import './findClinic.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Link } from 'react-router-dom'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { TextField, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
// --------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  outline: 'none',
}
const FindClinic = () => {
  const history = useHistory()
  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <section className="py-5 findClinic border-top">
        <div className="container">
          {/* <h2 className="title_h pb-5">Our best services for you<span></span></h2> */}
          <div className="row">
            <div className="col-md-6">
              <div className="findSection_img">
                <img src={'../../../../images/findClinic.png'} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="findSection mt-3">
                <div className="mainTitle">
                  <span>
                    <img src={'../../../../images/line.png'} alt="" />
                    How We Work
                  </span>
                  <h2>
                    We have a broad and hassle-free approach for our each and
                    every patient.
                  </h2>
                </div>
                <p className="findSection_p">
                  <ul className="findUl py-3">
                    <li>
                      You can download our android app from – Google Play Store
                      and IOS Store (Apple User) to avail our services
                    </li>
                    <li>
                      You can visit our website{' '}
                      <a href="https://www.clickyourhospital.com">
                        {' '}
                        www.clickyourhospital.com{' '}
                      </a>
                      to avail our services
                    </li>
                    <li>
                      You can share your medical records diagnostic reports
                      through WhatsApp on- 813-097-2217 or email on –
                      support@clickyourhospital.com.
                    </li>
                    <li>
                      Within 24-48 hours we will connect you and share multiple
                      treatment plans approx. cost with you from our network
                      hospitals.
                    </li>
                    <li>
                      You can choose the hospital of your choice, post that we
                      make arrangement for your admission in the hospital.
                    </li>
                  </ul>
                </p>
                <ul className="findUl py-3">
                  <li>Save on every surgery or treatment.</li>
                  <li>Top-searched clinics</li>
                </ul>
                <Box
                  sx={{
                    width: 'fit-content',
                    backgroundColor: '#009EE0',
                    border: '1px solid #009EE0',
                    borderRadius: '0px 15px 15px 15px',
                    padding: '10px 20px',
                    color: '#fff',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: '#009EE0',
                    },
                  }}
                  onClick={() => {
                    if (localStorage.getItem('selectLocation')) {
                      history.push(`/clinic`)
                    } else {
                      handleOpenModal()
                    }
                  }}
                >
                  Find Clinic
                  <ArrowForwardIcon />
                </Box>
                {/* <Link to="/clinic" className="btnFindMore">Find Clinic <ArrowForwardIcon /></Link> */}
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                cursor: 'pointer',
                p: 1,
                alignItems: 'center',
              }}
            >
              <Typography variant={'h5'}>Please Select Location </Typography>
              <Button
                sx={{
                  mt: 2,
                  width: '100px',
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleCloseModal()
                }}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Modal>
      </section>
    </>
  )
}

export default FindClinic
