import React from "react"
import img from "../images/bannerimg.jpg"
import Back from "../common/Back"
import "./checkOffers.css"

const CheckOffers = () => {
  return (
    <>
      <section className='contact'>
        <Back title='Check Offers' cover={img} />
        <div className='container py-4'>
          {/* <div className='shadow contactPage'>
            <div className="contact-con ">
              <div className="flex">
                <div className="col">
                  <figure>
                    <img src={"../../../images/call.svg"} alt='' className="w100" />
                  </figure>
                  <div>
                    <h3 className="h3">Call Us</h3>
                    <p className="lead">Reach us on our toll-free</p>
                    <a href="tel:+918130972217">+91 813-097-2217</a></div>
                </div>
                <div className="col">
                  <figure>
                    <img src={"../../../images/email.svg"} alt='' className="w100" />
                  </figure>
                  <div>
                    <h3 className="h3">Email Us</h3>
                    <p className="lead">Write to us at</p>
                    <a href="mailto:support@clickyourhospital.com">support@clickyourhospital.com</a></div>
                </div>
              </div>
            </div>
            <div className="aside" xss="removed">
              <h4 className="h4">Our Address</h4>
              <div> Faridabad<br></br>
                Haryana - 201010</div>
            </div>
          </div> */}
          <div className="row">
            <div class="col-md-8 heading">
              <h1>Offers and discounts is subject to new update form (of) our empanelled Hospital/Lab/Clinic etc.</h1>
              <ul className="mt-3 listCheckOffer">
                <li>To check offers/discounts please WhatsApp us on
                  <a href="https://wa.me/8130972217" target="_blank" className="mx-2">
                    813-097-2217
                  </a><br></br>
                  “Write your surgery/disease name and city/district and send us.<br></br>
                  “We will respond you soon with attractive and handsome offers discount for you.
                </li>
                <li>For More offers details please download our app via – Google Play Store and IOS apple store.</li>
                <li>You can follow and explorer our Facebook and Instagram page <strong>(CLICKYOURHOSPITAL.COM)</strong> to get new and attractive offers. Our all offers are reflecting on our social media page first.</li>
                <li>You Can save our official WhatsApp no. in your phone and explorer our WhatsApp catalogue or you can send request to find our catalogue. </li>
                <li>You can call our support team to know about our all offers and discount.</li>
              </ul>
            </div>
            <div className="col-md-4 p-5">
              <img src={"../../../images/special-offer.png"} alt='' className="w100" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CheckOffers
