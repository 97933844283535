import { api } from '../utils/request';

export const editMyProfile = async (data) => {
  const res = await api({ endpoint: `/users/editProfile`, method: 'PUT', data });
  return res;
};

export const myProfile = async ()=>{
  const res = await api({endpoint:'/users/myProfile', method:  'GET'});
  return res;
}