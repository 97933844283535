import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Back from '../common/Back'
import img from '../images/bannerimg.jpg'
import './ayurveda.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import VerifiedIcon from '@mui/icons-material/Verified'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { getAllTreatment } from '../../api/treatment'
import { getAllVendorByTreatment } from '../../api/vendorByTreatment'
import { getAllBanner } from '../../api/banner'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import { styled, useTheme } from '@mui/material/styles'
import {
  IconButton,
  InputAdornment,
  Stack,
  Slider,
  Pagination,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Autocomplete,
  Typography,
} from '@mui/material'
import { getPincode } from '../../api/getPincode'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { message } from '../../utils/message'
// ------------------------------------------------------

const limit = 5
const drawerWidth = 400

// reduce width of drawer
const Drawer = styled(
  MuiDrawer,
  {},
)(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    // position: "relative",
    // whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
      }),
      width: 0,

      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}))

const Ayurveda = () => {
  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setOpen(false)
  }
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    localStorage.removeItem('surgeryPrice')
  }, [])
  const [vendorByTreatment, setVendorByTreatment] = useState([])
  const [selectLocation, setSelectLocation] = useState(
    JSON.parse(localStorage.getItem('selectLocation')),
  )

  const [treatmentList, setTreatmentList] = useState([])
  const [value, setValue] = useState('')
  const history = useHistory()
  const [pinCode, setPinCode] = useState([])
  const [cityList, setCityList] = useState([])
  const [isSort, setIsSort] = useState(false)
  const [search, setSearch] = useState('')
  const [paginationData, setPaginationData] = useState({
    total: 0,
    pageCount: 0,
    currentPage: 0,
  })

  const [url, setUrl] = useState()
  const optionList = treatmentList.map((treatment) => {
    return {
      label: treatment.name,
      value: treatment._id,
    }
  })

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllBanner()
      const tempBanner = data.docs.find((banner) => {
        return banner.page === 'ayurvedaList'
      })
      setUrl(tempBanner.url)
      console.log(tempBanner)
    })()
  }, [])

  useEffect(() => {
    window.onLocationChange = () => {
      console.log('location changed')
      setSelectLocation(JSON.parse(localStorage.getItem('selectLocation')))
    }
    return () => {
      window.onLocationChange = () => { }
    }
  }, [])

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllTreatment()
      setTreatmentList(data.docs)
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      const { data } = await getPincode({
        districtName: selectLocation.districtName,
        stateName: selectLocation.state,
      })

      const uniquePincode = [];
      data.forEach((item) => {
        const isExist = uniquePincode.find((value) => {
          return value.pinCode === item.pinCode
        })
        if (!isExist) {
          uniquePincode.push(item)
        }
      })
      setPinCode(uniquePincode)
      //remove duplicate city
      const city = data.map((item) => item.cityName)
      const uniqueCity = [...new Set(city)]
      setCityList(uniqueCity)
    })()
  }, [selectLocation.districtName, selectLocation.state])
  const shortByPrice = () => {
    if (isSort) {
      const sorted = [...vendorByTreatment]
      sorted.sort((a, b) => {
        return b.price - a.price
      })
      setVendorByTreatment(sorted)
      setIsSort(false)
    } else {
      const sorted = [...vendorByTreatment]
      sorted.sort((a, b) => {
        return a.price - b.price
      })
      setVendorByTreatment(sorted)
      setIsSort(true)
    }
  }

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllVendorByTreatment({
        limit,
        skip: 0,
        // treatmentId: '6411729bd19b56f9aa66f663',

        district: selectLocation.districtName,
        state: selectLocation.state,
        type: 'ayurveda',
        treatmentId: search,
      })
      setSurgeryFilters({
        city: [],
        pinCode: [],
      })
      setIsLoading(false)
      setVendorByTreatment(data.docs)
      setPaginationData({
        total: data.totalDocs,
        pageCount: data.totalPages || 1,
        currentPage: data.page,
      })
    })()
  }, [search, selectLocation.districtName, selectLocation.state])

  const getYear = () => {
    const currentYear = new Date().getFullYear()
    return currentYear
  }

  const [surgeryFilters, setSurgeryFilters] = useState({
    city: [],
    pinCode: [],
  })
  const handleCityChange = (event) => {
    if (event.target.checked) {
      setSurgeryFilters({
        ...surgeryFilters,
        city: [...new Set([...surgeryFilters.city, event.target.value])],
      })
    } else {
      setSurgeryFilters({
        ...surgeryFilters,
        city: surgeryFilters.city.filter((city) => {
          return city != event.target.value
        }),
      })
    }
  }

  const handlePinCodeChange = (event) => {
    console.log('we are here')
    console.log(event.target.checked)
    if (event.target.checked) {
      setSurgeryFilters({
        ...surgeryFilters,
        pinCode: [...new Set([...surgeryFilters.pinCode, event.target.value])],
      })
    } else {
      setSurgeryFilters({
        ...surgeryFilters,
        pinCode: surgeryFilters.pinCode.filter((pinCode) => {
          return pinCode != event.target.value
        }),
      })
    }
  }

  const filterBtnClick = async (data) => {
    const response = await getAllVendorByTreatment({
      city: surgeryFilters.city,
      pinCode: surgeryFilters.pinCode,
      state: selectLocation.state,
      district: selectLocation.districtName,
      type: 'ayurveda',
      treatmentId: search,
    })
    if (response.code === 200) {
      setVendorByTreatment(response.data.docs)

      // alert('Hospital Fetched Successfully')
      // window.location.reload()
      handleDrawerClose()
    } else {
      alert(response.message)
    }
  }
  const [isloading, setIsLoading] = useState(true)
  const getAllVendors = async () => {
    const { data } = await getAllVendorByTreatment({
      limit,
      skip: 0,

      state: selectLocation.state,
      district: selectLocation.districtName,
      type: 'ayurveda',
      treatmentId: search,
    })
    data.docs.forEach((item) => {
      item.isCompare = false
    })
    setVendorByTreatment(data.docs)
    setPaginationData({
      total: data.totalDocs,
      pageCount: data.totalPages || 1,
      currentPage: data.page,
    })
  }

  return (
    <>
      <section className="ayurveda">
        <Back
          name="Ayurveda"
          title="FIND A BEST CLINICS OR HOSPITALS FOR YOUR TREATMENT"
          cover={url || img}
        />
        <section className="clinicSearch">
          <div className="container">
            <div className="searchBox">
              <Autocomplete
                name="treatment"
                size="small"
                id="combo-box-demo"
                options={optionList}
                value={optionList.find((value) => value?.value == search)}
                onChange={(event, newValue, reason) => {
                  console.log(newValue)
                  if (reason === 'clear') {
                    return setSearch('')
                  }
                  setSearch(newValue.value)
                }}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField {...params} label="Search Treatment" />
                )}
              />
            </div>
          </div>
        </section>

        <div className="container py-5">
          <h3 className="pageTitle">
            Showing Best Doctor's<span> Clinics</span> &{' '}
            <span>Hospitals list</span>
          </h3>
          <p className="pageSubTitle">
            Choose from the list of top-selling packages tailored for you. We
            care for your health
          </p>
          <div className="shortingBtn">
            <Box open={open} sx={{ display: 'flex' }}>
              <CssBaseline />
              <IconButton onClick={handleDrawerOpen} className="filterBtn">
                Filter
                <img src="/images/filter.png" alt="filter" />
              </IconButton>
              <div>
                <React.Fragment>
                  <Drawer anchor={'right'} open={open} onClose={toggleDrawer()}>
                    <Box className="drawerrr">
                      <h6 className="titleF">Filter By</h6>

                      <Box className="px-4">
                        <h6 className="py-2 filter_title">Specific Area</h6>

                        {cityList?.map((item, index) => (
                          <FormControlLabel
                            className="inputSe"
                            control={
                              <Checkbox
                                checked={surgeryFilters.city.includes(item)}
                              />
                            }
                            label={item}
                            value={item}
                            onChange={handleCityChange}
                          >
                            {item}
                          </FormControlLabel>
                        ))}

                        <h6 className="py-2 filter_title">Pincode</h6>

                        {pinCode?.map((item, index) => (
                          <FormControlLabel
                            className="inputSe"
                            control={
                              <Checkbox
                                checked={surgeryFilters.pinCode.includes(
                                  item.pinCode.toString(),
                                )}
                              />
                            }
                            label={item.pinCode}
                            value={item.pinCode}
                            onClick={handlePinCodeChange}
                          >
                            {item.pinCode}
                          </FormControlLabel>
                        ))}
                        <Stack spacing={2} direction="row" sx={{ pt: 3 }}>
                          <Button
                            className="filterBtn text-white mx-auto mt-3"
                            onClick={filterBtnClick}
                          >
                            Submit
                          </Button>
                          <Button
                            className="filterBtn text-white mx-auto mt-3"
                            onClick={() => {
                              getAllVendors()
                              setSurgeryFilters({
                                price: [0, 500000],
                                city: [],
                                pinCode: [],
                              })
                            }}
                          >
                            Clear
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Drawer>
                </React.Fragment>
              </div>
            </Box>
            <Button
              variant={'outlined'}
              sx={{
                color: '#4E5360',
                borderColor: '#4E5360',
              }}
              className="sortBtn"
              onClick={shortByPrice}
              endIcon={
                isSort ? (
                  <KeyboardDoubleArrowUpIcon />
                ) : (
                  <KeyboardDoubleArrowDownIcon />
                )
              }
            >
              Sort by Price
            </Button>
          </div>
          {isloading ? <LoadingPage /> :
            <>
              {vendorByTreatment.length === 0 ? (
                <Typography className="contactL" variant="h4">
                  {message}
                </Typography>
              ) : (
                vendorByTreatment?.map((ayurved) => (
                  <div className="row py-3 cliniCard">
                    <div className="col-md-4">
                      <div className="cliniCardImg">
                        <img
                          src={ayurved.logo ? ayurved?.logo : '/images/clinic.png'}
                          alt="Image"
                          className="cliniImg"
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="cliniCardContent">
                        <div className="cliniCardTitle pb-3">
                          <h3>{ayurved?.name}</h3>
                          <h3>
                            Price - <span>₹ {ayurved?.price}</span>
                          </h3>
                        </div>
                        <p>{ayurved?.type}</p>
                      </div>
                      <div className="viewBook">
                        <div className="clinicD">
                          {ayurved?.isVerified ? (
                            <div className="clinicDItem">
                              <div className="clinicDicon">
                                <VerifiedIcon />
                              </div>
                              <p>
                                {ayurved?.isVerified ? 'Verified' : 'Not Verified'}
                              </p>
                            </div>
                          ) : null}
                          {ayurved?.isTrusted ? (
                            <div className="clinicDItem">
                              <div className="clinicDicon">
                                <VerifiedUserOutlinedIcon />
                              </div>
                              <p>{ayurved?.isTrusted ? 'Trusted' : ''}</p>
                            </div>
                          ) : null}

                          {/* <div className="clinicDItem">
                        <div className="clinicDicon">
                          <VerifiedIcon />
                        </div>
                        <p>
                          {ayurved?.isVerified ? 'Verified' : 'Not Verified'}
                        </p>
                      </div> */}
                          <div className="clinicDItem">
                            <div className="clinicDicon">
                              <img src="/images/bag.png" alt="" />
                            </div>
                            <p>
                              {getYear() - ayurved.yearOfEstablishment} Year
                              Experience
                            </p>
                          </div>
                          <div className="clinicDItem">
                            <div className="clinicDicon">
                              <img src="/images/location.svg" alt="" />
                            </div>
                            <p>
                              {ayurved?.address?.city}, {ayurved?.address?.district}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="viewBook">
                        <Link to={`/ayurvedaDetailPage/${ayurved._id}`}>
                          View more details
                        </Link>
                        <button
                          onClick={() => {
                            history.push(`/ayurvedaDetailPage/${ayurved._id}`)
                          }}
                          className="bookNowBtn"
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </>
          }
          {paginationData.pageCount > 1 && (
            <Stack
              spacing={2}
              sx={{
                pt: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Pagination
                count={paginationData.pageCount || 1}
                onChange={async (e, page) => {
                  const { data } = await getAllVendorByTreatment({
                    limit,
                    skip: (page - 1) * limit,
                    treatmentId: '6411729bd19b56f9aa66f663',
                    city: 'Ballabgarh',
                    district: 'Faridabad',
                    state: 'Haryana',
                    type: 'ayurveda',
                  })
                  setVendorByTreatment(data.docs)
                }}
                color="primary"
              />
            </Stack>
          )}
        </div>
      </section>
    </>
  )
}
const LoadingPage = () => {
  return (
    <div style={{
      display: 'Grid',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src='../../../../images/giphy.gif' alt='' />
    </div>

  )
}
export default Ayurveda
