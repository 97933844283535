import { Stack } from '@mui/system'
import * as Yup from 'yup'
import React, { useEffect, useState, useCallback } from 'react'
import Back from '../common/Back'
import { styled, alpha } from '@mui/material/styles'
import img from '../images/bannerimg.jpg'
import './labDetailPage.css'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, RHFTextField } from '../hook-form'
import { LoadingButton } from '@mui/lab'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import AddIcon from '@mui/icons-material/Add'
import AvTimerIcon from '@mui/icons-material/AvTimer'
import DoneIcon from '@mui/icons-material/Done'
import HomeIcon from '@mui/icons-material/Home'
import {
  MenuItem,
  Button,
  Menu,
  Box,
  Item,
  Modal,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useParams, useHistory } from 'react-router-dom'
import { getSingleVender } from '../../api/singleVendor'
import { getLabDetails } from '../../api/labDetails'
import { getAllBanner } from '../../api/banner'
import { login, generateOtp } from '../../api/login'
import { Link } from 'react-router-dom'
// ---------------------------------------------------------------

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#000' }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const topSurgery = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 991, min: 768 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 3,
}
const LabDetailPage = () => {
  const [expanded2, setExpanded2] = React.useState('panel1')
  const handleChange2 = (panel) => (event, newExpanded2) => {
    setExpanded2(newExpanded2 ? panel : false)
  }
  const [singleTreatment, setSingleTreatment] = useState([])
  const [labDetails, setLabDetails] = useState([])
  const selectLocation = JSON.parse(localStorage.getItem('selectLocation'))
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const params = useParams()
  const history = useHistory()
  const [showLoader, setShowLoader] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open2 = Boolean(anchorEl)
  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose2 = () => {
    setAnchorEl(null)
  }
  const [open, setOpen] = React.useState(false)
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const [childModalOpen, setChildModalOpen] = React.useState(false)
  const handleChildModalOpen = () => {
    setChildModalOpen(true)
  }
  const handleChildModalClose = () => {
    setChildModalOpen(false)
  }
  const [otp, setOtp] = useState(0)
  const [data, setData] = useState([])

  const RegisterSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    password: Yup.string(),
  })

  const defaultValues = {
    mobile: '',
    password: '',
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,

    formState: { isSubmitting },
  } = methods
  const values = watch()

  const [totalPrice, setTotalPrice] = useState(0)

  const [labBooking, setLabBooking] = useState({
    name: '',
    mobile: '',
    address: {
      houseNo: '',
      streetNo: '',
      locality: '',
      landmark: '',
      city: '',
      district: '',
      state: '',
      pinCode: '',
    },
    pathLabTestId: history.location.search.split('labtest=')[1].split(','),
    sampleCollectionMode: 'lab visit',
    price: 0,
    sampleCollectionScheduleTime: '',
    status: 'pending',
    paymentStatus: 'unpaid',
    paymentType: 'postpaid',
    couponCode: '',
    remark: '',
    discountPrice: 0,
    vendorId: params.id,
    slotTo: '',
    slotFrom: '',
  })

  const [url, setUrl] = useState()
  const [isloading, setIsLoading] = useState(true)
  useEffect(() => {
    ; (async () => {
      const { data } = await getAllBanner()
      const tempBanner = data.docs.find((banner) => {
        return banner.page === 'pathLabDetail'
      })
      setUrl(tempBanner.url)
      console.log(tempBanner)
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      const { data } = await getSingleVender(params.id)
      setSingleTreatment(data)
      setIsLoading(false)
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      const { data } = await getLabDetails({
        vendorId: params.id,
        pathLabTestId: history.location.search.split('labtest=')[1].split(','),
      })
      setLabDetails(data)
      const tempTotalPrice = data.pathLabTestDetails.reduce(
        (acc, item) => acc + Number(item.price),
        0,
      )
      console.log(tempTotalPrice)
      setTotalPrice(tempTotalPrice)
      setLabBooking({
        ...labBooking,
        price: tempTotalPrice,
        discountPrice: tempTotalPrice,
      })
      setIsLoading(false)
    })()
  }, [])

  const onSubmit = async (data) => {
    const response = await login(data)
    console.log(data)
    if (response?.data?.token) {
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      window.location.reload()
      handleClose()
      handleChildModalClose()
    } else {
      alert(response.message)
    }
  }

  const verify = async () => {
    const response = await login(otp, data)
    console.log(data)
    if (response?.data?.token) {
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      window.location.reload()
      handleClose()
      handleChildModalClose()
    } else {
      alert(response.message)
    }
  }

  return (
    <>
      <section className="labDetailPage">
        <Back
          name="Lab Detail"
          title="WELL ENSURE YOU ALWAYS GET THE BEST RESULTS"
          cover={url || img}
        />
        {isloading ? <LoadingPage /> :
          <div className="container py-5">
            <div className="row">
              <div className="col-md-9">
                <div className="labDetailPage__content">
                  <h2>{singleTreatment?.name}</h2>
                  <p>{singleTreatment?.description}</p>
                </div>
                {/* <div className="textDetailList">
                <div className="packageDe">
                  <div className="iconSec">
                    <AddIcon />
                  </div>
                  <p>18 Test</p>
                </div>
                <div className="packageDe">
                  <div className="iconSec">
                    <AvTimerIcon />
                  </div>
                  <p>Duration - 3days</p>
                </div>
                <div className="packageDe">
                  <div className="iconSec">
                    <DoneIcon />
                  </div>
                  <p>NABL - <span>Approved</span></p>
                </div>
                <div className="packageDe">
                  <div className="iconSec">
                    <HomeIcon />
                  </div>
                  <p>Home sample</p>
                </div>
              </div> */}
              </div>
              <div className="col-md-3">
                <div className="labDetailPage__sidebar">
                  <h4>
                    Total Price - <span>₹ {totalPrice}</span>
                  </h4>
                  {userInfo ? (
                    <Button
                      className="bookNowBtn"
                      onClick={() => {
                        const labBookingDetails = {
                          ...labBooking,
                          price: totalPrice,
                          sampleCollectionMode: 'lab visit',
                          status: 'pending',
                          paymentStatus: 'unpaid',
                          couponCode: '',
                          remark: '',
                          discountPrice: totalPrice,
                          vendorId: singleTreatment._id,
                        }

                        localStorage.setItem(
                          'homeSampleCollection',
                          JSON.stringify(
                            labDetails.lab[0].isHomeSampleCollection,
                          ),
                        )
                        localStorage.setItem(
                          'labVisitCollection',
                          JSON.stringify(
                            labDetails.lab[0].isOnShopSampleCollection,
                          ),
                        )
                        const radiology = labDetails.pathLabTestDetails.map(
                          (test) => {
                            if (test.testType === 'radiology') {
                              return true
                            } else {
                              return false
                            }
                          },
                        )
                        if (radiology.includes(true)) {
                          localStorage.setItem('radiology', JSON.stringify(true))
                        } else {
                          localStorage.setItem('radiology', JSON.stringify(false))
                        }
                        setLabBooking(labBookingDetails)
                        console.log(labBookingDetails)
                        localStorage.setItem(
                          'labBooking',
                          JSON.stringify(labBookingDetails),
                        )
                        history.push('/labBooking')
                      }}
                    >
                      Book Now
                    </Button>
                  ) : (
                    <div>
                      <button
                        className="bookNowBtn"
                        onClick={() => {
                          handleOpen()
                        }}
                      >
                        Please Login
                      </button>
                      <Modal
                        className="modalL"
                        open={open}
                        onClose={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          handleClose()
                        }}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        <Box sx={{ ...style, width: 400 }}>
                          <FormProvider
                            methods={methods}
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <Stack spacing={3} className="py-4">
                              <RHFTextField
                                type="number"
                                size="small"
                                name="mobile"
                                label="Mobile"
                              />
                              <RHFTextField
                                type="password"
                                size="small"
                                name="password"
                                label="password"
                              />
                              <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                className="signBtn"
                                loading={isSubmitting}
                              >
                                Login
                              </LoadingButton>
                              <div className="text-center">
                                <p>
                                  Don't have an account?{' '}
                                  <span
                                    style={{
                                      color: '#FF8021',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      history.push('/signRegister')
                                    }}
                                  >
                                    Sign Up
                                  </span>
                                </p>
                              </div>
                              <div className="text-center">
                                <Link to="/forgotPassword">Forgot Password?</Link>
                              </div>
                            </Stack>
                          </FormProvider>
                        </Box>
                      </Modal>
                      <Modal
                        open={childModalOpen}
                        onClose={handleChildModalClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                      >
                        <Box className="changeChildModal">
                          <FormProvider className="text-center">
                            <div className="otpSec2">
                              <input
                                type="text"
                                placeholder="OTP"
                                onChange={(e) => setOtp(e.target.value)}
                              />
                            </div>
                            <div className="text-center">
                              <Button className="otpBtn" onClick={() => verify()}>
                                Login
                              </Button>
                            </div>
                          </FormProvider>
                        </Box>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="bloodAnalysis">
              <h3>Test List</h3>
              <div className="widthAccor">
                {labDetails?.pathLabTestDetails?.map((item, index) => {
                  const description = item.description.split('.')
                  return (
                    <Accordion
                      expanded={expanded2 === index}
                      onChange={handleChange2(index)}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                      >
                        <div
                          style={{ width: '33%', flexShrink: 0 }}
                          className="miniAccordian"
                        >
                          <h3>{item.name}</h3>
                        </div>
                        <div className="miniAccordian">
                          <h3
                            style={{
                              color: '#FF9710',
                              fontSize: '14px',
                              marginLeft: '10px',
                            }}
                          >
                            {' '}
                            Price : ₹ {item.price}
                          </h3>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* split the description by . in point wise direction */}
                        <ul style={{ listStyleType: 'circle' }}>
                          {' '}
                          {description.map((item) => {
                            return <li style={{ fontSize: '12px' }}>{item}</li>
                          })}
                        </ul>
                      </AccordionDetails>
                      {labDetails?.lab?.[0] &&
                        labDetails?.lab?.[0]?.labTestAttributeData?.filter((attribute) => {
                          return attribute.pathLabTestId === item._id
                        }).map(
                          (item, index) => (
                            <div className="facilitiesList">
                              <div className="row px-4">
                                <div className="col-md-6">
                                  <h6 className='lista'>{item?.pathLabAttributeDetails?.name}</h6>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    <span>{item?.dataValues}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ),
                        )}
                    </Accordion>
                  )
                })}
              </div>
              <h5 className="titleFacilities">Address -</h5>
              <div>
                <p>
                  {/* <div className="py-1 d-flex align-items-center">
                  <div className="pr">Locality - </div>
                  {singleTreatment?.address?.locality}
                </div> */}
                  <div className="py-1 d-flex align-items-center">
                    <div className="pr">Pincode - </div>
                    {singleTreatment?.address?.pinCode}
                  </div>
                  <div className="py-1 d-flex align-items-center">
                    <div className="pr">Area - </div>
                    {singleTreatment?.address?.city}
                  </div>
                  <div className="py-1 d-flex align-items-center">
                    <div className="pr">District - </div>
                    {singleTreatment?.address?.district}
                  </div>
                  <div className="py-1 d-flex align-items-center">
                    <div className="pr">State - </div>
                    {singleTreatment?.address?.state}
                  </div>
                </p>
              </div>
            </div>
            <Carousel
              responsive={topSurgery}
              infinite={true}
              // autoPlay={true}
              autoPlaySpeed={2000}
            >
              <div
                className="content"
                onClick={() => {
                  history.push('/packages')
                }}
              >
                <div className="box">
                  <div className="book_rightSec">
                    <div className="p-2">
                      <img src="/images/hospital_icon.png" alt="" />
                      <p>Blood Lab Tests From Top and Trusted Brands</p>
                    </div>
                    <div className="bookingfooter">
                      <p>Offers</p>
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Lab Test</h4>
                    <h5># 01</h5>
                    <h6>Total Checkup Solutions for your Health.</h6>
                  </div>
                </div>
              </div>
              <div
                className="content"
                onClick={() => {
                  history.push('/ayurveda')
                }}
              >
                <div className="box">
                  <div className="book_rightSec">
                    <div className="p-2">
                      <img src="/images/hospital_icon.png" alt="" />
                      <p>
                        Now Begin Your Journey Towards Natural Health By Top and
                        Trusted Ayurvedic Treatment Clinics
                      </p>
                    </div>
                    <div className="bookingfooter">
                      <p>Ayurvedic Treatment</p>
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Ayurvedic Treatment</h4>
                    <h5># 02</h5>
                    <h6>Top and Trusted Ayurvedic Brand In your City</h6>
                  </div>
                </div>
              </div>
              <div
                className="content"
                onClick={() => {
                  history.push('/ayurveda')
                }}
              >
                <div className="box">
                  <div className="book_rightSec">
                    <div className="p-2">
                      <img src="/images/hospital_icon.png" alt="" />
                      <p>
                        Consult India's Top Doctors Dieticians From the comforts
                        of your home.
                      </p>
                    </div>
                    <div className="bookingfooter">
                      <p>Nearest</p>
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Book Nearest Clinic</h4>
                    <h5># 03</h5>
                    <h6>Book Your Specialist Doctors and Dieticians</h6>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        }
      </section>
    </>
  )
}
const LoadingPage = () => {
  return (
    <div style={{
      display: 'Grid',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src='../../../../images/giphy.gif' alt='' />
    </div>

  )
}
export default LabDetailPage
