import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Back from '../common/Back'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import img from '../images/bannerimg.jpg'
import './radiologyListing.css'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { Pagination } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { Link } from 'react-router-dom'
import { getAllRadiology } from '../../api/pathology'
import { getAllBanner } from '../../api/banner'
import { useHistory } from 'react-router-dom'
import { message } from '../../utils/message'
// ---------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}



const RadiologyListing = () => {
  const history = useHistory()
  useEffect(() => {
    localStorage.removeItem('surgeryPrice')
  }, [])
  const [radiology, setRadiology] = useState([])
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [testList, setTestList] = useState([])
  const [paginationData, setPaginationData] = useState({
    total: 0,
    pageCount: 0,
    currentPage: 0,
  })

  const [url, setUrl] = useState()

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllBanner()
      const tempBanner = data.docs.find((banner) => {
        return banner.page === 'labRadioLabList'
      })
      setUrl(tempBanner.url)
      console.log(tempBanner)
    })()
  }, [])
  const [isloading, setIsLoading] = useState(true)
  useEffect(() => {
    ; (async () => {
      const { data } = await getAllRadiology()
      setIsLoading(false)
      setRadiology(data.docs)
      setPaginationData({
        total: data.totalDocs,
        pageCount: data.totalPages,
        currentPage: data.page,
      })
    })()
  }, [])

  useEffect(() => {
    if (testList.length == 4) {
      handleOpen()
    } else if (testList.length == 7) {
      handleOpen()
    }
  }, [testList])

  const addList = (id, shouldAdd) => {
    if (shouldAdd) {
      let list = [...testList]
      setTestList([...new Set([...testList, id])])
    } else {
      setTestList(testList.filter((tid) => tid !== id))
    }
  }

  const setScroll = () => {
    window.scrollTo(0, localStorage.getItem('position'));
  }

  console.log(testList)

  return (
    <>
      <section className="radiologyListing">
        <Back
          name="Radiology Listing"
          title="WELL ENSURE YOU ALWAYS GET THE BEST RESULTS"
          cover={url || img}
        />

        <div className="container py-5">
          <h3 className="pageTitle">Showing 1-20 of 180 results</h3>

          <h3 className="showAll">Show all Categories</h3>
          {isloading ? <LoadingPage /> :
            <Box
              sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                className="tabSection"
              >
                {radiology.map((item, index) => (
                  <Tab
                    label={item.name}
                    {...a11yProps(index)}
                    onClick={() => {
                      setScroll()
                    }}
                  />
                ))}
              </Tabs>
              {radiology.length === 0 ? (
                <Typography className='contactL' variant='h4'>
                  {message}
                </Typography>
              ) : (
                radiology?.map((item, index) => (
                  <TabPanel value={value} index={index} className="tabPannel">
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ py: 1 }}
                      className="mainCol"
                    >
                      {item?.pathLabTests?.map((item) => (
                        <div className="categoryListt">
                          <div className="col_M">
                            <div>
                              <h3 className="testName">{item?.name}</h3>
                              <span>({item?.description?.substring(0, 30)})</span>
                            </div>
                            <Checkbox
                              {...label}
                              checked={testList.includes(item?._id)}
                              onClick={(e) => {
                                addList(item?._id, e.target.checked)
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </Stack>
                    <div className="text-end pt-3">
                      <Button
                        onClick={() => {
                          if (testList.length == 0) {
                            alert('Please select atleast one test')
                            return
                          } else {
                            history.push(
                              `/labsListing?labtest=${testList.join(',')}`,
                            )
                          }
                        }}
                        to={`/labsListing?labtest=${testList.join(',')}`}
                        className="bookNowtext"
                      >
                        View Labs
                      </Button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="modalTextList"
                      >
                        <Box sx={style}>
                          <Typography
                            className="titleModal text-center"
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Do you wants to explore <br></br>
                            <span>OUR PACKAGES</span>
                          </Typography>
                          <div className="btnSec">
                            <Link to="/packages">
                              <Button onClick={handleClose} className="yesBtn">
                                Yes
                              </Button>
                            </Link>
                            <Button onClick={handleClose} className="noBtn">
                              No
                            </Button>
                          </div>
                        </Box>
                      </Modal>
                    </div>
                  </TabPanel>
                ))
              )}
            </Box>
          }
        </div>
        {paginationData.pageCount > 1 && (
          <Stack
            spacing={2}
            sx={{
              pt: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* <Pagination
              count={paginationData.pageCount || 1}
              onChange={async (e, page) => {
                const { data } = await getAllRadiology({
                  limit,
                  skip: (page - 1) * limit,
                })
                setRadiology(data.docs)
              }}
              color="primary"
            /> */}
          </Stack>
        )}
      </section>
    </>
  )
}
const LoadingPage = () => {
  return (
    <div style={{
      display: 'Grid',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src='../../../../images/giphy.gif' alt='' />
    </div>

  )
}
export default RadiologyListing
