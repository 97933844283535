import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import 'bootstrap/dist/css/bootstrap.css'
import './topSurgery.css'
import { getTopSurgery } from '../../../api/topSurgery'
import { useHistory } from 'react-router-dom'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { TextField, Typography } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  outline: 'none'
}
// --------------------------------------------------------
const topSurgery = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}

const TopSurgery = () => {
  const history = useHistory()
  const [topSurgeryList, setTopSurgeryList] = useState([])

  useEffect(() => {
    ; (async () => {
      const { data } = await getTopSurgery()
      setTopSurgeryList(data)
    })()
  }, [])

  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <section className="py-5 featured">
        <div className="container">
          <img
            src={'../../../../images/icon-1.svg'}
            alt=""
            className="icon_1"
          />
          <div className="mainTitle">
            <div>
              <span>
                <img src={'../../../../images/line.png'} alt="" />
                Our Top Surgery
              </span>
              <h2>Top Surgery</h2>
            </div>
          </div>
          <Carousel
            responsive={topSurgery}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
          >
            {topSurgeryList.map((item) => (
              <div
                className="content"
                onClick={() => {
                  if (localStorage.getItem('selectLocation')) {
                    history.push(`/chooseHospital/${item._id}`)
                    localStorage.setItem(
                      'subSurgeryId',
                      JSON.stringify(item._id),
                    )
                  } else {
                    handleOpenModal()
                  }
                }}
              >
                <div className="box">
                  {item.icon ? (
                    <>
                      <img src={item.icon} alt="" />
                    </>
                  ) : (
                    <img src={'../../../../images/knee.png'} alt="" />
                  )}
                  <h4>{item.name}</h4>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                cursor: 'pointer',
                p: 1,
                alignItems: 'center',
              }}
            >
              <Typography variant={'h5'}>Please Select Location </Typography>
              <Button
                sx={{
                  mt: 2,
                  width: '100px',
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleCloseModal()
                }}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Modal>
      </section>
    </>
  )
}

export default TopSurgery
