import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import img from '../images/bannerimg.jpg'
import Back from '../common/Back'
import './editProfile.css'
import 'bootstrap/dist/css/bootstrap.css'
import { deleteAccount } from '../../api/login'
import { useParams } from 'react-router-dom/cjs/react-router-dom'

export default function DeleteAccount() {
  const history = useHistory()
  const params = useParams()
  console.log(params.token)
  // console.log(localStorage.getItem('token'))
  // console.log('we are here')
  const token = localStorage.getItem('token') || params.token

  const handleLogout = () => {
    localStorage.clear()
    window.location.replace('/')
  }

  useEffect(() => {
    console.log(token)
    if (!token) {
      history.push('/signIn')
    }
  }, [])
  return (
    <>
      <Back name="Delete Account" title="Delete Account" cover={img} />
      <section className="formBg py-5 editProfile">
        <div className="container row justify-content-center">
          <div className="col-md-6 deleteProfile">
            <h3>Delete Account?</h3>
            <div className="profileIm">
              <img src={'../../../../images/avatar.jpeg'} alt="" />
            </div>

            <div>
              <button
                className="signBtn"
                onClick={async () => {
                  const resp = await deleteAccount(params?.token)
                  if (resp.code === 200) {
                    handleLogout()
                  }
                }}
              >
                Delete
              </button>
            </div>

            <button
              className="signBtn"
              onClick={() => {
                history.push(`/editProfile`)
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
