import { api } from '../utils/request';

export const getSingleVender = async (id) => {
    const res = await api({ endpoint: `/vendor/${id}`, method: 'GET' });
    return res;
}

// export const getSurgeryDetail = async (id) => {
//     const res = await api({ endpoint: `/vendor/surgeryDetail/${id}`, method: 'GET' });
//     return res;
// }

export const getSurgeryDetail = async (query = {}) => {
    const res = await api({ endpoint: '/vendor/surgeryDetail', method: 'GET', query });
    return res;

};