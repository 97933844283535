import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/css/bootstrap.css";
import "./ourServices.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
// --------------------------------------------------------
const ourServices = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const OurServices = () => (
  <>
    <section className="py-5 OurServices border-top">
      <div className="container">
        <div className="mainTitle">
          <span>
            <img src={"../../../../images/line.png"} alt="" />
            Get Know About services
          </span>
          <h2>Our best services for you</h2>
        </div>
        <div className="row">
          <div className="content">
            <div className="box">
              <img src={"../../../../images/surgeries.png"} alt="" />
              <h4>Surgeries</h4>
              <p>
                Top surgical expert,experienced surgeons,safe approved and secure
                procedure, you can plan your surgery, maximum success less
                price, surgery means health not only a cut on your beautiful
                body.
              </p>
              {/* <Link to='/chooseHospital' className='readMore'><ArrowForwardIcon /> Read More</Link> */}
            </div>
          </div>
          <div className="content">
            <div className="box">
              <img src={"../../../../images/labs.png"} alt="" />
              <h4>Labs</h4>
              <p>
                State of the art labs and radiology in your city,highly
                experienced and certified radiologist and pathologist, advance
                and affordable.
              </p>
              {/* <Link to='/labsListing' className='readMore'><ArrowForwardIcon /> Read More</Link> */}
            </div>
          </div>
          <div className="content">
            <div className="box">
              <img src={"../../../../images/ayurveda.png"} alt="" />
              <h4>Ayurveda</h4>
              <p>
                Top ayurvedic expert, panchkarm package, nadiparikshan,
                ayurvedic doctor,ayurvedic treatment center, ayurvedic hospital,,
                book appointment with top ayurvedic doctors, network of top
                ayurvedic expert in your city, find your nearest ayurvedic
                clinic.
              </p>
              {/* <Link to='/ayurveda' className='readMore'><ArrowForwardIcon /> Read More</Link> */}
            </div>
          </div>
          <div className="content">
            <div className="box">
              <img src={"../../../../images/clinics.png"} alt="" />
              <h4>Clinics</h4>
              <p>
                Top specialized clinics in your city, find doctors,and consult
                with top specialist book an appointment for your families.
              </p>
              {/* <Link to='/clinic' className='readMore'><ArrowForwardIcon /> Read More</Link> */}
            </div>
          </div>
          <div className="content">
            <div className="box">
              <img src={"../../../../images/blood_bank.png"} alt="" />
              <h4>Blood Bank</h4>
              <p>
                Check units availability in your cities blood banks, connect and
                get your unit first, free checkups,hassle free procedure, easy
                to connect.
              </p>
              {/* <Link to='/bloodBank' className='readMore'><ArrowForwardIcon /> Read More</Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default OurServices;
