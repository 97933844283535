import React from 'react'
import Back from '../common/Back'
import Heading from '../common/Heading'
import img from '../images/bannerimg.jpg'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import './faq.css'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const Faq = () => {
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      <section className="faq">
        <Back name="Faqs" title="Faqs" cover={img} />
        <div className="container row py-4">
          <div className="left col-md-12">
            <Heading title="Faqs" />
            <div>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>
                    How should I book an appointment/Surgery/Lab/Clinic and
                    other services of clickyourhospital?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You can book an appointment via the following steps- <br />
                    *Register/ Login yourself (create your profile by using your
                    details)
                    <br /> * Set Location, Price filter, Area Filter And or
                    anything which you suit.
                    <br /> *Choose your need (Surgery, Lab, Clinic, Ayurveda,
                    Blood Bank) <br />* Now You can compare the hospital with
                    their package price along with their facility and surgery
                    details.
                    <br /> *choose your hospital /lab / clinic/ Ayurveda/ and
                    confirm details, click on book now and follow further steps.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>
                    Is it possible to compare price of a surgery and lab test?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes, it is possible to compare hospital and their surgery
                    prices package and lab test price and their facilities.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>
                    How Many days in advance do I need to book an appointment or
                    any service
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Feel free, this depends on availability of
                    hospital/lab/clinic/Ayurveda and provided schedule data from
                    hospital/lab/clinic/Ayurveda
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>
                    How Many days in advance do I need to book an appointment or
                    any service
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Feel free, this depends on availability of
                    hospital/lab/clinic/Ayurveda and provided schedule data from
                    hospital/lab/clinic/Ayurveda
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>Can a booking be rescheduled</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes, You have to ,make a call to our support team on –
                    8130972217 or write us on support@clickyourhospital.com
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>Can a booking be cancelled?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes, Select the profile icon on the top right corner. <br />
                    • Select cancel booking and follow the steps.
                    <br /> • Please keep ready your Patient ID, Registered
                    Mobile no., Booking date,
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel6'}
                onChange={handleChange('panel6')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>
                    In case of advance payment, will be refunded for not making
                    an appearance on the appointment date?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes, you can book again this by making a call to our call
                    support.
                    <br /> • Payments made through a payment gateway, bank
                    account, UPI. Etc. will be refunded within next 15 working
                    days.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel7'}
                onChange={handleChange('panel7')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>My Account is locked.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Accounts are locked if we detect multiple failed attempts.
                    We unblock the account as per your request only.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel8'}
                onChange={handleChange('panel8')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>Forgot Password</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Try clicking on forgot password and try resetting the same.
                    <br />• If this still unresolved, write to us on
                    support@clickyourhospital.com
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel9'}
                onChange={handleChange('panel9')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>
                    When will I receive my Health Check-up reports?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Your Health Check-up report shall be share on your WhatsApp
                    or mail or you can collect it also from our office and lab
                    centre.
                    <br /> • Send your details via WhatsApp on – 8130972217{' '}
                    <br />• Write – My Report- Your Patient ID, Your Mobile No.,
                    And booking date <br />• You Can call on – 8130972217,
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel10'}
                onChange={handleChange('panel10')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>Forgot my username.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Try using your Email ID, Phone No. If this still doesn’t
                    work, please contact our support team.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel11'}
                onChange={handleChange('panel11')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>I want to cancel my order.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Dear Customer, once order is confirmed it cannot be
                    cancelled. You can cancel your order at the time of delivery
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel12'}
                onChange={handleChange('panel12')}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>Haven’t received the refund amount.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    To initiate your refund, please share the order details and
                    raise a refund request with our customer support team by
                    writing us on spport@clickyourhospital.com or call us on-
                    813-097-2217 If refund is already initiated, please note it
                    will take 15 working days for the amount to reflect in your
                    account.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Faq
