import React, { useEffect } from "react"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import "./userReviews.css"
import { getAllUserReviews } from "../../../api/userReviews"
// --------------------------------------------------------

const userReview = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
    slidesToSlide: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};


const UserReviews = () => {
  const [userReviews, setUserReviews] = React.useState([])

  useEffect(() => {
    (async () => {
      const { data } = await getAllUserReviews();
      setUserReviews(data.docs);
    })();
  }, []);

  return (
    <>
      <section className='py-5 UserReviews border-top'>
        <div className='container'>
          <div className="mainTitle">
            <span>
              <img src={"../../../../images/line.png"} alt='' />
              User Reviews
            </span>
            <h2>Read the best reviews from our users</h2>
          </div>
          <div className="row">
            <div className="col-md-8">
              <Carousel
                responsive={userReview}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
              >
                <div className='content'>
                  <div className='box'>
                    <div className="imageSec">
                      <img src={"../../../../images/st.jpeg"} alt='' />
                      <i className="fa-solid fa-play"></i>
                    </div>
                    <p>I received help from ClickYourHospital team during my surgery and recovery. And my faith in them has increased a lot. Thanks a lot.</p>
                    <div className="testiRtin">
                      <div>
                        <h4>Saurabh Tomar</h4>
                        {/* <span>{review.designation}</span> */}
                      </div>
                      <div className="starS">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='content'>
                  <div className='box'>
                    <div className="imageSec">
                      <img src={"../../../../images/a.jpeg"} alt='' />
                      <i className="fa-solid fa-play"></i>
                    </div>
                    <p>I would like to thank Clickyourhospital team for supporting me for getting the proper and hassle free treatment at hospital.</p>
                    <div className="testiRtin">
                      <div>
                        <h4>Amit Singh</h4>
                        {/* <span>{review.designation}</span> */}
                      </div>
                      <div className="starS">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='content'>
                  <div className='box'>
                    <div className="imageSec">
                      <img src={"../../../../images/g.jpeg"} alt='' />
                      <i className="fa-solid fa-play"></i>
                    </div>
                    <p>Nice service with good hygiene maintenance. I got my brother's treatment with their help and believe me it was an amazing and very comfortable experience I had with them. Keep it up guys and thank you very much. 					</p>
                    <div className="testiRtin">
                      <div>
                        <h4>Swati Singh</h4>
                        {/* <span>{review.designation}</span> */}
                      </div>
                      <div className="starS">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='content'>
                  <div className='box'>
                    <div className="imageSec">
                      <img src={"../../../../images/as.jpeg"} alt='' />
                      <i className="fa-solid fa-play"></i>
                    </div>
                    <p>Well Qualified Experienced Team of CYH. Patient Care is on Top Priority..!! Helpful and accommodating staff. Everything went quickly for a regular checkup. I am highly satisfied! Very good condition of hospital with a great care of patients .
                      Little bit issues in parking.</p>
                    <div className="testiRtin">
                      <div>
                        <h4>Gaurav Kumar</h4>
                        {/* <span>{review.designation}</span> */}
                      </div>
                      <div className="starS">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='content'>
                  <div className='box'>
                    <div className="imageSec">
                      <img src={"../../../../images/v.jpeg"} alt='' />
                      <i className="fa-solid fa-play"></i>
                    </div>
                    <p>GOOD SERVICES AND ALL STAFF ARE VERY FRIENDLY AND SUPPORTIVE.</p>
                    <div className="testiRtin">
                      <div>
                        <h4>Vineet</h4>
                        {/* <span>{review.designation}</span> */}
                      </div>
                      <div className="starS">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='content'>
                  <div className='box'>
                    <div className="imageSec">
                      <img src={"../../../../images/j.jpeg"} alt='' />
                      <i className="fa-solid fa-play"></i>
                    </div>
                    <p>Doctor and staff are very helpful. Had a great experience and recovery was also fast </p>
                    <div className="testiRtin">
                      <div>
                        <h4>Jitendra</h4>
                        {/* <span>{review.designation}</span> */}
                      </div>
                      <div className="starS">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='content'>
                  <div className='box'>
                    <div className="imageSec">
                      <img src={"../../../../images/n.jpeg"} alt='' />
                      <i className="fa-solid fa-play"></i>
                    </div>
                    <p>ClickYourHospital is excellent and very capable team. I am really obliged that thay help in getting my mother treated and now she is fine. Thank ClickYourHospital. </p>
                    <div className="testiRtin">
                      <div>
                        <h4>Neha</h4>
                        {/* <span>{review.designation}</span> */}
                      </div>
                      <div className="starS">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {userReviews.map((review) => (
                  <div className='content'>
                    <div className='box'>
                      <div className="imageSec">
                        <img src={review.image} alt='' />
                        <i className="fa-solid fa-play"></i>
                      </div>
                      <p>{review.description?.substring(0, 150)}</p>
                      <div className="testiRtin">
                        <div>
                          <h4>{review.name}</h4>
                          <span>{review.designation}</span>
                        </div>
                        <div className="starS">
                          {review.rating} <i className="fa-solid fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}
              </Carousel>
            </div>
            <div className="col-md-4">
              <div className="imgUsers">
                <img src={"../../../../images/users.png"} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  )
}

export default UserReviews
