import React from 'react'
import Back from '../common/Back'
import Heading from '../common/Heading'
import img from '../images/bannerimg.jpg'
import './about.css'

const About = () => {
  return (
    <>
      <section className="about">
        <Back name="About Us" title="About Us - Who We Are?" cover={img} />
        <div className="container row py-4 align-items-center">
          <div className="col-md-8">
            <Heading
              title="About Us"
              subtitle="Check out our company story and work process"
            />

            <p>
              We clickyourhospital.com’’ team came under the parents company Vaj
              healthtech pvt. Ltd. Which was founded by Dr. Shiva(A healthcare
              expert) Dr. R.k. gupta and Vineet Agarwal (A Delhi University
              Alumni) and a serial enterpreneur with the help of Jitendra
              kumar (M.B.A) and Neha gupta (DELHI UNIVERSITY Alumni)
              <br /> <br />
              India’s Largest and first digital healthcare comparing platform We
              “clickyourhospital.com” will take care of your healthcare journey
              and enhancing and ensuring high quality next care surgeries
              through an excellent health care model.
            </p>

            <br />
            <h5>Our Mission : -</h5>
            <p>
              Bringing the best health care services to everyone at best price
              with full of transparency every body have right to know how he
              get and how he pay.
            </p>
            <br />
            <h5>Our vision: -</h5>
            <p>
              We are committed to make better of every one health care journey
              through our clear transparent services and latest technology.
            </p>
            <br />
            <h5>Our values: -</h5>
            <p>
              Everyone is like our families, and it is our duty that our family
              never fell hassle.
            </p>
            <br />
            <h3>We are offering</h3>
            <br />
            <h5>Surgery care :</h5>
            <p>
              A open transparent and comparable platform to ensuring you for a
              price right.
            </p>
            <br />
            <h5>Clinic : -</h5>
            <p>
              Consult various specialist expert at your edge and your budget.
            </p>
            <br />
            <h5>Ayurveda :-</h5>
            <p>
              Book ayurveda expert compare panchkarm package, nadiparikshan and
              ayurvedic treatment.
            </p>
            <br />
            <h5>Blood bank :-</h5>
            <p>
              Check blood units availability in city’s , blood banks and get
              first at your need.
            </p>
            <br />
            <h3>Why choose us</h3>
            <p>Establish easy & trusted connection through our model between you
              and your chosen hospital.</p>
            <p>
              “Clickyourhospital.com” is founded by Dr. Shiva and Dr. R.K.
              gupta after experiencing the healthcare fields. They fell that,
              there are a lots of thing to fix in this sector and they started
              this. They discussed the health care important goal with Vineet
              Agarwal and Neha gupta (both have a long experience of Market/
              Price / Sales and Customer support ) this was a time when a
              novel service (Health care service) meet the technology .And
              they all are decided to start a journey where they offering a
              honest market platform to our India and as well as to the world.
              And finely we all are having this platform as best wish of
              doctor shiva
            </p>

            {/* <button className='btn2'>More About Us</button> */}
          </div>
          <div className="col-md-4">
            <img src="./images/abouts.png" alt="" />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
