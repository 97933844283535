import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, TextField, Typography } from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import img from '../images/bannerimg.jpg'
import Back from '../common/Back'
import Autocomplete from '@mui/lab/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFTextField, RHFSelect } from '../hook-form'
import './surgeryBooking.css'
import 'bootstrap/dist/css/bootstrap.css'
import {
  getDistrictByState,
  getCityByDistrict,
  getAllState,
} from '../../api/locationApi'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
// ----------------------------------------------------------------------

const gender = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
]
const isDiabetic = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
]
const isChronicDisease = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
]
const isDigestionProblem = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
]
const isExercise = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
]

export default function SurgeryBooking() {
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const history = useHistory()
  const surgeryBooking = JSON.parse(localStorage.getItem('surgeryBooking'))
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const subSurgeryId = JSON.parse(localStorage.getItem('subSurgeryId'))
  const bookSurgery = JSON.parse(localStorage.getItem('bookSurgery'))
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    couponCode: Yup.string(),
    remark: Yup.string(),
    address: Yup.object().shape({
      houseNo: Yup.string(),
      streetNo: Yup.string(),
      locality: Yup.string(),
      landmark: Yup.string(),
      city: Yup.string(),
      district: Yup.string(),
      state: Yup.string(),
      pinCode: Yup.number()
        .positive()
        .min(100000, 'Please enter valid PinCode')
        .max(999999, 'Please enter valid PinCode')
        .required('PinCode is required'),
    }),
    discountPrice: Yup.string(),
    vendorId: Yup.string(),
    gender: Yup.string().required('Please select Gender'),
    age: Yup.number()
      .positive()
      .min(1, 'please enter age more than 0')
      .max(100, 'please enter age below 100')
      .required('Age is required'),
    weight: Yup.number(),
    height: Yup.number(),
    skinColor: Yup.string(),
    waistSize: Yup.number(),
    bloodPressure: Yup.string(),
    isDiabetic: Yup.string(),
    workType: Yup.string(),
    isChronicDisease: Yup.string(),
    medicineHistory: Yup.array().of(
      Yup.object().shape({
        medicineName: Yup.string(),
        medicineDuration: Yup.string(),
      }),
    ),
    sleepingTime: Yup.string(),
    morningMotion: Yup.string(),
    isDigestionProblem: Yup.string(),
    isExercise: Yup.string(),
    surgeryId: Yup.string(),
    paymentType: Yup.string(),
    paymentStatus: Yup.string(),
    userId: Yup.string(),
    price: Yup.string(),
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    surgeryDate: Yup.string(),
    surgeryStatus: Yup.string(),
    surgeryAttributeValues: Yup.array().of(
      Yup.object().shape({
        attributeName: Yup.string(),
        attributeValue: Yup.string(),
        attributePrice: Yup.number(),
      }),
    ),
  })

  const defaultValues = {
    name: bookSurgery?.name || '',
    couponCode: bookSurgery?.couponCode || '',
    remark: bookSurgery?.remark || '',
    discountPrice: surgeryBooking?.discountPrice,
    address: {
      houseNo: bookSurgery?.address?.houseNo || '',
      streetNo: bookSurgery?.address?.streetNo || '',
      locality: bookSurgery?.address?.locality || '',
      landmark: bookSurgery?.address?.landmark || '',
      city: bookSurgery?.address?.city || '',
      district: bookSurgery?.address?.district || '',
      state: bookSurgery?.address?.state || '',
      pinCode: bookSurgery?.address?.pinCode || 0,
    },
    vendorId: surgeryBooking?.vendorId,
    age: bookSurgery?.age || 1,
    gender: bookSurgery?.gender || '',
    weight: bookSurgery?.weight || 0,
    height: bookSurgery?.height || 0,
    skinColor: bookSurgery?.skinColor || '',
    waistSize: bookSurgery?.waistSize || 0,
    bloodPressure: bookSurgery?.bloodPressure || '',
    isDiabetic: bookSurgery?.isDiabetic || '',
    workType: bookSurgery?.workType || '',
    isChronicDisease: bookSurgery?.isChronicDisease || '',
    medicineHistory: bookSurgery?.medicineHistory || [
      {
        medicineName: '',
        medicineDuration: '',
      },
    ],
    sleepingTime: bookSurgery?.sleepingTime || 0,
    morningMotion: bookSurgery?.morningMotion || '',
    isDigestionProblem: bookSurgery?.isDigestionProblem || '',
    isExercise: bookSurgery?.isExercise || '',
    surgeryId: subSurgeryId,
    paymentType: 'postpaid',
    paymentStatus: 'unpaid',
    userId: userInfo._id,
    price: surgeryBooking?.price,
    mobile: bookSurgery?.mobile || '',
    surgeryDate: '',
    surgeryStatus: 'pending',
    surgeryAttributeValues: JSON.parse(
      localStorage.getItem('surgeryAttributeValues'),
    ),
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods
  const values = watch()

  useEffect(() => {
    ;(async () => {
      const { data } = await getAllState()
      setStateList(data.docs)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const { data: district } = await getDistrictByState(values.address.state)
      setDistrictList(district.docs)
    })()
  }, [values.address.state])

  useEffect(() => {
    ;(async () => {
      const { data: city } = await getCityByDistrict(values.address.district)
      setCityList(city.docs)
    })()
  }, [values.address.district])

  const onSubmit = async (data) => {
    if (
      data.address.state === '' ||
      data.address.district === '' ||
      data.address.city === ''
    ) {
      alert('Please select state, district and city')
      return
    }
    localStorage.setItem('bookSurgery', JSON.stringify(data))
    history.push('/payment')
  }

  const stateOptionsList = stateList.map((state) => ({
    label: state.name,
    value: state.name,
  }))
  const districtOptionsList = districtList.map((district) => ({
    label: district.name,
    value: district.name,
  }))
  const cityOptionsList = cityList.map((city) => ({
    label: city.name,
    value: city.name,
  }))

  const append = (data) => {
    setValue('medicineHistory', [...values.medicineHistory, data])
  }

  const remove = (index) => {
    const list = [...values.medicineHistory]
    list.splice(index, 1)
    setValue('medicineHistory', list)
  }

  console.log(errors)

  return (
    <>
      <Back name="Surgery Booking" title="Surgery Booking" cover={img} />
      <section className="formBg py-5">
        <div className="container row justify-content-center">
          <div className="col-md-12 b-x p-5 register_page">
            <Box sx={{ width: '100%' }}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <h4 className="paymentTitle">Fill the Basic details</h4>
                  <Stack direction="row" spacing={2}>
                    <Stack spacing={2} sx={{ width: '70%' }}>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="name"
                          label="Patient Name"
                        />
                        <RHFTextField
                          size="small"
                          name="mobile"
                          label="Mobile No"
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFSelect
                          size="small"
                          name="gender"
                          label="Gender"
                          placeholder="Gender"
                        >
                          <option value="" />
                          {gender.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFSelect>
                        <RHFTextField
                          size="small"
                          type="number"
                          name="age"
                          label="Age"
                        />
                      </Stack>
                      <Typography>Address details</Typography>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="address.houseNo"
                          label="Address Line 1"
                        />
                        <RHFTextField
                          size="small"
                          name="address.streetNo"
                          label="Address Line 2"
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="address.landmark"
                          label="Landmark"
                        />
                        <Autocomplete
                          name="address.state"
                          size="small"
                          id="combo-box-demo"
                          options={stateOptionsList}
                          key={stateOptionsList.length}
                          value={stateOptionsList.find(
                            (option) => option.value === values.address.state,
                          )}
                          // getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            setValue('address.state', newValue.value)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="State" />
                          )}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Autocomplete
                          name="address.district"
                          size="small"
                          id="combo-box-demo"
                          key={districtOptionsList.length + 1}
                          options={districtOptionsList}
                          value={districtOptionsList.find(
                            (option) =>
                              option.value === values.address.district,
                          )}
                          onChange={(event, newValue) => {
                            setValue('address.district', newValue.value)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="District" />
                          )}
                        />
                        <Autocomplete
                          name="address.city"
                          size="small"
                          id="combo-box-demo"
                          options={cityOptionsList}
                          key={cityOptionsList.length}
                          value={cityOptionsList.find(
                            (option) => option.value === values.address.city,
                          )}
                          onChange={(event, newValue) => {
                            setValue('address.city', newValue.value)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="Area" />
                          )}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="address.pinCode"
                          label="PinCode"
                          type="number"
                        />
                        <RHFTextField
                          size="small"
                          name="address.locality"
                          label="Locality"
                        />
                      </Stack>
                      <Typography>Additional details (Optional)</Typography>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="weight"
                          label="Enter weight (in KG)"
                          type="regex"
                          pattern="^\d+(\.\d{0,2})?$"
                          inputProps={{
                            inputMode: 'numeric',
                            // pattern: '[0-9]*[.]{1}[0-9]{1,2}',
                          }}
                        />
                        <RHFTextField
                          size="small"
                          name="height"
                          label="Enter height (in CM)"
                          type="regex"
                          pattern="^\d+(\.\d{0,2})?$"
                          inputProps={{
                            inputMode: 'numeric',
                            // pattern: '[0-9]*[.]{1}[0-9]{1,2}',
                          }}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="waistSize"
                          label="Enter waist Size (in Inches)"
                          type="regex"
                          pattern="^\d+(\.\d{0,2})?$"
                          inputProps={{
                            inputMode: 'numeric',
                            // pattern: '[0-9]*[.]{1}[0-9]{1,2}',
                          }}
                        />
                        <RHFTextField
                          size="small"
                          name="skinColor"
                          label="Enter skin color"
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="bloodPressure"
                          label="Blood Pressure"
                        />
                        <RHFSelect
                          size="small"
                          name="isDiabetic"
                          label="Diabetic"
                          placeholder="Diabetic"
                        >
                          <option value="" />
                          {isDiabetic.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFSelect>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFSelect
                          size="small"
                          name="workType"
                          label="Work Type"
                          placeholder="Work Type"
                        >
                          <option value="" />

                          <option key={'sitting'} value={'sitting'}>
                            {'Sitting'}
                          </option>
                          <option key={'field'} value={'field'}>
                            {'Field'}
                          </option>
                          <option key={'average'} value={'average'}>
                            {'Average'}
                          </option>
                        </RHFSelect>

                        <RHFSelect
                          size="small"
                          name="isChronicDisease"
                          label="Chronic Disease"
                          placeholder="Chronic Disease"
                        >
                          <option value="" />
                          {isChronicDisease.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFSelect>
                      </Stack>

                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="sleepingTime"
                          label="Sleeping Time (in Hours)"
                        />
                        <RHFSelect
                          size="small"
                          name="morningMotion"
                          label="Morning Motion"
                          placeholder="Morning Motion"
                        >
                          <option value="" />

                          <option key={'hard'} value={'hard'}>
                            {'Hard'}
                          </option>
                          <option key={'sticky'} value={'sticky'}>
                            {'Sticky'}
                          </option>
                          <option key={'Smooth'} value={'Smooth'}>
                            {'Smooth'}
                          </option>
                        </RHFSelect>
                      </Stack>

                      <Stack direction="row" spacing={2}>
                        <RHFSelect
                          size="small"
                          name="isDigestionProblem"
                          label="Digestion Problem"
                          placeholder="Digestion Problem"
                        >
                          <option value="" />
                          {isDigestionProblem.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFSelect>
                        <RHFSelect
                          size="small"
                          name="isExercise"
                          label="Exercise"
                          placeholder="Exercise"
                        >
                          <option value="" />
                          {isExercise.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFSelect>
                      </Stack>
                      <Stack direction="column" spacing={2}>
                        <Stack direction="row" spacing={2}>
                          <Typography variant="h6" className="paymentTitle">
                            Medicine History
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              append({ medicineName: '', duration: '' })
                            }}
                          >
                            Add
                          </Button>
                        </Stack>
                        {values.medicineHistory.map((item, index) => {
                          return (
                            <Stack key={index} direction={'row'} spacing={1}>
                              <RHFTextField
                                size="small"
                                name={`medicineHistory[${index}].medicineName`}
                                label="Medicine Name"
                              />
                              <RHFTextField
                                size="small"
                                name={`medicineHistory[${index}].duration`}
                                label="Duration (in months)"
                                inputProps={{
                                  inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                }}
                              />
                              <IconButton
                                size="small"
                                color="error"
                                onClick={() => {
                                  remove(index)
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                          )
                        })}
                      </Stack>
                    </Stack>
                    <Stack spacing={2}>
                      <div>
                        <h4>{surgeryBooking.surgeryName}</h4>
                      </div>

                      {values.surgeryAttributeValues
                        .filter(({ attributeName }) => attributeName != 'Price')
                        .map((item, index) => (
                          <div
                            className="hhhhhj"
                            key={index}
                            style={{
                              backgroundColor: '#eeeeee',
                              padding: '5px',
                            }}
                          >
                            <h6>{item.attributeName}:</h6>
                            <p className="px-2">{item.attributeValue}</p>
                          </div>
                        ))}
                      <div className="totalPaySec">
                        <hr></hr>
                        <div className="totalPay">
                          <h6>Total Payable</h6>
                          <h5>₹ {surgeryBooking.price}</h5>
                        </div>
                      </div>
                    </Stack>
                  </Stack>
                  <hr></hr>
                  <h4 className="paymentTitle">Payment Option</h4>
                  <FormControl className="m-0">
                    <RadioGroup
                      defaultValue="postpaid"
                      value={values.paymentType}
                      row
                    >
                      {/* <FormControlLabel value="payNow" control={<Radio />} label="Pay Now" /> */}
                      <FormControlLabel
                        value="postpaid"
                        name="paymentType"
                        control={<Radio />}
                        label="Cash On Hospital (COH) "
                      />
                      {/* <FormControlLabel
                        value="prepaid"
                        name='paymentType'
                        control={<Radio />}
                        label="Pay Now"
                      /> */}
                    </RadioGroup>
                  </FormControl>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    className="signBtn"
                    loading={isSubmitting}
                  >
                    Book Now
                  </LoadingButton>
                </Stack>
              </FormProvider>
            </Box>
          </div>
        </div>
      </section>
    </>
  )
}
