import { api } from '../utils/request';

export const changePassword = async (data) => {
  const res = await api({ endpoint: `/users/changePassword`, method: 'PUT', data });
  return res;
};

export const forgetPasswordChange = async (data) => {
  const {token,...rest} = data
  const res = await api({ endpoint: `/users/changePassword`, method: 'PUT', data:rest,headers:{
    'Authorization': `Bearer ${token}`
  } });
  return res;
}