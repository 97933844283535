import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, IconButton, InputAdornment, Button, Modal } from '@mui/material'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFTextField } from '../../hook-form'
import './forgotPassword.css'
import Iconify from '../../Iconify'
import 'bootstrap/dist/css/bootstrap.css'
import { login } from '../../../api/login'
import img from '../../images/bannerimg.jpg'
import Back from '../Back'
import { generateOtp } from '../../../api/login'
import {forgetPassword} from '../../../api/forgetPassword'
// ----------------------------------------------------------------------

export default function ForgotPassword({ toggleDrawer }) {
  const history = useHistory()
  // fetch token form params
  const [showError, setShowError] = useState()
  const [showLoader, setShowLoader] = useState()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [otp, setOtp] = useState(0)
  const [data, setData] = useState([])

  const LoginSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
  })

  const defaultValues = {
    mobile: '',
  }

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data) => {
   setData(data)
    const response = await generateOtp(data.mobile)
    if (response?.code === 201) {
      handleOpen()
    } else {
      alert(response.message)
    }
    setShowLoader(false)
  }

  const verify = async () => {
    //add OTP in data
    data.otp = otp
    const response = await forgetPassword(data)
    console.log(data)
    if (response?.data?.token) {
      // localStorage.setItem('userInfo', JSON.stringify(response.data))
      // localStorage.setItem('token', response.data.token)
      history.push('/changePassword/' + response.data.token)
      window.location.reload()
    } else {
      alert(response.message)
    }
  }

  return (
    <section className="signInn">
      <Back title="Forget Password" cover={img} />
      <section className="formBg py-5">
        <section className="loginPage">
          <div className="container">
            <Box sx={{ width: '50%' }}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <RHFTextField
                    size="small"
                    name="mobile"
                    label="Enter mobile number"
                  />
                  <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        className="signBtn"
                        loading={isSubmitting}
                      >
                        Send OTP
                      </LoadingButton>
                </Stack>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description"
                >
                  <Box className="changeChildPak">
                    <FormProvider className="text-center">
                      <div className="otpSec2">
                        <input
                          type="text"
                          placeholder="OTP"
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>
                      <div className="text-center">
                        <Button className="otpBtn" onClick={() => verify()}>
                          Verify
                        </Button>
                      </div>
                    </FormProvider>
                  </Box>
                </Modal>
              </FormProvider>
            </Box>
          </div>
        </section>
      </section>
    </section>
  )
}
