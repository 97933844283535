import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Box, Button } from '@mui/material'
import img from '../images/bannerimg.jpg'
import Back from '../common/Back'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFTextField } from '../hook-form'
import './paymentPackage.css'
import 'bootstrap/dist/css/bootstrap.css'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { getAvailability } from '../../api/availability'
import { createPackageBooking } from '../../api/bookings'
import { useParams } from 'react-router-dom'
import { getSingleVender } from '../../api/singleVendor'
// ----------------------------------------------------------------------

export default function PaymentClinic() {
  const [alignment, setAlignment] = React.useState('')
  const handleChange = (event, newAlignment) => {
    console.log('we are here too')
    setAlignment(newAlignment)
  }
  const [singleVendor, setSingleVendor] = useState([])
  const [packageBooking, setPackageBooking] = useState()
  const [value, setValue] = React.useState(new Date())
  const history = useHistory()
  const [availability, setAvailability] = useState([])
  const params = useParams()

  const [weekDay, setWeekDay] = useState('Monday')

  const RegisterSchema = Yup.object().shape({
    date: Yup.string(),
    from: Yup.string(),
    to: Yup.string(),
  })

  const defaultValues = {
    date: '',
    from: '',
    to: '',
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = methods
  const values = watch()

  useEffect(() => {
    ;(async () => {
      const packageLocalBooking = JSON.parse(
        localStorage.getItem('bookPackage'),
      )
      const { data } = await createPackageBooking(packageLocalBooking.vendorId)
      setSingleVendor(data)
      console.log(data)
    })()
  }, [])

  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    ;(async () => {
      const packageLocalBooking = JSON.parse(
        localStorage.getItem('bookPackage'),
      )
      setPackageBooking(packageLocalBooking)
    })()
  }, [])

  const onSubmit = async (data) => {
    console.log(data)
    const date = new Date(value).toLocaleDateString('sv-SE')
    const timeFrom = new Date(data.from).toLocaleTimeString('sv-SE')
    const timeTo = new Date(data.to).toLocaleTimeString('sv-SE')
    console.log(date + 'T' + timeFrom + ':00')

    const tempSlotFrom = new Date(date + 'T' + timeFrom).toISOString()
    const tempSlotTo = new Date(date + 'T' + timeTo).toISOString()
    packageBooking.slotFrom = tempSlotFrom
    packageBooking.slotTo = tempSlotTo

    console.log(packageBooking)
    const response = await createPackageBooking(packageBooking)
    if (response?.code === 201) {
      alert('Booking Successful')
      history.push('bookingHistory')
    } else {
      alert(response.message)
    }
  }
  const weekSlot = singleVendor?.weekSlots?.find(
    (weekSlot) => weekSlot.day === weekDay,
  )

  const setSlotSelect = (from, to) => {
    const date = new Date(value).toLocaleDateString('sv-SE')
    const slotFrom = new Date(date + 'T' + from + ':00').toISOString()
    const slotTo = new Date(date + 'T' + to + ':00').toISOString()
    setPackageBooking({
      ...packageBooking,
      slotFrom: slotFrom,
      slotTo: slotTo,
    })
  }

  return (
    <section className="signInn">
      <Back title="Payment" cover={img} />
      <section className="formBg">
        <div className="container row justify-content-center">
          <div className="col-md-6 b-x p-4 register_page">
            <div className="maintitle pb-3">Payment</div>
            <p>Welcome back ! Please enter your details for continue</p>
            <Box sx={{ width: '100%' }}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3} className="py-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      openTo="day"
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue)
                        console.log(newValue)
                        console.log(new Date(newValue).getDay())
                        setWeekDay(
                          new Date(newValue).toLocaleString('en-us', {
                            weekday: 'long',
                          }),
                        )
                      }}
                      disablePast
                      // shouldDisableDate={(date) => {
                      //   return availability.find(
                      //     (unavaialabledate) =>
                      //       new Date(unavaialabledate).toLocaleDateString() ===
                      //       new Date(date).toLocaleDateString(),
                      //   )
                      // }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <Stack direction={'row'} spacing={1}>
                    <RHFTextField
                      name="from"
                      InputLabelProps={{ shrink: true }}
                      label="From"
                      type="time"
                    />
                    <RHFTextField
                      name="to"
                      InputLabelProps={{ shrink: true }}
                      label="To"
                      type="time"
                    />
                  </Stack>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    // className="signBtn"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </FormProvider>
            </Box>
          </div>
        </div>
      </section>
    </section>
  )
}
