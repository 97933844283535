import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Back from '../common/Back'
import img from '../images/bannerimg.jpg'
import './clinic.css'
import VerifiedIcon from '@mui/icons-material/Verified'
import Autocomplete from '@mui/lab/Autocomplete'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'
import { useHistory } from 'react-router-dom'
import { getAllTreatment } from '../../api/treatment'
import { getAllVendorByTreatment } from '../../api/vendorByTreatment'
import { getAllSpeciality } from '../../api/speciality'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import { styled, useTheme } from '@mui/material/styles'
import {
  IconButton,
  InputAdornment,
  Stack,
  Slider,
  Pagination,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
} from '@mui/material'
import { getPincode } from '../../api/getPincode'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { message } from '../../utils/message'
// ---------------------------------------------------------------------

const limit = 5

const drawerWidth = 400

// reduce width of drawer
const Drawer = styled(
  MuiDrawer,
  {},
)(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    // position: "relative",
    // whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
      }),
      width: 0,

      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}))

const Clinic = () => {
  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setOpen(false)
  }
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    localStorage.removeItem('surgeryPrice')
  }, [])
  const [vendorByTreatment, setVendorByTreatment] = useState([])
  const [specialityList, setSpecialityList] = useState([])
  const [value, setValue] = useState('')
  const history = useHistory()
  const [pinCode, setPinCode] = useState([])
  const [cityList, setCityList] = useState([])
  const [isSort, setIsSort] = useState(false)
  const [search, setSearch] = React.useState('')
  const [selectLocation, setSelectLocation] = useState(
    JSON.parse(localStorage.getItem('selectLocation')),
  )
  const [paginationData, setPaginationData] = useState({
    total: 0,
    pageCount: 0,
    currentPage: 0,
  })

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllSpeciality()
      setSpecialityList(data.docs)
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      const { data } = await getPincode({
        districtName: selectLocation.districtName,
        stateName: selectLocation.state,
      })

      const uniquePincode = [];
      data.forEach((item) => {
        const isExist = uniquePincode.find((value) => {
          return value.pinCode === item.pinCode
        })
        if (!isExist) {
          uniquePincode.push(item)
        }
      })
      setPinCode(uniquePincode)
      //remove duplicate city
      const city = data.map((item) => item.cityName)
      const uniqueCity = [...new Set(city)]
      setCityList(uniqueCity)
    })()
  }, [selectLocation.districtName, selectLocation.state])
  const shortByPrice = () => {
    if (isSort) {
      const sorted = [...vendorByTreatment]
      sorted.sort((a, b) => {
        return b.price - a.price
      })
      setVendorByTreatment(sorted)
      setIsSort(false)
    } else {
      const sorted = [...vendorByTreatment]
      sorted.sort((a, b) => {
        return a.price - b.price
      })
      setVendorByTreatment(sorted)
      setIsSort(true)
    }
  }

  useEffect(() => {
    window.onLocationChange = () => {
      console.log('location changed')
      setSelectLocation(JSON.parse(localStorage.getItem('selectLocation')))
    }
    return () => {
      window.onLocationChange = () => { }
    }
  }, [])

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllVendorByTreatment({
        limit,
        skip: 0,
        // treatmentId: '6411729bd19b56f9aa66f663',

        district: selectLocation.districtName,
        state: selectLocation.state,
        type: 'clinic',
      })
      setVendorByTreatment(data.docs)
      setPaginationData({
        total: data.totalDocs,
        pageCount: data.totalPages || 1,
        currentPage: data.page,
      })
    })()
  }, [selectLocation.districtName, selectLocation.state])

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllVendorByTreatment({
        limit,
        skip: 0,
        // treatmentId: '6411729bd19b56f9aa66f663',

        district: selectLocation.districtName,
        state: selectLocation.state,
        type: 'clinic',
        specialist: search,
      })
      setIsLoading(false)
      setVendorByTreatment(data.docs)
      setPaginationData({
        total: data.totalDocs,
        pageCount: data.totalPages || 1,
        currentPage: data.page,
      })
    })()
  }, [search, selectLocation.districtName, selectLocation.state])

  const getYear = () => {
    const currentYear = new Date().getFullYear()
    return currentYear
  }

  const [surgeryFilters, setSurgeryFilters] = useState({
    city: [],
    pinCode: [],
  })
  const handleCityChange = (event) => {
    if (event.target.checked) {
      setSurgeryFilters({
        ...surgeryFilters,
        city: [...new Set([...surgeryFilters.city, event.target.value])],
      })
    } else {
      setSurgeryFilters({
        ...surgeryFilters,
        city: surgeryFilters.city.filter((city) => {
          return city != event.target.value
        }),
      })
    }
  }

  const handlePinCodeChange = (event) => {
    console.log('we are here')
    console.log(event.target.checked)
    if (event.target.checked) {
      setSurgeryFilters({
        ...surgeryFilters,
        pinCode: [...new Set([...surgeryFilters.pinCode, event.target.value])],
      })
    } else {
      setSurgeryFilters({
        ...surgeryFilters,
        pinCode: surgeryFilters.pinCode.filter((pinCode) => {
          return pinCode != event.target.value
        }),
      })
    }
  }

  const filterBtnClick = async (data) => {
    const response = await getAllVendorByTreatment({
      city: surgeryFilters.city,
      pinCode: surgeryFilters.pinCode,
      state: selectLocation.state,
      district: selectLocation.districtName,
      type: 'clinic',
      specialist: search,
    })
    if (response.code === 200) {
      setVendorByTreatment(response.data.docs)

      // alert('Hospital Fetched Successfully')
      // window.location.reload()
      handleDrawerClose()
    } else {
      alert(response.message)
    }
  }
  const [isloading, setIsLoading] = useState(true)
  const getAllVendors = async () => {
    const { data } = await getAllVendorByTreatment({
      limit,
      skip: 0,

      state: selectLocation.state,
      district: selectLocation.districtName,
      type: 'clinic',
      specialist: search,
    })

    setVendorByTreatment(data.docs)
    setPaginationData({
      total: data.totalDocs,
      pageCount: data.totalPages || 1,
      currentPage: data.page,
    })
  }

  return (
    <>
      <section className="clinic">
        <Back
          name="Clinic"
          title="FIND A BEST CLINIC FOR REQUEST AN APPOINTMENT"
          cover={img}
        />
        <section className="clinicSearch">
          <div className="container">
            <div className="searchBox">
              <Autocomplete
                name="speciality"
                size="small"
                id="combo-box-demo"
                value={specialityList.find((item) => item._id === search)}
                options={specialityList}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue, reason) => {
                  if (reason === 'clear') {
                    return setSearch('')
                  }
                  console.log(newValue)
                  setSearch(newValue._id)
                }}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField {...params} label="Search Speciality" />
                )}
              />
            </div>
          </div>
        </section>
        <div className="container py-5">
          <h3 className="pageTitle">
            Showing Best <span>Doctor's Clinics</span> list
          </h3>
          <p className="pageSubTitle">
            Choose from the list of top-selling packages tailored for you. We
            care for your health
          </p>
          <div className="row justify-content-end">
            <div className="col-md-6">
              <div className="shortingBtn">
                <Box open={open} sx={{ display: 'flex' }}>
                  <CssBaseline />
                  <IconButton onClick={handleDrawerOpen} className="filterBtn">
                    Filter
                    <img src="/images/filter.png" alt="filter" />
                  </IconButton>
                  <div>
                    <React.Fragment>
                      <Drawer
                        anchor={'right'}
                        open={open}
                        onClose={toggleDrawer()}
                      >
                        <Box className="drawerrr">
                          <h6 className="titleF">Filter By</h6>

                          <Box className="px-4">
                            <h6 className="py-2 filter_title">Specific Area</h6>

                            {cityList?.map((item, index) => (
                              <FormControlLabel
                                className="inputSe"
                                control={
                                  <Checkbox
                                    checked={surgeryFilters.city.includes(item)}
                                  />
                                }
                                label={item}
                                value={item}
                                onChange={handleCityChange}
                              >
                                {item}
                              </FormControlLabel>
                            ))}

                            <h6 className="py-2 filter_title">Pincode</h6>

                            {pinCode?.map((item, index) => (
                              <FormControlLabel
                                className="inputSe"
                                control={
                                  <Checkbox
                                    checked={surgeryFilters.pinCode.includes(
                                      item.pinCode.toString(),
                                    )}
                                  />
                                }
                                label={item.pinCode}
                                value={item.pinCode}
                                onClick={handlePinCodeChange}
                              >
                                {item.pinCode}
                              </FormControlLabel>
                            ))}
                            <Stack spacing={2} direction="row" sx={{ pt: 3 }}>
                              <Button
                                className="filterBtn text-white mx-auto mt-3"
                                onClick={filterBtnClick}
                              >
                                Submit
                              </Button>
                              <Button
                                className="filterBtn text-white mx-auto mt-3"
                                onClick={() => {
                                  getAllVendors()
                                  setSurgeryFilters({
                                    price: [0, 500000],
                                    city: [],
                                    pinCode: [],
                                  })
                                }}
                              >
                                Clear
                              </Button>
                            </Stack>
                          </Box>
                        </Box>
                      </Drawer>
                    </React.Fragment>
                  </div>
                </Box>
                <Button
                  variant={'outlined'}
                  sx={{
                    color: '#4E5360',
                    borderColor: '#4E5360',
                  }}
                  className="sortBtn"
                  onClick={shortByPrice}
                  endIcon={
                    isSort ? (
                      <KeyboardDoubleArrowUpIcon />
                    ) : (
                      <KeyboardDoubleArrowDownIcon />
                    )
                  }
                >
                  Sort by Price
                </Button>
              </div>
            </div>
          </div>
          {isloading ? <LoadingPage /> :
            <>
              {vendorByTreatment.length === 0 ? (
                <Typography className="contactL" variant="h4">
                  {message}
                </Typography>
              ) : (
                vendorByTreatment?.map((clinic) => (
                  <div className="row py-3 cliniCard">
                    <div className="col-md-4">
                      <div className="cliniCardImg">
                        <img
                          src={clinic.logo ? clinic.logo : '/images/clinic.png'}
                          alt="Avatar"
                          className="cliniImg"
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="cliniCardContent">
                        <div className="cliniCardTitle pb-3">
                          <h3>{clinic?.name}</h3>
                          <h3>
                            Price - <span>₹ {clinic?.price}</span>
                          </h3>
                        </div>

                        <p>
                          {'Doctor: ' +
                            clinic?.doctorName +
                            '-(' +
                            specialityList.find(
                              (item) => item._id === clinic?.specialist,
                            )?.name +
                            ')'}{' '}
                        </p>
                        <p>{clinic?.highestDegree} </p>
                      </div>
                      <div className="clinicD">
                        {clinic?.isVerified ? (
                          <div className="clinicDItem">
                            <div className="clinicDicon">
                              <VerifiedIcon />
                            </div>
                            <p>
                              {clinic?.isVerified ? 'Verified' : 'Not Verified'}
                            </p>
                          </div>
                        ) : null}
                        {clinic?.isTrusted ? (
                          <div className="clinicDItem">
                            <div className="clinicDicon">
                              <VerifiedUserOutlinedIcon />
                            </div>
                            <p>{clinic?.isTrusted ? 'Trusted' : ''}</p>
                          </div>
                        ) : null}

                        <div className="clinicDItem">
                          <div className="clinicDicon">
                            <img src="/images/bag.png" alt="" />
                          </div>
                          <p>
                            {getYear() - clinic?.yearOfEstablishment} Year
                            Experience
                          </p>
                        </div>
                        <div className="clinicDItem">
                          <div className="clinicDicon">
                            <img src="/images/location.svg" alt="" />
                          </div>
                          <p>
                            {clinic?.address?.city}, {clinic?.address?.district}
                          </p>
                        </div>
                      </div>
                      <div className="viewBook">
                        <Link to={`/clinicDetailPage/${clinic._id}`}>
                          View more details
                        </Link>
                        <button
                          onClick={() => {
                            history.push(`/clinicDetailPage/${clinic._id}`)
                          }}
                          className="bookNowBtn"
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
              {paginationData.pageCount > 1 && (
                <Stack
                  spacing={2}
                  sx={{
                    pt: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Pagination
                    count={paginationData.pageCount || 1}
                    onChange={async (e, page) => {
                      const { data } = await getAllVendorByTreatment({
                        limit,
                        skip: (page - 1) * limit,
                        // treatmentId: '6411729bd19b56f9aa66f663',
                        city: selectLocation.name,
                        district: selectLocation.districtName,
                        state: selectLocation.state,
                        type: 'clinic',
                        specialist: search,
                      })
                      setVendorByTreatment(data.docs)
                    }}
                    color="primary"
                  />
                </Stack>
              )}
            </>
          }
        </div>
      </section>
    </>
  )
}
const LoadingPage = () => {
  return (
    <div style={{
      display: 'Grid',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src='../../../../images/giphy.gif' alt='' />
    </div>

  )
}
export default Clinic
