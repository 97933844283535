import React from "react"
import Home from "./homePage/Home"
import TopSurgery from "./topSurgery/TopSurgery"
import OurServices from "./ourServices/OurServices"
import AboutSection from "./aboutSection/AboutSection"
import BookSurgery from "./bookSurgery/BookSurgery"
import StepSec from "./stepSec/StepSec"
import FindClinic from "./findClinic/FindClinic"
import UserReviews from "./userReviews/UserReviews"
import BookAppointment from "./bookAppointment/BookAppointment"

const HomePage = () => {
  return (
    <>
      <Home />
      <TopSurgery />
      <OurServices />
      <AboutSection />
      <BookSurgery />
      <StepSec />
      <FindClinic />
      <UserReviews />
      <BookAppointment />
    </>
  )
}

export default HomePage
