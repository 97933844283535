import * as Yup from 'yup';
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import img from "../images/bannerimg.jpg"
import Autocomplete from '@mui/lab/Autocomplete';
import Back from "../common/Back"
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField, RHFSelect } from '../hook-form';
import "./packageBooking.css"
import 'bootstrap/dist/css/bootstrap.css';
import Link from 'react-router-dom/Link';
import { getDistrictByState, getCityByDistrict, getAllState } from '../../api/locationApi';
import { createPackage } from '../../api/bookings';
// ----------------------------------------------------------------------
const gender = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
];

export default function PackageBooking() {
  const history = useHistory();
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const packageBooking = JSON.parse(localStorage.getItem('packageBooking'));
  const bookPackage = JSON.parse(localStorage.getItem('bookPackage'));

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    gender: Yup.string().required('Please select Gender'),
    age: Yup.number()
      .positive()
      .min(1, 'please enter age more than 0')
      .max(100, 'please enter age below 100')
      .required('Age is required'),
    // email: Yup.string().required("Email is required"),
    address: Yup.object().shape({
      houseNo: Yup.string(),
      streetNo: Yup.string(),
      locality: Yup.string(),
      landmark: Yup.string(),
      city: Yup.string(),
      district: Yup.string(),
      state: Yup.string(),
      pinCode: Yup.number()
        .positive()
        .min(100000, 'Please enter valid Pincode')
        .max(999999, 'Please enter valid Pincode')
        .required('Pincode is required'),
    }),
    treatment: Yup.string(),
    price: Yup.string(),
    isPaid: Yup.string(),
    customerId: Yup.string(),
    paymentType: Yup.string(),
    slotTo: Yup.string(),
    slotFrom: Yup.string(),
    packageName: Yup.string(),
    packageId: Yup.string(),
    status: Yup.string(),
    paymentStatus: Yup.string(),


  });

  const defaultValues = {
    name: bookPackage?.name || '',
    mobile: bookPackage?.mobile || '',
    gender: bookPackage?.gender || '',
    age: bookPackage?.age || '',
    // email: bookPackage?.email || '',
    address: {
      houseNo: bookPackage?.address?.houseNo || '',
      streetNo: bookPackage?.address?.streetNo || '',
      locality: bookPackage?.address?.locality || '',
      landmark: bookPackage?.address?.landmark || '',
      city: bookPackage?.address?.city || '',
      district: bookPackage?.address?.district || '',
      state: bookPackage?.address?.state || '',
      pinCode: bookPackage?.address?.pinCode || 0,
    },
    price: packageBooking?.price,
    status: packageBooking?.status,
    isPaid: packageBooking?.isPaid,
    customerId: packageBooking?.customerId,
    paymentType: packageBooking?.paymentType,
    slotTo: '',
    slotFrom: '',
    packageName: packageBooking?.packageName,
    paymentStatus: packageBooking?.paymentStatus,
    packageId: packageBooking?.packageId,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,

    formState: { isSubmitting },
  } = methods;
  const values = watch();

  useEffect(() => {
    (async () => {
      const { data } = await getAllState();
      setStateList(data.docs);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data: district } = await getDistrictByState(values.address.state);
      setDistrictList(district.docs);
    })();
  }, [values.address.state]);

  useEffect(() => {
    (async () => {
      const { data: city } = await getCityByDistrict(values.address.district);
      setCityList(city.docs);
    })();
  }, [values.address.district]);

  const onSubmit = async (data) => {
    if (data.address.state === '' || data.address.district === '' || data.address.city === '') {
      alert('Please select state, district and city')
      return
    }
    localStorage.setItem('bookPackage', JSON.stringify(data))
    history.push('paymentPackage');
  };

  const stateOptionsList = stateList.map((state) => ({
    label: state.name,
    value: state.name,
  }))
  const districtOptionsList = districtList.map((district) => ({
    label: district.name,
    value: district.name,
  }))
  const cityOptionsList = cityList.map((city) => ({
    label: city.name,
    value: city.name,
  }))

  return (
    <>
      <Back name='Package Booking' title='Package Booking' cover={img} />
      <section className='formBg py-5'>
        <div className="container row justify-content-center">
          <div className="col-md-12 b-x p-5 register_page">
            <Box sx={{ width: '100%' }}>
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Stack spacing={3}>
                  <h4 className='paymentTitle'>Fill the Basic details</h4>
                  <Stack direction="row" spacing={2}>
                    <Stack spacing={2} sx={{ width: '70%' }}>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField size="small" name="name" label="Patient Name" />
                        <RHFTextField size="small" name="mobile" label="Mobile No" />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFSelect size="small" name="gender" label="gender" placeholder="Gender">
                          <option value="" />
                          {gender.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFSelect>
                        <RHFTextField size="small" name="age" type="number" label="Age" />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        {/* <RHFTextField size="small" name="email" label="Email" /> */}
                        <RHFTextField size="small" name="address.houseNo" label="House No" />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField size="small" name="address.streetNo" label="Street" />
                        <RHFTextField size="small" name="address.landmark" label="Landmark" />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Autocomplete
                          name="address.state"
                          size="small"
                          id="combo-box-demo"
                          options={stateOptionsList}
                          key={stateOptionsList.length}
                          value={stateOptionsList.find(
                            (option) => option.value === values.address.state,
                          )}
                          // getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            setValue('address.state', newValue.value)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="State" />
                          )}
                        />
                        <Autocomplete
                          name="address.district"
                          size="small"
                          id="combo-box-demo"
                          key={districtOptionsList.length + 1}
                          options={districtOptionsList}
                          value={districtOptionsList.find(
                            (option) =>
                              option.value === values.address.district,
                          )}
                          onChange={(event, newValue) => {
                            setValue('address.district', newValue.value)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="District" />
                          )}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Autocomplete
                          name="address.city"
                          size="small"
                          id="combo-box-demo"
                          options={cityOptionsList}
                          key={cityOptionsList.length}
                          value={cityOptionsList.find(
                            (option) => option.value === values.address.city,
                          )}
                          onChange={(event, newValue) => {
                            setValue('address.city', newValue.value)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="Area" />
                          )}
                        />
                        <RHFTextField size="small" name="address.pinCode" type="number" label="PinCode" />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField size="small" name="address.locality" label="Locality" />
                      </Stack>
                    </Stack>
                    <Stack spacing={2}>
                      <div className='totalPaySec'>
                        <h4>Packages</h4>
                        <hr></hr>
                        <div className='totalPay'>
                          <h6>Total Payable</h6>
                          <h5>₹ {packageBooking?.price}</h5>
                        </div>
                      </div>
                    </Stack>
                  </Stack>

                  <h4 className='paymentTitle'>Payment Option</h4>

                  <FormControl className='m-0'>
                    <RadioGroup
                      defaultValue="postpaid"
                      value={values.paymentType}
                      row
                    >
                      {/* <FormControlLabel value="payNow" control={<Radio />} label="Pay Now" /> */}
                      <FormControlLabel
                        value="postpaid"
                        name="paymentType"
                        control={<Radio />}
                        label="Cash"
                      />
                      {/* <FormControlLabel
                        value="prepaid"
                        name='paymentType'
                        control={<Radio />}
                        label="Pay Now"
                      /> */}
                    </RadioGroup>
                  </FormControl>
                  <LoadingButton fullWidth size="large" type="submit" className='signBtn' loading={isSubmitting}>
                    Book Now
                  </LoadingButton>
                </Stack>
              </FormProvider>
            </Box>
          </div>
        </div>
      </section>
    </>
  );
}
