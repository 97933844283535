import React, { useEffect, useState } from "react"
import Back from "../common/Back"
import img from "../images/bannerimg.jpg"
import "./bookRadiologyTests.css"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DoneIcon from '@mui/icons-material/Done';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DescriptionIcon from '@mui/icons-material/Description';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Stack, Pagination } from '@mui/material';
import { getAllVendorByLabTest } from "../../api/vendorByLabTest";
// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const BookRadiologyTests = () => {
  const searchParams = useHistory()
  const [vendorByLabTest, setAllVendorByLabTest] = useState([]);
  const selectLocation = JSON.parse(localStorage.getItem('selectLocation'));
  const [isloading, setIsLoading] = useState(true)
  useEffect(() => {
    (async () => {
      const { data } = await getAllVendorByLabTest(
        {
          pathLabTestIds: searchParams.location.search.split("labtest=")[1].split(","),
          city: selectLocation.name,
          state: selectLocation.state,
          district: selectLocation.districtName,
        }
      );
      setAllVendorByLabTest(data.docs);
      console.log(data.docs);
      setIsLoading(false)
    })();
  }, []);

  const getYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear;
  };

  return (
    <>
      <section className='bookRadiologyTests'>
        <Back name='Book Radiology Tests' title='Book Radiology Tests' cover={img} />
        {isloading ? <LoadingPage /> :
          <div className='container py-5'>
            <h3 className='pageTitle'>Book <span>Radiology</span> Tests</h3>
            <p className='pageSubTitle'>Choose from the list of top-selling packages tailored for you. We care for your health</p>

            <Box sx={{ flexGrow: 1, pt: 4 }}>
              <Grid container spacing={2}>
                {vendorByLabTest.map((item) => (
                  <Grid item xs={6}>
                    <Item>
                      <div className="labsListing">
                        <h3>{item.name}</h3>
                        <h4>Price - <span>₹ {item.price}</span></h4>
                      </div>
                      <hr></hr>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="labsListCol">
                            <div className="iconLabs">
                              <LocationOnIcon />
                            </div>
                            <p>{item.address.city},{item.address.state}</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="labsListCol">
                            <div className="iconLabs">
                              <DoneIcon />
                            </div>
                            <p>{item.type}</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="labsListCol">
                            <div className="iconLabs">
                              <LocalPhoneIcon />
                            </div>
                            <p>{item.mobile}</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="clinicDItem">
                            <div className="clinicDicon">
                              <img src="/images/bag.png" alt="" />
                            </div>
                            <p>{getYear() - item.yearOfEstablishment} Year Experience</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="labsListCol">
                            <div className="iconLabs">
                              <DescriptionIcon />
                            </div>
                            <p>{item.description?.substring(0, 10)}</p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <Link to={`/radiologyDetailPage/${item._id}`}> View details</Link>
                        <div className="labsListCol">
                          <Link to={`/radiologyDetailPage/${item._id}`}>
                            <button className='bookNowBtn'>Book Now</button>
                          </Link>
                        </div>
                      </div>
                    </Item>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Stack spacing={2} sx={{
              pt: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Pagination count={10} color="primary" />
            </Stack>
          </div>
        }
      </section>
    </>
  )
}
const LoadingPage = () => {
  return (
    <div style={{
      display: 'Grid',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src='../../../../images/giphy.gif' alt='' />
    </div>

  )
}
export default BookRadiologyTests
