import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import img from '../images/bannerimg.jpg'
import Back from '../common/Back'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFTextField } from '../hook-form'
import './editProfile.css'
import 'bootstrap/dist/css/bootstrap.css'
import { editMyProfile, myProfile } from '../../api/myProfile'
import { TextField, Typography } from '@mui/material'
import Autocomplete from '@mui/lab/Autocomplete'
import { Stack, IconButton, InputAdornment, Grid } from '@mui/material'

import {
  getAllState,
  getDistrictByState,
  getCityByDistrict,
} from '../../api/locationApi'
// ----------------------------------------------------------------------
import { fileUpload } from '../../utils/request'
import { deleteAccount } from '../../api/login'
export default function EditProfile() {
  const history = useHistory()
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const token = localStorage.getItem('token')

  const [personInfo, setPersonInfo] = useState({
    userName: '',
    mobile: '',
    email: '',
    image: '',
    uniquePatientId: '',
    address: {
      houseNo: '',
      streetNo: '',
      locality: '',
      landmark: '',
      city: '',
      district: '',
      state: '',
      pinCode: '',
    },
  })

  console.log(personInfo)

  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [districtList, setDistrictList] = useState([])

  const RegisterSchema = Yup.object().shape({
    userName: Yup.string().required('User Name required'),
    mobile: Yup.string(),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    image: Yup.string(),
    uniquePatientId: Yup.string(),
    address: Yup.object().shape({
      houseNo: Yup.string(),
      streetNo: Yup.string(),
      locality: Yup.string(),
      landmark: Yup.string(),
      city: Yup.string(),
      district: Yup.string(),
      state: Yup.string(),
      pinCode: Yup.string(),
    }),
  })

  const defaultValues = {
    userName: '',
    mobile: '',
    email: '',
    image: '',
    uniquePatientId: '',
    address: {
      houseNo: '',
      streetNo: '',
      locality: '',
      landmark: '',
      city: '',
      district: '',
      state: '',
      pinCode: '',
    },
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods
  const values = watch()

  useEffect(() => {
    ;(async () => {
      const resp = await myProfile()
      console.log(resp.data)
      setValue('userName', resp.data?.userName)
      setValue('mobile', resp.data?.mobile)
      setValue('email', resp.data?.email)
      setValue('image', resp.data?.image)
      setValue('uniquePatientId', resp.data?.uniquePatientId)
      setValue('address.houseNo', resp.data?.address?.houseNo)
      setValue('address.streetNo', resp.data?.address?.streetNo)
      setValue('address.locality', resp.data?.address?.locality)
      setValue('address.landmark', resp.data?.address?.landmark)
      setValue('address.city', resp.data?.address?.city)
      setValue('address.district', resp.data?.address?.district)
      setValue('address.state', resp.data?.address?.state)
      setValue('address.pinCode', resp.data?.address?.pinCode)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const { data } = await getAllState()
      setStateList(data.docs)
      const { data: district } = await getDistrictByState(
        userInfo.address.state,
      )
      setDistrictList(district.docs)
      const { data: city } = await getCityByDistrict(userInfo.address.district)
      setCityList(city.docs)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const { data: district } = await getDistrictByState(values.address.state)
      setDistrictList(district.docs)
    })()
  }, [values.address.state])

  useEffect(() => {
    ;(async () => {
      const { data: city } = await getCityByDistrict(values.address.district)
      setCityList(city.docs)
    })()
  }, [values.address.district])

  const onProfileChange = async (data) => {
    const response = await editMyProfile(data)
    if (response.code === 200) {
      alert('Profile Update')
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      window.location.reload()
      // history.push('/editProfile')
    } else {
      alert(response.message)
    }
  }

  const stateOptions = stateList.map((state) => {
    return {
      value: state._id,
      name: state.name,
    }
  })

  const districtOptions = districtList.map((district) => {
    return {
      value: district._id,
      name: district.name,
    }
  })

  const cityOptions = cityList.map((city) => {
    return {
      value: city._id,
      name: city.name,
    }
  })
  const [file, setFile] = useState()
  const handleChange = async (e) => {
    // console.log(e.target.files)
    setFile(URL.createObjectURL(e.target.files[0]))

    const resp = await fileUpload(e.target.files[0], '/file')
    // console.log(JSON.parse(resp) || '[]')
    const images = JSON.parse(resp) || '[]'
    // console.log(images.fileUrl)
    setValue('image', images.fileUrl)
  }
  console.log(values)
  useEffect(() => {
    setFile(userInfo.image)
  }, [userInfo.image])

  return (
    <>
      <Back name="Edit Profile" title="Edit Profile" cover={img} />
      <section className="formBg py-5 editProfile">
        <div className="container row justify-content-center">
          <div className="col-md-12 b-x p-5">
            <div className="row">
              <div className="col-md-4 editProfileTitle">
                {/* <h3>Edit Profile</h3> */}
                {/* <Link to='./'>View Profile</Link> */}
                <button
                  className="signBtn"
                  onClick={() => {
                    history.push(`/deleteAccount/${token}`)
                  }}
                >
                  Delete Account
                </button>
              </div>
              {values && (
                <div className="col-md-8">
                  <Box
                    sx={{ width: '100%', border: '1px solid #E9E9E9', p: 5 }}
                  >
                    <FormProvider
                      methods={methods}
                      onSubmit={handleSubmit(onProfileChange)}
                    >
                      <Stack spacing={3} className="py-4">
                        <div className="App">
                          <h4 className="m-0">Profile Image:</h4>
                          <input type="file" onChange={handleChange} />
                          <img
                            style={{
                              height: '150px',
                              width: '150px',
                            }}
                            src={file}
                          />
                        </div>
                        <RHFTextField
                          size="small"
                          name="userName"
                          label="User Name"
                        />
                        <RHFTextField
                          size="small"
                          name="mobile"
                          label="Phone Number"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <RHFTextField
                          size="small"
                          name="email"
                          label="Email"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <RHFTextField
                          size="small"
                          name="uniquePatientId"
                          label="Unique Patient Id"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <Typography>Address details</Typography>
                        <Stack direction="row" spacing={2}>
                          {' '}
                          <RHFTextField
                            size="small"
                            name="address.houseNo"
                            label="House No"
                          />
                          <RHFTextField
                            size="small"
                            name="address.streetNo"
                            label="Street/Road"
                          />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <RHFTextField
                            size="small"
                            name="address.landmark"
                            label="Landmark"
                          />

                          <RHFTextField
                            size="small"
                            name="address.locality"
                            label="Locality"
                          />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <Autocomplete
                            name="address.state"
                            size="small"
                            id="combo-box-demo"
                            value={
                              stateOptions.find(
                                (state) =>
                                  state.name === userInfo?.address?.state,
                              ) || null
                            }
                            options={stateOptions}
                            onChange={(event, newValue) => {
                              setValue('address.state', newValue.name)
                            }}
                            getOptionLabel={(option) => option.name}
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                              <TextField {...params} label="State" />
                            )}
                          />

                          <Autocomplete
                            name="address.district"
                            size="small"
                            id="combo-box-demo"
                            value={
                              districtList.find(
                                (district) =>
                                  district.name === values.address.district,
                              ) || null
                            }
                            options={districtList}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                              setValue('address.district', newValue.name)
                            }}
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                              <TextField {...params} label="District" />
                            )}
                          />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <Autocomplete
                            name="address.city"
                            size="small"
                            id="combo-box-demo"
                            value={
                              cityList.find(
                                (city) => city.name === values.address.city,
                              ) || null
                            }
                            options={cityList}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                              setValue('address.city', newValue.name)
                            }}
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                              <TextField {...params} label="Area" />
                            )}
                          />

                          <RHFTextField
                            size="small"
                            name="address.pinCode"
                            label="PinCode"
                            type="number"
                          />
                        </Stack>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          className="signBtn"
                          loading={isSubmitting}
                        >
                          Save changes
                        </LoadingButton>
                      </Stack>
                    </FormProvider>
                  </Box>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
