import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img from "../images/bannerimg.jpg"
import "./blog.css"

const Blog = () => {
  return (
    <>
      <section className='supportCenter'>
        <Back title='Blog' cover={img} />
        <div className='container row py-4'>
          <div className='left col-md-12'>
            <h3>1. What is surgery?</h3>
            <p><strong>Ans-</strong> A medical treatment process in which your body will get a cut (through manually and/or instrumental technique) to get off your health problem. The act of performing surgery may be called a surgical procedure or operation.</p>
            <strong>*  Surgery can have many purposes, including: </strong>
            <ul className="blogList">
              <li>Making or confirming a diagnosis</li>
              <li>Removing damaged tissue or an obstruction</li>
              <li>Repairing or repositioning tissues or organs</li>
              <li>Implanting devices</li>
              <li>Redirecting blood vessels</li>
              <li>Transplanting tissues or organs</li>
              <li>Pain prevention</li>
              <li>Body adjustments</li>
            </ul>
            <strong>There are many types of surgery, including: </strong>
            <ul className="blogList">
              <li>Bariatric surgery</li>
              <li>Breast surgery</li>
              <li>Colon & rectal surgery</li>
              <li>Endocrine surgery</li>
              <li>General surgery</li>
              <li>Gynecological surgery</li>
              <li>Hand surgery</li>
              <li>Laparoscopy surgery</li>
              <li>Proctology</li>
              <li>Head & neck surgery</li>
              <li>Orthopedics surgery</li>
            </ul>
            <h3>2. What is Level 2 UltraSound?</h3>
            <p className="py-2">A level 2 ultrasound, also known as a fetal anatomical survey, is <strong>a prenatal scan that evaluates the anatomical details of a developing fetus.</strong> It's also known as an anomaly scan, 20-week ultrasound, or 2nd trimester ultrasound. </p>
            <strong>A level 2 ultrasound:</strong>
            <ul className="blogList">
              <li>Measures the size of the fetus</li>
              <li>Evaluates the baby's major organs</li>
              <li>Measures the amount of amniotic fluid</li>
              <li>Measures the length of the cervix</li>
              <li>Determines the location of the placenta</li>
              <li>Detects birth defects</li>
            </ul>
            <p>A level 2 ultrasound is recommended during pregnancy, between 18 and 22 weeks gestation. It works in the same way as a standard ultrasound but provides more detailed information. </p>
            <strong>A level 2 ultrasound may be recommended if: </strong>
            <ul className="blogList">
              <li>Previous prenatal testing has indicated fetal anomalies</li>
              <li>There is a family history of chromosomal abnormalities</li>
              <li>There has been spotting during pregnancy</li>
            </ul>
            <p>A level 2 ultrasound can take 30 to 45 minutes. During the scan, you may be able to see your baby's beating heart, the curve of the spine, and the face, arms, and legs. </p>
            <p>The safety of surgery depends on the type of surgery and how healthy you are. The risk of death in surgery increases with age. Emergency surgery is usually riskier than planned surgeries. </p>
            <h3>3. What is laparoscopy surgery?</h3>
            <p>Laparoscopic surgery, also known as keyhole surgery, is <strong>a surgical technique that allows a surgeon to look inside the body without making a large incision.</strong> During the procedure, the surgeon inserts a laparoscope, a thin rod with a camera attached, into the abdomen. The surgery usually takes 30 to 60 minutes.</p>
            <strong>Laparoscopic surgery can be used for:</strong>
            <ul className="blogList">
              <li>Diagnosing conditions that develop inside the abdomen or pelvis</li>
              <li>Removing a damaged or diseased organ</li>
              <li>Removing a tissue sample for further testing (biopsy)</li>
              <li>Evaluating the causes of pain, bleeding, lumps, or diseases in the abdomen</li>
              <li>Removing the gallbladder (laparoscopic cholecystectomy)</li>
              <li>Removing the appendix (laparoscopic appendectomy)</li>
            </ul>
            <strong>Laparoscopic surgery has several advantages over traditional surgery, including: </strong>
            <ul className="blogList">
              <li>More rapid recovery</li>
              <li>Reduced pain</li>
              <li>Much smaller scars</li>
              <li>Often no need to stay in hospital overnight</li>
            </ul>
            <p>Most laparoscopic procedures are major surgery, but gallbladder removal (cholecystectomy) and getting one's tubes tied (tubal ligation) are minor surgeries. </p>
            <h3>4. What is laser surgery?</h3>
            <p>Laser surgery is <strong>a type of surgery that uses light beams instead of instruments like scalpels.</strong> There are several types of lasers, each with different characteristics that perform specific functions during surgery. </p>
            <strong>Laser surgery can be used for: </strong>
            <ul className="blogList">
              <li>Skin: Treating skin diseases or cosmetic concerns such as sunspots or wrinkles</li>
              <li>Refractive error: LASIK, in which a knife is used to cut a flap in the cornea, and a laser is used to reshape the layers underneath</li>
            </ul>
            <strong>Laser surgery can have several benefits, including: </strong>
            <ul className="blogList">
              <li>Higher levels of precision</li>
              <li>Less pain, swelling, and scarring than traditional surgery</li>
            </ul>
            <strong>However, laser surgery can also have side effects, such as:</strong>
            <ul className="blogList">
              <li>Dry eyes</li>
              <li>Temporary visual issues, such as glare</li>
              <li>Discomfort or mild pain after the procedure</li>
            </ul>
            <h3>5. What is general surgery?</h3>
            <p>General surgery is <strong>a surgical specialty that involves a wide range of operative procedures for diagnosing and treating injuries and diseases in any region of the body.</strong> General surgeons are highly skilled medical doctors who train specifically in the surgical treatment of illness and injury.</p>
            <strong>General surgery focuses on:</strong>
            <ul className="blogList">
              <li>Alimentary canal and abdominal contents</li>
              <li>Common abdominal complaints</li>
              <li>Soft tissues</li>
            </ul>
            <strong>Common procedures performed by general surgeons include: </strong>
            <p>Appendectomy, Breast surgery, Esophageal surgery, Gastroenterology procedures, Gallbladder removal, Hernia repair etc. </p>
            <strong>General surgery also includes: </strong>
            <p>Skin, Breasts, Peripheral vasculature, Head and neck, Anatomy, Physiology, Metabolism, Immunology, Nutrition, Pathology, Wound healing, Shock and resuscitation, Intensive care, Neoplasia.</p>
            <h3>6. What is Plastic surgery?</h3>
            <p>Plastic surgery is <strong>a surgical specialty that can improve a person's appearance and reconstruct facial and body tissue defects.</strong> These issues may be caused by illness, injury, or birth disorders. Plastic surgery can also restore and improve function. </p>
            <p>The term "plastic surgery" comes from the Greek word plastikos, which means to shape or mold something. The term was first used in the 1800s to describe the process in which doctors and surgeons reshaped or molded body tissue. </p>
            <p>Some people turn to plastic surgery to correct a physical defect or to alter a part of the body that makes them feel uncomfortable. For example, guys with a condition called gynecomastia (excess breast tissue) that doesn't go away with time or weight loss may opt for reduction surgery.</p>
            <p>Plastic surgery has proven to be exceedingly safe, but no medical procedure is without risks. This is true both during the surgery and the recovery time afterwards. Before scheduling an elective surgery, you should review the qualifications of the physician performing it. </p>
            <h3>7. What is robotic surgery?</h3>
            <p>Robotic surgery, also known as robot-assisted surgery, is <strong>a type of minimally invasive surgery that uses a robotic surgical system to perform operations on patients.</strong> During robotic surgery, a surgeon uses a computer to control a robotic arm that has very small tools attached to it. The surgeon operates through a series of tiny incisions in the patient's body. </p>
            <p>Robotic surgery can be used to treat conditions affecting the heart, digestive system, bladder, prostate, and more. Benefits of robotic surgery include: </p>
            <ul className="blogList">
              <li>Less blood loss</li>
              <li>Shorter hospital stays</li>
              <li>Quicker recovery</li>
            </ul>
            <p>Robotic surgery can be performed solely or it can be performed alongside traditional open surgical procedure.</p>
            <h3>8. What is Panchkarma in Ayurveda?</h3>
            <p>Panchkarma is an ayurvedic therapy process in which a ayurvedic doctor is balancing your body doshas ( VATTA PITTA KAFFA ) with the help of expert panchkarma therapist.</p>
            <p>Its include pancha karma( five karma – five action) 1. Vaman 2. Virechan 3. Nasya 4. Basti 5. Raktmokshan .</p>
            <p>Panchkarma can detox your body by accumulated of your body toxin. It can be use for purifies your body and mind. Panckarma can increase your body stamina and immunity.</p>
            <p>By using of panchkarma treatment Doctor can treat your chronic diseases. Panchkarma can shows a general side-effect.</p>
            <h3>9. What is IVF?</h3>
            <p>In vitro fertilization (IVF) is <strong>a series of procedures that help with conception.</strong> It's a type of assisted reproductive technology (ART). </p>
            <p>During IVF, a human egg is fertilized with sperm in a laboratory. The fertilized egg, called an embryo, is then placed in the uterus. If the embryo successfully implants in the uterus, pregnancy occurs. </p>
            <p>IVF involves a combination of medicines and surgical procedures. A full cycle of IVF takes about 2 to 3 weeks. </p>
            <strong>The five stages of IVF are: </strong>
            <ul className="blogList">
              <li>Medication</li>
              <li>Harvest the eggs</li>
              <li>Fertilization</li>
              <li>Embryo culture</li>
              <li>Embryo transfer</li>
            </ul>
            <p>IVF is the treatment of choice for a wide variety of infertility cases. For example, it can help women with blocked or damaged fallopian tubes have a child using their own eggs. It can also help male partners who have severe sperm abnormalities. </p>
            <p>An IVF pregnancy is generally considered safe after the first trimester, which is approximately 12 weeks. This is because the risk of miscarriage drops significantly after this point.</p>
            <h3>10. What is hair transplant?</h3>
            <p>A hair transplant is <strong>a surgical procedure that treats hair loss.</strong> The procedure involves removing hair-bearing skin from one part of the scalp and grafting it onto bald or thinning areas of the scalp. </p>
            <p>Hair grafting is the most common type of hair transplant surgery. A healthcare provider cuts tiny pieces of the scalp containing healthy hair. These grafts are sometimes called “hair plugs”. </p>
            <p>Hair transplants can address the after-effects of genetic hair loss, but they cannot prevent the progression of the condition. In most cases, a hair transplant procedure lasts a lifetime. </p>
            <p>Hair transplants are generally a safe and effective procedure, with a success rate of around 90–95%. However, there are some risks, including bleeding and infection. </p>
            <p>Most patients see results between six and nine months after surgery. For some patients, it takes 12 months. It's important to know that between two and eight weeks after the surgery, the transplanted hair will fall out. This is normal. </p>
            <h3>11. What is Aesthetics treatment?</h3>
            <p>Aesthetic treatments are non-invasive, painless, and require no downtime. They are also known as cosmetic treatments. </p>
            <p>Aesthetic medicine is <strong>a branch of modern medicine that focuses on altering cosmetic appearance.</strong> It uses minimally invasive medical procedures to enhance patients' physical appearance. </p>
            <strong>Aesthetic treatments can include: </strong>
            <p>Injectable and topical treatments, Scars, Skin laxity, Wrinkles, Moles, Liver spots, Excess fat, Cellulite, Unwanted hair, Skin discoloration, Spider veins, Botox injections, Dermal fillers, Facial rejuvenation. </p>
            <p>The effects of aesthetic treatments can be seen instantly with superficial peels or within a week to 10 days for deeper peels.</p>
            <h3>12. What is Piles surgery?</h3>
            <p>Piles surgery, also known as a haemorrhoidectomy, is <strong>a procedure that removes severe haemorrhoids.</strong> It's usually a day procedure that's carried out under general anesthetic. </p>
            <p>Piles surgery is considered a safe and effective treatment for hemorrhoids. However, like any type of surgery, it has a few risks, including: </p>
            <ul className="blogList">
              <li>Infection</li>
              <li>Bleeding</li>
              <li>Complications related to anesthesia</li>
            </ul>
            <p>Recovery after the surgery can take a few weeks and can be painful. You can expect severe pain for the first two postoperative days, significant pain for the first week, and less severe pain in the second week. </p>
            <p>Doctors often recommend surgery for grade 3 or grade 4 hemorrhoids.</p>
            <h3>13. What is kidney stone surgery?</h3>
            <p>Kidney stone surgery is <strong>a minimally invasive procedure that removes large kidney stones or a large number of small stones.</strong> The procedure is called percutaneous nephrolithotomy. </p>
            <p>During the procedure, a surgeon makes a small incision in the back to create a narrow tunnel directly to the kidney. A thin, flexible tube called an endoscope is guided to the kidney to break up and remove the stone. The procedure is performed while the patient is under general anesthesia.</p>
            <p>The procedure takes about three hours. Larger stones may require additional surgeries to completely remove them. </p>
            <p>After the procedure, you may have pain and nausea when the stone pieces pass. This can happen soon after treatment and may last for 4 to 8 weeks. You may also have some bruising on your back or side where the stone was treated. </p>
            <p>You usually are able to go home on the same day. </p>
            <h3>14 What is gallbladder stone surgery?</h3>
            <ul className="blogList p-0 m-0">
              <li>Gallbladder stone surgery, also called laparoscopic cholecystectomy, is <strong>a common and safe procedure to remove gallstones. </strong></li>
              <li>The surgery is performed using a laparoscope, a long, thin telescope with a camera and light at the end. The surgeon inserts the laparoscope through a larger incision in the abdomen to see inside the body on a monitor. Special surgical instruments are inserted through other incisions to remove the gallbladder and gallstones. </li>
              <li>Doctors often recommend surgery for patients who have had repeated gallstone attacks and pain. Surgery is the best way to prevent future attacks. </li>
              <li>Gallstones can cause sudden pain in the upper right abdomen, called a gallbladder attack or biliary colic. Stones larger than 5-6mm are more likely to cause complications and require removal. Stones smaller than 5mm can often dissolve on their own with medical intervention. </li>
            </ul>
            <h3>15. What is cyst and how to get off this?</h3>
            <p>Commonly A cyst is a small pocket of tissues and filled with pus, fluid and other substances. Cyst me occur by injury infection and other issues. Some time cyst has no any symptoms but when the cyst is grown and it takes a large shape in our body it can cause of many health issues. It has multiple kinds of types. It can be grown in our body’s organs like kidney cyst, ovarian cyst, skin cyst and many more. Only surgery is an option for some types of cyst. </p>
            <h3>16. What is eye surgery?</h3>
            <p>Eye surgery is a way to remove, repair, or manipulate the eye and its surrounding tissues. It can be used to treat a variety of conditions, including:-</p>
            <strong>Cataracts, Glaucoma, Detached retinas, Retinal tears, Diabetic retinopathy, Nearsightedness or farsightedness. </strong>
            <p>Risks of eye surgery include: Infection, Eye damage, Dryness, Vision loss. </p>
            <p>The risks are higher for some people than for others. It should can be perform by an expert and experienced eye surgeon.</p>
            <strong>Types of corrective eye surgery include: </strong>
            <ul className="blogList">
              <li>PRK: photorefractive keratectomy</li>
              <li>LASIK: Laser-assisted in situ keratomileusis</li>
              <li>LASEK: Laser-assisted subepithelial keratomileusis</li>
              <li>SMILE: SMall Incision Lenticule Extraction</li>
              <li>Intracorneal ring</li>
              <li>Implantable corneal lenses</li>
            </ul>
            <p>LASIK is a common type of laser eye surgery to correct vision problems. The procedure takes around 20 minutes to complete, but patients should allow for about 4 hours, as there is preparation required before surgery, and recovery time afterwards. </p>
            <h3>17. What is fistula?</h3>
            <p>A fistula is <strong>an abnormal connection or a link-line between two organs, vessels, or the skin.</strong> Fistulas can develop anywhere, but the most common location is around the anus. </p>
            <strong>Here are some types of fistulas:  </strong>
            <ul className="blogList">
              <li>Rectovaginal or anovaginal fistula: A hole that develops between the rectum or anus and the vagina</li>
              <li>Colovaginal fistula: A fistula that occurs between the colon and vagina</li>
              <li>Enteroenteric fistula: A fistula that forms between two parts of an intestine</li>
              <li>Anal fistula: An abnormal tunnel under the skin that connects the anal canal in the colon to the skin of the buttocks</li>
              <li>Enterocutaneous fistula: A fistula between the small intestine and the skin</li>
            </ul>
            <p>Fistulas can be classified according to their anatomic localization. </p>
            <p>Fistulas can look like holes in the skin. They might ooze drainage, like pus, blood, or poop, especially when you touch the skin around it. </p>
            <p>Fistulas often heal spontaneously. In nonhealing obstetric related anal fistulas, surgical therapy is dependent on the location of the fistula as well as vaginal involvement. </p>
            <h3>18. What is fissure? </h3>
            <p>A fissure is <strong>a cleft or groove that can be normal or abnormal.</strong> For example, a fissure in the cerebral cortex is a normal fissure, but a fissure in the anus is abnormal. </p>
            <p>An anal fissure is a small tear in the thin, moist tissue that lines the anus. Common causes of an anal fissure include: </p>
            <ul className="blogList">
              <li>Constipation</li>
              <li>Straining or passing hard or large stools during a bowel movement</li>
              <li>Persistent diarrhea</li>
            </ul>
            <p>Fissures are usually caused by trauma to the inner lining of the anus from a bowel movement or other stretching of the anal canal. Patients with a tight anal sphincter muscle are more likely to develop anal fissures. </p>
            <p>You can normally treat anal fissures at home with good wound hygiene, and avoid recurrences by making small dietary and lifestyle changes. Most fissures heal spontaneously. </p>
            <h3>19. What is herniya and how to get it off?</h3>
            <p>A hernia is <strong>a sac that forms when the lining of the abdominal cavity pushes through a weak area in the belly wall.</strong> Hernias can occur gradually as you get older, or can result from an injury or surgery. </p>
            <p>Hernias can be repaired with either open or laparoscopic surgery. During open surgery, the surgeon: </p>
            <ul className="blogList">
              <li>Makes an incision close to the site of the hernia</li>
              <li>Pushes the bulging tissue back into the abdomen</li>
              <li>Sews the area shut, sometimes reinforcing it with surgical mesh</li>
              <li>Closes the incision</li>
            </ul>
            <p>A hernia usually does not go away without surgery. Non-surgical approaches such as wearing a corset, binder, or truss may exert gentle pressure on the hernia and keep it in place. </p>
            <p>Hernias are most common in areas like the belly, groin and upper thigh area, and belly button area. </p>
            <h3>20. What is surgical abortion?</h3>
            <p>Abortion is performing when a woman got an unaccepted pregnancy. Abortion perform to get ends a pregnancy. A surgical abortion is medical process is done by a medically certified doctor. Surgical abortion involves a minor opration.</p>
            <h3>21. What is MTP?</h3>
            <p>MTP stands for <strong>Medical Termination of Pregnancy.</strong> It is a procedure that intentionally terminates a pregnancy before full term. MTP is also known as induced abortion or intentional or voluntary termination of pregnancy. </p>
            <p>MTP can be performed using medication or surgery. In the early stages of pregnancy (7-9 weeks), medication can be used to terminate the pregnancy. Otherwise, a surgical procedure is required. </p>
            <p>MTP can be performed if the pregnancy is risky for the life of the mother or the child. The MTP ACT defines the safe period for termination of pregnancy as 20 weeks. </p>
            <p>MTP can be performed through medical abortion, which is a type of non-surgical abortion in which abortifacient pharmaceutical drugs are used to induce abortion.</p>
            <h3>22. What are the Grades of Piles or Hemorrhoids?</h3>
            <strong>There are four grades of internal hemorrhoids/Piles: </strong>
            <ul className="blogList">
              <li>
                Grade 1
                <p>Hemorrhoids/Piles are present but can only be seen with an endoscopy or colonoscopy. They do not extend out of the anus.</p>
              </li>
              <li>
                Grade 2
                <p>Hemorrhoids/Piles extend out of the anus during a bowel movement or with straining, but then return on their own.</p>
              </li>
              <li>
                Grade 3
                <p>Hemorrhoids/Piles can be manually shifted back into place.</p>
              </li>
              <li>
                Grade 4
                <p>Hemorrhoids/Piles are permanently prolapsed and cannot be put back into position.</p>
              </li>
            </ul>
            <p><strong>Internal hemorrhoids are graded based on their appearance and degree of prolapse, known as Goligher's classification.</strong> Patients may experience painless bleeding with any grade. </p>
            <p>There are various treatment options for hemorrhoids, no matter how severe. </p>
            <h3>23. Appendicitis surgery?</h3>
            <p>Appendicitis surgery, also known as an appendectomy, is <strong>a surgical procedure that removes the appendix.</strong> The appendix is located in the lower right side of the abdomen. Appendicitis is an inflamed appendix that can occur when part of the appendix becomes obstructed. </p>
            <p>Appendectomy is usually performed as an emergency procedure to treat complicated acute appendicitis. There are two types of appendectomy: </p>
            <ul className="blogList">
              <li>Open appendectomy: A 2 to 4 inch incision is made in the lower right side of the abdomen to remove the appendix.</li>
              <li>Laparoscopic appendectomy: A less invasive method that doesn't require a large incision.</li>
            </ul>
            <p>Most patients leave the hospital 1 to 2 days after surgery and can return to normal activities within 2 to 4 weeks. </p>
            <p>The incidence of appendicitis has decreased in recent years, but it remains one of the more common surgical emergencies. </p>
            <h3>24. What is lab test?</h3>
            <p>A lab test is <strong>a test that analyzes a sample of blood, urine, or body tissue to check if a person's health is normal.</strong> A technician or doctor analyzes the test samples to see if the results fall within the normal range. </p>
            <strong>Lab tests are commonly used for: </strong>
            <ul className="blogList">
              <li>Discovering the cause of symptoms</li>
              <li>Confirming a diagnosis</li>
              <li>Screening for diseases</li>
            </ul>
            <p>Lab tests use a range because what is normal differs from person to person. Many factors affect test results. </p>
            <p>For example, a blood test can give providers information about your organs, such as the heart, kidneys, and liver. The BMP includes blood glucose, calcium, and electrolyte tests, as well as blood tests that measure kidney function. </p>
            <h3>25. What is ultrasound (USG)?</h3>
            <p>Ultrasound is a imaging test that shows structure of inside your body using high- intensity sound waves. It makes a picture of your body organs tissues and other organs and structure inside your body.</p>
            <h3>26. What is MRI?</h3>
            <p>Magnetic Resonance Imaging (MRI) is <strong>a non-invasive imaging technology that produces detailed three-dimensional anatomical images.</strong> It is often used for disease detection, diagnosis, and treatment monitoring. </p>
            <p>MRI measures the water content (or fluid characteristics) of different tissues, which is processed by the computer to create a black and white image. The image is highly detailed and can show even the smallest abnormality. </p>
            <p>MRI is the investigation of choice in the preoperative staging of rectal and prostate cancer. It also has a role in the diagnosis, staging, and follow-up of other tumors, as well as for determining areas of tissue for sampling in biobanking. </p>
            <p>MRI provides better soft tissue contrast than CT and can differentiate better between fat, water, muscle, and other soft tissue than CT. These images provide information to physicians and can be useful in diagnosing a wide variety of diseases and conditions. </p>
            <h3>27. What is CT-Scan?</h3>
            <p>A CT scan, or computed tomography scan, is <strong>an imaging test that uses X-rays and a computer to produce detailed images of the inside of the body.</strong> CT scans are painless and noninvasive. </p>
            <p>CT scans can help healthcare providers detect diseases and injuries. They can show detailed images of any part of the body, including the bones, muscles, fat, organs, and blood vessels. CT scans can also help doctors find cancer and show things like a tumor's shape and size.</p>
            <p>CT scans are also known as CAT scans, and spiral or helical CT. </p>
            <p>A CT scan usually takes around 10 to 20 minutes. During the scan, you'll need to lie very still and breathe normally. You may be asked to breathe in, breathe out, or hold your breath at certain points. </p>
            <p>Contrast can be given several ways, depending on the type of CT being performed. It may be delivered through a vein (IV) in your hand or forearm. You might drink the contrast before your scan. Rarely, the contrast may be given into your rectum using an enema.</p>
            <h3>28. What is TVS?</h3>
            <p>A transvaginal ultrasound (TVS) is <strong>an imaging procedure that uses sound waves to examine a woman's pelvic cavity and organs.</strong> The probe is placed inside the vagina during the test.</p>
            <strong>A TVS can: </strong>
            <ul className="blogList">
              <li>Look at the uterus, ovaries, tubes, cervix, and pelvic area</li>
              <li>Show abnormal structures or growths in the pelvic area</li>
              <li>Provide more detailed images in early pregnancy compared to an abdominal approach</li>
              <li>Offer excellent resolution of ovarian morphology</li>
            </ul>
            <p>A TVS can be performed during menstruation. If you are menstruating and wearing a tampon, you will need to remove the tampon before the test. You should also drink 32 ounces of any liquid one hour before your examination time. </p>
            <p>A TVS is the preferred best practice for early pregnancy scanning. At approximately 5 weeks, the gestational sac can be seen via transvaginal ultrasound.</p>
            <h3>29. What is X-ray?</h3>
            <p>X-rays is type of diagnosis process in which a radiation is perform to struct your body tissues and inside organs, bone etc.</p>
            <h3>30. What is ECG?</h3>
            <p>An electrocardiogram (ECG or EKG) is <strong>a simple test that measures and records the heart's electrical activity.</strong> It's a graphical representation of the cardiac cycle produced by an electrograph. </p>
            <p>An ECG uses sensors attached to the skin to detect the electrical signals produced by the heart each time it beats. The electrical impulse (or wave) travels through the heart with each heartbeat. The ECG helps measure these impulses. </p>
            <h3>31. What is dialysis? </h3>
            <p>Dialysis is <strong>an artificial process that removes waste and excess fluid from the blood when the kidneys stop working properly.</strong> It's a treatment that replicates the kidney's function and cleans the waste from blood for individuals with kidney disease or kidney failure. </p>
            <p>Dialysis often involves diverting blood to a machine to be cleaned. It's an artificial process of filtering blood, which is done to remove the nitrogenous waste products that can result in toxicity inside the system. </p>
            <strong>There are three main types of dialysis: </strong>
            <ul className="blogList">
              <li>In-center hemodialysis</li>
              <li>Home hemodialysis</li>
              <li>Peritoneal dialysis</li>
            </ul>
            <h3>32. What is Knee replacement?</h3>
            <p>Knee replacement, also known as knee arthroplasty or total knee replacement, is <strong>a surgical procedure that resurfaces a knee damaged by arthritis.</strong> The procedure involves replacing parts of the knee joint with artificial (prosthetic) parts. </p>
            <p>During the procedure, an orthopedic surgeon removes damaged cartilage and bone, and then positions new metal and plastic implants to restore the alignment and function of the knee. The metal parts of the implant are made of titanium- or cobalt-chromium-based alloys, and the plastic parts are made of medical grade polyethylene. </p>
            <p>Knee replacement surgery can relieve pain and restore function to a knee joint that's severely damaged, usually by arthritis. Most people who undergo a knee replacement have either osteoarthritis, rheumatoid arthritis, or postinjury arthritis.</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Blog
