import { api, fileUpload } from '../utils/request'

export const getAllState = async () => {
  const res = await api({ endpoint: `/state`, method: 'GET' })
  return res
}

export const getAllCity = async () => {
  const res = await api({ endpoint: `/city`, method: 'GET' })
  return res
}

export const getAllDistrict = async () => {
  const res = await api({ endpoint: `/district`, method: 'GET' })
  return res
}

export const getDistrictByState = async (name) => {
  const res = await api({
    endpoint: `/district`,
    method: 'GET',
    query: { stateName: name },
  })
  return res
}

export const getCityByDistrict = async (name) => {
  const res = await api({
    endpoint: `/city`,
    method: 'GET',
    query: { districtName: name },
  })
  return res
}

export const getStateById = async (id) => {
  const res = await api({ endpoint: `/state/${id}`, method: 'GET' })
  return res
}

export const getDistrictByStateId = async (query) => {
  const res = await api({ endpoint: `/district`, method: 'GET', query })
  return res
}

export const getDistrictById = async (id) => {
  const res = await api({ endpoint: `/district/${id}`, method: 'GET' })
  return res
}

export const getCityById = async (id) => {
  const res = await api({ endpoint: `/city/${id}`, method: 'GET' })
  return res
}

export const getCityByDistrictId = async (id) => {
  const res = await api({
    endpoint: `/city`,
    method: 'GET',
    query: { districtId: id },
  })
  return res
}
