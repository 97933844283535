import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Back from '../common/Back'
import img from '../images/bannerimg.jpg'
import './bookingHistory.css'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add'
import VaccinesIcon from '@mui/icons-material/Vaccines'
import { getMyBookings } from '../../api/myBookings'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee'
import { Link } from 'react-router-dom'
// -----------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: '#fff',
  boxShadow: 24,
  p: 4,
}

const BookingHistory = () => {
  const [open, setOpen] = React.useState(false)
  const [booking, setBooking] = useState([])
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  useEffect(() => {
    localStorage.removeItem('surgeryPrice')
  }, [])
  const [bookings, setBookings] = useState([])
  useEffect(() => {
    ; (async () => {
      const { data } = await getMyBookings()
      setBookings(data)
    })()
  }, [])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '100%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
  }
  const [openClinic, setOpenClinic] = useState(false)
  const handleOpenClinic = () => setOpenClinic(true)
  const handleCloseClinic = () => setOpenClinic(false)
  const [openAyurvedic, setOpenAyurvedic] = useState(false)
  const handleOpenAyurvedic = () => setOpenAyurvedic(true)
  const handleCloseAyurvedic = () => setOpenAyurvedic(false)
  const [openLab, setOpenLab] = useState(false)
  const handleOpenLab = () => {
    console.log('asd')
    setOpenLab(true)
  }
  const handleCloseLab = () => setOpenLab(false)
  const [openPackage, setOpenPackage] = useState(false)
  const handleOpenPackage = () => setOpenPackage(true)
  const handleClosePackage = () => setOpenPackage(false)
  const [openSurgery, setOpenSurgery] = useState(false)
  const handleOpenSurgery = () => setOpenSurgery(true)
  const handleCloseSurgery = () => setOpenSurgery(false)
  console.log(booking)
  return (
    <>
      <section className="bookingHistory">
        <Back name="Booking history" title="Booking history list" cover={img} />
        <div className="container py-5">
          <h3 className="pageTitle">
            Booking history <span>list</span>
          </h3>

          <Stack sx={{ py: 5 }}>
            {bookings?.map((booking) => {
              return (
                <div className="row pb-3">
                  <div className="col-md-8">
                    <div className="bookingHistoryList">
                      <div className="row align-items-center">
                        <div className="col-md-3">
                          <div className="bookingHistoryImg">
                            <img src="/images/knee_1.png" alt="hospital" />
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="bookingList">
                            <h3>{booking?.name}</h3>
                            <div className="generalLabList">
                              <div className="icon_sec">
                                <AddIcon />
                              </div>
                              <p>Vendor Name: {booking?.vendorName}</p>
                            </div>
                            <div className="generalLabList">
                              <div className="icon_sec">
                                <CurrencyRupeeIcon />
                              </div>
                              <p>
                                Amount:{' '}
                                {booking?.price ||
                                  booking?.labPrices
                                    ?.map((item, index) => {
                                      return item.price
                                    })
                                    .reduce((a, b) => a + b, 0)}{' '}
                              </p>
                            </div>
                            <div className="generalLabList">
                              <div className="icon_sec">
                                <VaccinesIcon />
                              </div>
                              {booking?.status === 'pending' ? (
                                <p
                                  className="text-danger"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  in-Progress
                                </p>
                              ) : booking?.status === 'booked' ? (
                                <p
                                  className="text-success"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {booking?.status}
                                </p>
                              ) : booking?.status === 'cancelled' ? (
                                <p
                                  className="text-danger"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {booking?.status}
                                </p>
                              ) : booking?.status === 'completed' ? (
                                <p
                                  className="text-success"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {booking?.status}
                                </p>
                              ) : booking?.status === 'rejected' ? (
                                <p
                                  className="text-danger"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {booking?.status}
                                </p>
                              ) : booking?.status === 'no response' ? (
                                <p
                                  className="text-danger"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  Not Responded
                                </p>
                              ) : null}
                              {booking?.surgeryStatus === 'pending' ? (
                                <p
                                  className="text-danger"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  in-Progress
                                </p>
                              ) : booking?.surgeryStatus === 'in progress' ? (
                                <p
                                  className="text-danger"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  Confirmed
                                </p>
                              ) : booking?.surgeryStatus === 'booked' ? (
                                <p
                                  className="text-success"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {booking?.surgeryStatus}
                                </p>
                              ) : booking?.surgeryStatus === 'cancelled' ? (
                                <p
                                  className="text-danger"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {booking?.surgeryStatus}
                                </p>
                              ) : booking?.surgeryStatus === 'completed' ? (
                                <p
                                  className="text-success"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {booking?.surgeryStatus}
                                </p>
                              ) : booking?.surgeryStatus === 'rejected' ? (
                                <p
                                  className="text-danger"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {booking?.surgeryStatus}
                                </p>
                              ) : booking?.surgeryStatus === 'no response' ? (
                                <p
                                  className="text-danger"
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  Not Responded
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="text-end">
                            <Button
                              className="bookNowBtn py-1"
                              aria-label="details"
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                // at 0 element setBooking
                                setBooking(booking)
                                if (booking?.bookingType === 'Clinic') {
                                  handleOpenClinic()
                                } else if (
                                  booking?.bookingType === 'Ayurvedic'
                                ) {
                                  handleOpenAyurvedic()
                                } else if (booking?.bookingType === 'Lab') {
                                  handleOpenLab()
                                } else if (booking?.bookingType === 'Package') {
                                  handleOpenPackage()
                                } else if (booking?.bookingType === 'Surgery') {
                                  handleOpenSurgery()
                                }
                              }}
                              // onClick={handleOpen}
                              startIcon={<FileOpenIcon />}
                            >
                              View Details
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

            {/* 
              Clinic Treatment Details
              */}
            <Modal
              className="modalBooking"
              open={openClinic}
              onClose={handleCloseClinic}
              aria-labelledby="modal-modal-Clinic"
              aria-describedby="modal-modal-descriptionClinic"
            >
              <Box sx={style} className="modalBookingBox">
                <Typography
                  className="mainTitle"
                  variant="h3"
                  gutterBottom
                  component="div"
                >
                  Clinic Treatment Details
                </Typography>
                <Paper sx={{ p: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Patient Details
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Age :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.age}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Gender :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.gender}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Mobile :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.mobile}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Address :
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.address?.houseNo},{' '}
                          {booking?.address?.streetNo},{' '}
                          {booking?.address?.locality},{' '}
                          {booking?.address?.landmark}, {booking?.address?.city}
                          , {booking?.address?.district},{' '}
                          {booking?.address?.state}, {booking?.address?.pinCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper sx={{ p: 2, my: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Vendor Details
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.vendorName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {booking?.status === 'completed' || booking?.status === 'booked' ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={3}>
                          <Typography
                            className="titleThree"
                            variant="h6"
                            gutterBottom
                            component="div"
                          >
                            Address :
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                          <Typography
                            className="titleThree"
                            variant="h6"
                            gutterBottom
                            component="div"
                            sx={{
                              textTransform: 'capitalize',
                            }}
                          >
                            {booking?.vendorAddress?.houseNo},
                            {booking?.vendorAddress?.streetNo},
                            {booking?.vendorAddress?.landmark},
                            {booking?.vendorAddress?.locality},
                            {booking?.vendorAddress?.city},
                            {booking?.vendorAddress?.district},
                            {booking?.vendorAddress?.state},
                            {booking?.vendorAddress?.pinCode},
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                </Paper>
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Booking Details
                  </Typography>
                  <Box>
                    {/* <Grid container spacing={2}>
                                      <Grid item xs={2} sm={3}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                        >
                                          Treatment Name :
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={8}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                          sx={{
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {booking?.treatment}
                                        </Typography>
                                      </Grid>
                                    </Grid> */}
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Consultation Date Time :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {new Date(
                            booking?.consultationTime?.replace('Z', '+05:30'),
                          ).toLocaleDateString()}{' '}
                          {new Date(
                            booking?.consultationTime?.replace('Z', '+05:30'),
                          ).toLocaleTimeString()}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Booking Status :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.status === 'pending'
                            ? 'In-Progress'
                            : booking?.status}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Price :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.price}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={2}>
                                      <Grid item xs={2} sm={3}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                        >
                                          Coupon Code :
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={8}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                          sx={{
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {booking?.couponCode}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={2} sm={3}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                        >
                                          Discounted Price :
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={8}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                          sx={{
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {booking?.discountPrice}
                                        </Typography>
                                      </Grid>
                                    </Grid> */}
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Payment Status :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.paymentStatus}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Box>
            </Modal>
            {/* 
              Surgery Booking Details */}

            <Modal
              className="modalBooking"
              open={openSurgery}
              onClose={handleCloseSurgery}
              aria-labelledby="modal-modal-Surgery"
              aria-describedby="modal-modal-descriptionSurgery"
            >
              <Box sx={style} className="modalBookingBox">
                <Typography
                  className="mainTitle"
                  variant="h3"
                  gutterBottom
                  component="div"
                >
                  Surgery Details
                </Typography>
                <Paper sx={{ p: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Patient Details
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Age :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.age}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Gender :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.gender}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Mobile :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.mobile}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Address :
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.address?.houseNo},{' '}
                          {booking?.address?.streetNo},{' '}
                          {booking?.address?.locality},{' '}
                          {booking?.address?.landmark}, {booking?.address?.city}
                          , {booking?.address?.district},{' '}
                          {booking?.address?.state}, {booking?.address?.pinCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper sx={{ p: 2, my: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Vendor Details
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.vendorName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {booking?.surgeryStatus === 'completed' || booking?.surgeryStatus === 'booked' || booking?.surgeryStatus === 'in progress' ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={3}>
                          <Typography
                            className="titleThree"
                            variant="h6"
                            gutterBottom
                            component="div"
                          >
                            Address :
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                          <Typography
                            className="titleThree"
                            variant="h6"
                            gutterBottom
                            component="div"
                            sx={{
                              textTransform: 'capitalize',
                            }}
                          >
                            {booking?.vendorAddress?.houseNo},
                            {booking?.vendorAddress?.streetNo},
                            {booking?.vendorAddress?.landmark},
                            {booking?.vendorAddress?.locality},
                            {booking?.vendorAddress?.city},
                            {booking?.vendorAddress?.district},
                            {booking?.vendorAddress?.state},
                            {booking?.vendorAddress?.pinCode},
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                </Paper>
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Booking Details
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Surgery Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.surgery?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Surgery Date :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {new Date(booking?.surgeryDate).toLocaleDateString()}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Surgery Status :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.surgeryStatus === 'pending'
                            ? 'In-Progress'
                            : booking?.surgeryStatus}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Price :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.price}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={2}>
                                      <Grid item xs={2} sm={3}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                        >
                                          Coupon Code :
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={8}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                          sx={{
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {booking?.couponCode}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={2} sm={3}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                        >
                                          Discounted Price :
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={8}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                          sx={{
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {booking?.discountPrice}
                                        </Typography>
                                      </Grid>
                                    </Grid> */}
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Payment Status :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.paymentStatus}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper sx={{ p: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Other Details
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          weight :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.weight}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Height :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.height}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Skin Color :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.skinColor}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Waist Size :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.waistSize}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Morning Motion :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.morningMotion}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Work Type :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.workType}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Sleeping Time :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.sleepingTime}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Chronic Disease :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.isChronicDisease ? 'Yes' : 'No'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Diabetic :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.isDiabetic ? 'Yes' : 'No'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Digestion Problem :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.isDigestionProblem ? 'Yes' : 'No'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Exercise :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.isExercise ? 'Yes' : 'No'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Medicine History :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        {booking?.medicineHistory?.map((item, index) => {
                          return (
                            <Typography
                              key={index}
                              className="titleThree"
                              variant="h6"
                              gutterBottom
                              component="div"
                              sx={{
                                textTransform: 'capitalize',
                              }}
                            >
                              {item.medicineName}, {item.duration} Months
                            </Typography>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Box>
            </Modal>
            {/* 
              Ayurveda Booking Details
               */}
            <Modal
              className="modalBooking"
              open={openAyurvedic}
              onClose={handleCloseAyurvedic}
              aria-labelledby="modal-modal-Ayurvedic"
              aria-describedby="modal-modal-descriptionAyurvedic"
            >
              <Box sx={style} className="modalBookingBox">
                <Typography
                  className="mainTitle"
                  variant="h3"
                  gutterBottom
                  component="div"
                >
                  Ayurvedic Treatment Details
                </Typography>
                <Paper sx={{ p: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Patient Details
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Age :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.age}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Gender :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.gender}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Mobile :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.mobile}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Address :
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.address?.houseNo},{' '}
                          {booking?.address?.streetNo},{' '}
                          {booking?.address?.locality},{' '}
                          {booking?.address?.landmark}, {booking?.address?.city}
                          , {booking?.address?.district},{' '}
                          {booking?.address?.state}, {booking?.address?.pinCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper sx={{ p: 2, my: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Vendor Details
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.vendorName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {booking?.status === 'completed' || booking?.status === 'booked' ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={3}>
                          <Typography
                            className="titleThree"
                            variant="h6"
                            gutterBottom
                            component="div"
                          >
                            Address :
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                          <Typography
                            className="titleThree"
                            variant="h6"
                            gutterBottom
                            component="div"
                            sx={{
                              textTransform: 'capitalize',
                            }}
                          >
                            {booking?.vendorAddress?.houseNo},
                            {booking?.vendorAddress?.streetNo},
                            {booking?.vendorAddress?.landmark},
                            {booking?.vendorAddress?.locality},
                            {booking?.vendorAddress?.city},
                            {booking?.vendorAddress?.district},
                            {booking?.vendorAddress?.state},
                            {booking?.vendorAddress?.pinCode},
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                </Paper>
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Booking Details
                  </Typography>
                  <Box>
                    {/* <Grid container spacing={2}>
                                      <Grid item xs={2} sm={3}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                        >
                                          Treatment Name :
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={8}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                          sx={{
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {booking?.treatment}
                                        </Typography>
                                      </Grid>
                                    </Grid> */}
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Booking Date Time :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {new Date(
                            booking?.consultationTime?.replace('Z', '+05:30'),
                          ).toLocaleDateString()}{' '}
                          {new Date(
                            booking?.consultationTime?.replace('Z', '+05:30'),
                          ).toLocaleTimeString()}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Booking Status :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.status === 'pending'
                            ? 'In-Progress'
                            : booking?.status}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Price :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.price}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={2}>
                                      <Grid item xs={2} sm={3}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                        >
                                          Coupon Code :
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={8}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                          sx={{
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {booking?.couponCode}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={2} sm={3}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                        >
                                          Discounted Price :
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6} sm={8}>
                                        <Typography
                                          className="titleThree"
                                          variant="h6"
                                          gutterBottom
                                          component="div"
                                          sx={{
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          {booking?.discountPrice}
                                        </Typography>
                                      </Grid>
                                    </Grid> */}
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Payment Status :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.paymentStatus}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Box>
            </Modal>
            {/*
          Lab Booking Details 
           */}
            <Modal
              className="modalBooking"
              open={openLab}
              onClose={handleCloseLab}
              aria-labelledby="modal-modal-Lab"
              aria-describedby="modal-modal-descriptionLab"
            >
              <Box sx={style} className="modalBookingBox">
                <Typography
                  className="mainTitle"
                  variant="h3"
                  gutterBottom
                  component="div"
                >
                  Lab Booking Details
                </Typography>
                <Paper sx={{ p: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Patient Details
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Age :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.age}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Gender :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.gender}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Mobile :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.mobile}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Address :
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.address?.houseNo},{' '}
                          {booking?.address?.streetNo},{' '}
                          {booking?.address?.locality},{' '}
                          {booking?.address?.landmark}, {booking?.address?.city}
                          , {booking?.address?.district},{' '}
                          {booking?.address?.state}, {booking?.address?.pinCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper sx={{ p: 2, my: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Vendor Details
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={3} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.vendorName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {booking?.status === 'completed' || booking?.status === 'booked' ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={3}>
                          <Typography
                            className="titleThree"
                            variant="h6"
                            gutterBottom
                            component="div"
                          >
                            Address :
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                          <Typography
                            className="titleThree"
                            variant="h6"
                            gutterBottom
                            component="div"
                            sx={{
                              textTransform: 'capitalize',
                            }}
                          >
                            {booking?.vendorAddress?.houseNo},
                            {booking?.vendorAddress?.streetNo},
                            {booking?.vendorAddress?.landmark},
                            {booking?.vendorAddress?.locality},
                            {booking?.vendorAddress?.city},
                            {booking?.vendorAddress?.district},
                            {booking?.vendorAddress?.state},
                            {booking?.vendorAddress?.pinCode},
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                </Paper>
                <Paper sx={{ p: 2, mb: 2 }}>
                  <Typography
                    className="titleTwo"
                    variant="h5"
                    gutterBottom
                    component="div"
                  >
                    Booking Details
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Test Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.pathLabTest?.map((item, index) => {
                            return (
                              <Typography
                                key={index}
                                className="titleThree"
                                variant="h6"
                                gutterBottom
                                component="div"
                                sx={{
                                  textTransform: 'capitalize',
                                }}
                              >
                                {index + 1} . {item.name}
                              </Typography>
                            )
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Test Date Time :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {new Date(
                            booking?.sampleCollectionScheduleTime?.replace(
                              'Z',
                              '+05:30',
                            ),
                          ).toLocaleDateString()}{' '}
                          {new Date(
                            booking?.sampleCollectionScheduleTime?.replace(
                              'Z',
                              '+05:30',
                            ),
                          ).toLocaleTimeString()}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Test Status :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {/* {booking?.status} */}
                          {booking?.status === 'pending'
                            ? 'In-Progress'
                            : booking?.status}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Price :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.labPrices
                            ?.map((item, index) => {
                              return item.price
                            })
                            .reduce((a, b) => a + b, 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Coupon Code :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.couponCode}
                        </Typography>
                      </Grid>
                    </Grid> */}
                    {/* <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Discounted Price :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.discountPrice}
                        </Typography>
                      </Grid>
                    </Grid> */}
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={3}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Payment Status :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          className="titleThree"
                          variant="h6"
                          gutterBottom
                          component="div"
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {booking?.paymentStatus}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Box>
            </Modal>
            {/*
              Package Booking Modal 

               */}
            <Modal
              open={openPackage}
              onClose={handleClosePackage}
              aria-labelledby="modal-modal-Package"
              aria-describedby="modal-modal-descriptionPackage"
            >
              <Box sx={style}>
                <Typography variant="h5" gutterBottom component="div">
                  Package Booking Details
                </Typography>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={3}>
                      <Typography variant="h6" gutterBottom component="div">
                        Package Name :
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {booking.packageName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={3}>
                      <Typography variant="h6" gutterBottom component="div">
                        Test Date Time :
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {new Date(
                          booking.slotFrom?.replace('Z', '+05:30'),
                        ).toLocaleDateString()}{' '}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={3}>
                      <Typography variant="h6" gutterBottom component="div">
                        Test Status :
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {/* {booking.status} */}
                        {booking?.status === 'pending'
                          ? 'In-Progress'
                          : booking?.status}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={3}>
                      <Typography variant="h6" gutterBottom component="div">
                        Price :
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {booking?.price}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={3}>
                      <Typography variant="h6" gutterBottom component="div">
                        Coupon Code :
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {booking?.couponCode}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={3}>
                      <Typography variant="h6" gutterBottom component="div">
                        Discounted Price :
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {booking?.price}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={3}>
                      <Typography variant="h6" gutterBottom component="div">
                        Payment Status :
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {booking.isPaid ? 'Paid' : 'Not Paid'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Modal>
          </Stack>
        </div>
      </section>
    </>
  )
}
export default BookingHistory
