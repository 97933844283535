import { api } from '../utils/request';

export const createClinicBooking = async (data) => {
  const res = await api({ endpoint: `/clinicConsultancyBooking`, method: 'POST', data });
  return res;
};

export const createAyurvedaBooking = async (data) => {
  const res = await api({ endpoint: `/ayurvedicConsultancyBooking`, method: 'POST', data });
  return res;
};

export const createLabBooking = async (data) => {
  const res = await api({ endpoint: `/labBooking`, method: 'POST', data });
  return res;
};

export const createSurgeryBooking = async (data) => {
  const res = await api({ endpoint: `/surgeryBooking`, method: 'POST', data });
  return res;
};

export const createPackageBooking = async (data) => {
  const res = await api({ endpoint: `/packageBooking`, method: 'POST', data });
  return res;
};