import React from 'react'
import './footer.css'
import 'bootstrap/dist/css/bootstrap.css'
import { Link } from 'react-router-dom'
const Footer = () => {
  const setScroll = () => {
    window.scrollTo(0, localStorage.getItem('position'))
  }

  return (
    <>
      <footer>
        <div className="container">
          <a href="tel:+917011527883" className="callB">
            <i class="fa-solid fa-phone"></i>
          </a>
          <a href="https://wa.me/7011527883" target="_blank" className="whats">
            <i class="fa-brands fa-whatsapp"></i>
          </a>
          <div className="row pt-4">
            <div className="col-md-4">
              <div className="logoFooter">
                <Link
                  onClick={() => {
                    setScroll()
                  }}
                  to=""
                  className="m-0"
                >
                  <img src="../images/whitelogo.png" alt="" className="w-75" />
                </Link>
                <p className="pt-3">
                  clickyourhospital.com is a india's best healthcare service provider that having the mission to make simple and more efficient the surgery journey of every patient by offering support at every step.
                </p>
                <p>
                  Social Links:
                  <a href="https://www.facebook.com/profile.php?id=61550272673604&mibextid=9R9pXO" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="https://twitter.com/clickyour2023" target="_blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="https://www.instagram.com/p/Cz5wQU1SOwy/?igshid=YTI4YzE2YTYwMA==" target="_blank">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/vaj-healthtech-private-limited-4ab02429b/" target="_blank">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              {/* <h3>Support -</h3> */}
              <ul>
                <li>
                  <Link
                    to="/howToWork"
                    onClick={() => {
                      setScroll()
                    }}
                  >
                    Careers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faq"
                    onClick={() => {
                      setScroll()
                    }}
                  >
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog"
                    onClick={() => {
                      setScroll()
                    }}
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setScroll()
                    }}
                    to="/checkOffers"
                  >
                    Check Offers
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              {/* <h3>About -</h3> */}
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      setScroll()
                    }}
                    to="/about"
                  >
                    About Us
                  </Link>
                </li>
                {/* <li>
                  <Link
                    onClick={() => {
                      setScroll()
                    }}
                    to="/joinUs"
                  >
                    Join us as Business
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="/privacyPolicy"
                    onClick={() => {
                      setScroll()
                    }}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/termsConditions"
                    onClick={() => {
                      setScroll()
                    }}
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/refundPolicy"
                    onClick={() => {
                      setScroll()
                    }}
                  >
                    Refund Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              {/* <h3>Contact Us - </h3> */}
              <div className="row addr">
                <div className="col-md-5">
                  <p>Address :</p>
                </div>
                <div className="col-md-7">
                  <p>Varanasi</p>
                </div>
              </div>
              <div className="row addr">
                <div className="col-md-5">
                  <p>Contact No. :</p>
                </div>
                <div className="col-md-7">
                  <p>
                    <a href="tel:+918130972217">+91 813-097-2217</a>
                  </p>
                </div>
              </div>
              <div className="row addr">
                <div className="col-md-5">
                  <p>Email Address :</p>
                </div>
                <div className="col-md-7">
                  <p>
                    <a href="mailto:support@clickyourhospital.com">
                      support@clickyourhospital.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <p>All Content is Copyright and All Rights Reserved at Vaj Healthtech Pvt. Ltd.</p>
            <div className="linkFooter">
              <p>
                <img src='../images/codewireImg.ico' />
                {/* <a href="https://codewiretech.com/" target="_blank">
                  <img src='../images/codewireImg.ico' />
                </a> */}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
