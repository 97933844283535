import React from "react"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import "./stepSec.css"
// --------------------------------------------------------
const StepSec = () => {

  return (
    <>
      {/* <section className="py-5 stepSec border-top">
        <div className="steps_to_foreign_currency">
          <div className="container">
            <div className="mainTitle">
              <span>
                <img src={"../../../../images/line.png"} alt='' />
                Fastest Solution
              </span>
              <h2>4 easy steps to get your solution</h2>
            </div>
            <div className="how-it-works">
              <div className="process row text-center">
                <div className="col content-col">
                  <div className="circle-icon-container">
                    <div className="wrapper">
                      <img src={"../../../../images/search_doctor.svg"} alt='' />
                    </div>
                  </div>
                  <p className="hhhhh">1. Search Doctor</p>
                  <p className="ppppp">Select currency and denomination you need to carry along</p>
                </div>
                <div className="col content-col">
                  <div className="circle-icon-container">
                    <div className="wrapper">
                      <img src={"../../../../images/doctor_profile.svg"} alt='' />
                    </div>
                  </div>
                  <p className="hhhhh">2. Check Doctor Profile</p>
                  <p className="ppppp">Option to choose the doorstep delivery or branch pick up</p>
                </div>
                <div className="col content-col">
                  <div className="circle-icon-container">
                    <div className="wrapper">
                      <img src={"../../../../images/schedule_appointment.svg"} alt='' />
                    </div>
                  </div>
                  <p className="hhhhh">3. Schedule Appointment</p>
                  <p className="ppppp">Make payment via multiple payment options like debit card, credit card &amp; Netbanking</p>
                </div>
                <div className="col content-col">
                  <div className="circle-icon-container">
                    <div className="wrapper">
                      <img src={"../../../../images/get_solution.svg"} alt='' />
                    </div>
                  </div>
                  <p className="hhhhh">4. Get Your Solution</p>
                  <p className="ppppp">Receipt of Oriel Forex order completion at blocked rate</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div class="stepSec">
        <div class="container big-cont">
          <div className="mainTitle">
            <span>
              <img src={"../../../../images/line.png"} alt='' />
              Fastest Solution
            </span>
            <h2>4 easy steps to get your solution</h2>
          </div>
          <div class="grafh">
            <div class="svg-box">
              <svg height="200" width="200">
                <circle class="circle" cx="100" cy="100" r="95" stroke="#00f6ff" stroke-width="2" fill-opacity="0"></circle>
              </svg>
              <div class="circle-box firstBB circleB"></div>
              <div class="circle-logo">
                <img src={"../../../../images/search_doctor.svg"} alt='' />
                <p>1. Set Your <br></br>Location</p>
              </div>
            </div>
            <div class="svg-box bg-icon">
              <svg height="200" width="200">
                <circle class="circle" cx="100" cy="100" r="95" stroke="#00214a" stroke-width="2" fill-opacity="0"></circle>
              </svg>
              <div class="circle-box dark-red circleB"></div>
              <div class="circle-logo">
                <img src={"../../../../images/doctor_profile.svg"} alt='' />
                <p>2. Select Your <br></br>Surgery</p>
              </div>
            </div>
            <div class="svg-box bg-icon1">
              <svg height="200" width="200">
                <circle class="circle" cx="100" cy="100" r="95" stroke="#FF9710" stroke-width="2" fill-opacity="0"></circle>
              </svg>
              <div class="circle-box yellow circleB"></div>
              <div class="circle-logo">
                <img src={"../../../../images/schedule_appointment.svg"} alt='' />
                <p>3. Compare Price<br></br> Facilities</p>
              </div>
            </div>
            <div class="svg-box bg-icon2">
              <svg height="200" width="200">
                <circle class="circle" cx="100" cy="100" r="95" stroke="#009EE0" stroke-width="2" fill-opacity="0"></circle>
              </svg>
              <div class="circle-box blue circleB"></div>
              <div class="circle-logo">
                <img src={"../../../../images/get_solution.svg"} alt='' />
                <p>4. Book & Get Your <br></br>Surgery Done</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



export default StepSec
