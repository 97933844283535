import React, { useEffect, useState } from 'react'
import Back from '../common/Back'
import img from '../images/bannerimg.jpg'
import './labsListing.css'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import DoneIcon from '@mui/icons-material/Done'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import DescriptionIcon from '@mui/icons-material/Description'
import { getAllBanner } from '../../api/banner'
import { getAllVendorByLabTest } from '../../api/vendorByLabTest'

import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import { styled, useTheme } from '@mui/material/styles'
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Slider,
  Pagination,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
} from '@mui/material'
import { getPincode } from '../../api/getPincode'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { message } from '../../utils/message'
import VerifiedIcon from '@mui/icons-material/Verified'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}))

const limit = 5

const drawerWidth = 400

// reduce width of drawer
const Drawer = styled(
  MuiDrawer,
  {},
)(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    // position: "relative",
    // whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
      }),
      width: 0,

      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}))

const LabsListing = () => {
  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setOpen(false)
  }
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    localStorage.removeItem('surgeryPrice')
  }, [])
  const searchParams = useHistory()
  const [vendorByLabTest, setAllVendorByLabTest] = useState([])
  const [selectLocation, setSelectLocation] = useState(
    JSON.parse(localStorage.getItem('selectLocation')),
  )

  const [pinCode, setPinCode] = useState([])
  const [cityList, setCityList] = useState([])
  const [isSort, setIsSort] = useState(false)
  const [paginationData, setPaginationData] = useState({
    total: 0,
    pageCount: 0,
    currentPage: 0,
  })

  const [url, setUrl] = useState()

  useEffect(() => {
    ;(async () => {
      const { data } = await getAllBanner()
      const tempBanner = data.docs.find((banner) => {
        return banner.page === 'pathLabTestPackagesList'
      })
      setUrl(tempBanner.url)
      console.log(tempBanner)
    })()
  }, [])

  useEffect(() => {
    window.onLocationChange = () => {
      console.log('location changed')
      setSelectLocation(JSON.parse(localStorage.getItem('selectLocation')))
    }
    return () => {
      window.onLocationChange = () => {}
    }
  }, [])
  const [isloading, setIsLoading] = useState(true)
  useEffect(() => {
    ;(async () => {
      console.log(searchParams.location.search.split('labtest=')[1])
      const { data } = await getAllVendorByLabTest({
        limit,
        skip: 0,
        pathLabTestIds: searchParams.location.search
          .split('labtest=')[1]
          .split(','),

        state: selectLocation.state,
        district: selectLocation.districtName,
      })
      setAllVendorByLabTest(data.docs)
      setPaginationData({
        total: data.totalDocs,
        pageCount: data.totalPages,
        currentPage: data.page,
      })
      setIsLoading(false)
    })()
  }, [selectLocation.state, selectLocation.districtName])

  const getYear = () => {
    const currentYear = new Date().getFullYear()
    return currentYear
  }

  const [surgeryFilters, setSurgeryFilters] = useState({
    city: [],
    pinCode: [],
  })

  useEffect(() => {
    ;(async () => {
      const { data } = await getPincode({
        districtName: selectLocation.districtName,
        stateName: selectLocation.state,
      })

      const uniquePincode = []
      data.forEach((item) => {
        const isExist = uniquePincode.find((value) => {
          return value.pinCode === item.pinCode
        })
        if (!isExist) {
          uniquePincode.push(item)
        }
      })
      setPinCode(uniquePincode)
      //remove duplicate city
      const city = data.map((item) => item.cityName)
      const uniqueCity = [...new Set(city)]
      setCityList(uniqueCity)
    })()
  }, [selectLocation.state, selectLocation.districtName])
  const shortByPrice = () => {
    if (isSort) {
      const sorted = [...vendorByLabTest]
      sorted.sort((a, b) => {
        return b.pathLabData?.price?.price - a.pathLabData?.price?.price
      })
      setAllVendorByLabTest(sorted)
      setIsSort(false)
    } else {
      const sorted = [...vendorByLabTest]
      sorted.sort((a, b) => {
        return a.pathLabData?.price?.price - b.pathLabData?.price?.price
      })
      setAllVendorByLabTest(sorted)
      setIsSort(true)
    }
  }

  const handleCityChange = (event) => {
    if (event.target.checked) {
      setSurgeryFilters({
        ...surgeryFilters,
        city: [...new Set([...surgeryFilters.city, event.target.value])],
      })
    } else {
      setSurgeryFilters({
        ...surgeryFilters,
        city: surgeryFilters.city.filter((city) => {
          return city != event.target.value
        }),
      })
    }
  }

  const handlePinCodeChange = (event) => {
    console.log('we are here')
    console.log(event.target.checked)
    if (event.target.checked) {
      setSurgeryFilters({
        ...surgeryFilters,
        pinCode: [...new Set([...surgeryFilters.pinCode, event.target.value])],
      })
    } else {
      setSurgeryFilters({
        ...surgeryFilters,
        pinCode: surgeryFilters.pinCode.filter((pinCode) => {
          return pinCode != event.target.value
        }),
      })
    }
  }

  const filterBtnClick = async (data) => {
    const response = await getAllVendorByLabTest({
      pathLabTestIds: searchParams.location.search
        .split('labtest=')[1]
        .split(','),
      city: surgeryFilters.city,
      pinCode: surgeryFilters.pinCode,
      state: selectLocation.state,
      district: selectLocation.districtName,
      type: 'lab',
    })
    if (response.code === 200) {
      setAllVendorByLabTest(response.data.docs)

      // alert('Hospital Fetched Successfully')
      // window.location.reload()
      handleDrawerClose()
    } else {
      alert(response.message)
    }
  }

  const getAllVendors = async () => {
    const { data } = await getAllVendorByLabTest({
      limit,
      skip: 0,

      state: selectLocation.state,
      district: selectLocation.districtName,
      type: 'lab',
    })
    data.docs.forEach((item) => {
      item.isCompare = false
    })
    // setAllVendorByLabTest(data.docs)

    setPaginationData({
      total: data.totalDocs,
      pageCount: data.totalPages || 1,
      currentPage: data.page,
    })
    setIsLoading(false)
  }

  return (
    <>
      <section className="labsList">
        <Back
          name="Labs Listing"
          title="Labs near you in your city"
          cover={url || img}
        />
        {isloading ? (
          <LoadingPage />
        ) : (
          <div className="container py-5">
            <h3 className="pageTitle">
              Book <span>Diagnostic</span> Tests
            </h3>
            <p className="pageSubTitle">
              Choose from the list of top-selling packages tailored for you. We
              care for your health
            </p>
            <div className="shortingBtn">
              <Box open={open} sx={{ display: 'flex' }}>
                <CssBaseline />
                <IconButton onClick={handleDrawerOpen} className="filterBtn">
                  Filter
                  <img src="/images/filter.png" alt="filter" />
                </IconButton>
                <div>
                  <React.Fragment>
                    <Drawer
                      anchor={'right'}
                      open={open}
                      onClose={toggleDrawer()}
                    >
                      <Box className="drawerrr">
                        <h6 className="titleF">Filter By</h6>

                        <Box className="px-4">
                          <h6 className="py-2 filter_title">Specific Area</h6>

                          {cityList?.map((item, index) => (
                            <FormControlLabel
                              className="inputSe"
                              control={
                                <Checkbox
                                  checked={surgeryFilters.city.includes(item)}
                                />
                              }
                              label={item}
                              value={item}
                              onChange={handleCityChange}
                            >
                              {item}
                            </FormControlLabel>
                          ))}

                          <h6 className="py-2 filter_title">Pincode</h6>

                          {pinCode?.map((item, index) => (
                            <FormControlLabel
                              className="inputSe"
                              control={
                                <Checkbox
                                  checked={surgeryFilters.pinCode.includes(
                                    item.pinCode.toString(),
                                  )}
                                />
                              }
                              label={item.pinCode}
                              value={item.pinCode}
                              onClick={handlePinCodeChange}
                            >
                              {item.pinCode}
                            </FormControlLabel>
                          ))}
                          <Stack spacing={2} direction="row" sx={{ pt: 3 }}>
                            <Button
                              className="filterBtn text-white mx-auto mt-3"
                              onClick={filterBtnClick}
                            >
                              Submit
                            </Button>
                            <Button
                              className="filterBtn text-white mx-auto mt-3"
                              onClick={() => {
                                getAllVendors()
                                setSurgeryFilters({
                                  city: [],
                                  pinCode: [],
                                })
                              }}
                            >
                              Clear
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Drawer>
                  </React.Fragment>
                </div>
              </Box>
              <Button
                variant={'outlined'}
                sx={{
                  color: '#4E5360',
                  borderColor: '#4E5360',
                }}
                className="sortBtn"
                onClick={shortByPrice}
                endIcon={
                  isSort ? (
                    <KeyboardDoubleArrowUpIcon />
                  ) : (
                    <KeyboardDoubleArrowDownIcon />
                  )
                }
              >
                Sort by Price
              </Button>
            </div>
            <Box sx={{ flexGrow: 1, pt: 4 }}>
              <Grid container spacing={2}>
                {vendorByLabTest.length === 0 ? (
                  <Typography variant="h5">{message}</Typography>
                ) : (
                  vendorByLabTest?.map((item) => (
                    <Grid item xs={12} md={6}>
                      <Item>
                        <div className="labsListing">
                          <h3>{item?.name}</h3>
                          <h4>
                            Price -{' '}
                            <span>₹ {item?.pathLabData?.price?.price}</span>
                          </h4>
                        </div>
                        <hr></hr>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="labsListCol">
                              <div className="iconLabs">
                                <LocationOnIcon />
                              </div>
                              <p>
                                {item?.address?.city},{item?.address?.state}
                              </p>
                            </div>
                          </div>

                          {item.isVerified ? (
                            <div className="col-md-4">
                              <div className="labsListCol">
                                <div className="iconLabs colorClass">
                                  <VerifiedIcon color="success" />
                                </div>
                                <p>Verified</p>
                              </div>
                            </div>
                          ) : null}
                          {item.isTrusted ? (
                            <div className="col-md-4">
                              <div className="labsListCol">
                                <div className="iconLabs colorClass">
                                  <VerifiedUserOutlinedIcon color="success" />
                                </div>
                                <p>Trusted</p>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-md-4">
                            <div className="labsListCol">
                              <div className="iconLabs">
                                <DoneIcon />
                              </div>
                              <p>{item?.type}</p>
                            </div>
                          </div>
                          {/* <div className="col-md-4">
                        <div className="labsListCol">
                          <div className="iconLabs">
                            <LocalPhoneIcon />
                          </div>
                          <p>{item?.mobile}</p>
                        </div>
                      </div> */}
                          <div className="col-md-4">
                            <div className="clinicDItem">
                              <div className="clinicDicon">
                                <img src="/images/bag.png" alt="" />
                              </div>
                              <p>
                                {getYear() - item?.yearOfEstablishment} Year
                                Experience
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="labsListCol">
                              <div className="iconLabs">
                                <DescriptionIcon />
                              </div>
                              <p>{item?.description?.substring(0, 10)}</p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <Link
                            to={`/labDetailPage/${item._id}?${searchParams.location.search}`}
                          >
                            {' '}
                            View details
                          </Link>
                          <div className="labsListCol">
                            <Link
                              to={`/labDetailPage/${item._id}?${searchParams.location.search}`}
                            >
                              <button className="bookNowBtn">Book Now</button>
                            </Link>
                          </div>
                        </div>
                      </Item>
                    </Grid>
                  ))
                )}
              </Grid>
            </Box>
            {paginationData.pageCount > 1 && (
              <Stack
                spacing={2}
                sx={{
                  pt: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Pagination
                  count={paginationData.pageCount || 1}
                  onChange={async (e, page) => {
                    const { data } = await getAllVendorByLabTest({
                      limit,
                      skip: (page - 1) * limit,
                      pathLabTestIds: searchParams.location.search
                        .split('labtest=')[1]
                        .split(','),
                      city: selectLocation.name,
                      state: selectLocation.state,
                      district: selectLocation.districtName,
                    })
                    setAllVendorByLabTest(data.docs)
                  }}
                  color="primary"
                />
              </Stack>
            )}
          </div>
        )}
      </section>
    </>
  )
}
const LoadingPage = () => {
  return (
    <div
      style={{
        display: 'Grid',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src="../../../../images/giphy.gif" alt="" />
    </div>
  )
}
export default LabsListing
