import { api } from '../utils/request'

export const getTopSurgery = async (query = {}) => {
  const res = await api({ endpoint: '/surgery/top', method: 'GET', query })
  return res
}

export const getSurgeryDetails = async (id) => {
  const res = await api({ endpoint: `/surgery/${id}`, method: 'GET' })
  return res
}
