import { api } from '../utils/request';

export const getAllPathology = async (query = {}) => {
    const res = await api({ endpoint: '/pathLabCategory/pathology', method: 'GET', query });
    return res;

};

export const getAllRadiology = async (query = {}) => {
    const res = await api({ endpoint: '/pathLabCategory/radiology', method: 'GET', query });
    return res;

};