import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import img from '../../images/bg.jpeg'
import Back from '../../common/Back'
import Box from '@mui/material/Box'
import { IconButton, InputAdornment } from '@mui/material'
import Iconify from '../../Iconify'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFTextField } from '../../hook-form'
import './changePassword.css'
import 'bootstrap/dist/css/bootstrap.css'
import { changePassword,forgetPasswordChange } from '../../../api/changePassword'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const history = useHistory()
  const params = useParams()
  const token = params.token
  console.log(token)
  const userDetail = JSON.parse(localStorage.getItem('userDetail'))

  const [showError, setShowError] = useState()
  const [showLoader, setShowLoader] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const ChangePassSchema = Yup.object().shape({
    newPassword: Yup.string().required('New Password required'),
    ...(token
      ? {}
      : { oldPassword: Yup.string().required('New Password required') }),
  })

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
  }

  const methods = useForm({
    resolver: yupResolver(ChangePassSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods
  const values = watch()

  const onSubmit = async (data) => {
    if (token) {
       const response = await forgetPasswordChange({...data,token})
      if (response.code === 200) {
        localStorage.setItem('userDetail', JSON.stringify(response.data))
        alert('Password changed successfully')
        history.push('/')
      } else {
        setShowError(response.message)
      }
    } else {
      const response = await changePassword(data)
      if (response.code === 200) {
        localStorage.setItem('userDetail', JSON.stringify(response.data))
        alert('Password changed successfully')
        history.push('/')
      } else {
        setShowError(response.message)
      }
    }
    setShowLoader(false)
  }

  return (
    <>
      <Back
        name={token ? 'Update Password' : 'Change Password'}
        title={token ? 'Update Password' : 'Change Password'}
        cover={img}
      />
      <section className="formBg py-5">
        <div className="container row justify-content-center">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                {/* <img src={"../../../images/register.png"} alt='' /> */}
                <div className="signleftSection">
                  <div className="LefttxtSty">
                    <img src={'../../../images/login-graphics.svg'} />
                  </div>
                </div>
              </div>
              <div className="col-md-6 register_page">
                <div className="maintitle">
                  {token ? 'Update Password' : 'Change Password'}
                </div>
                <Box sx={{ width: '100%' }}>
                  <FormProvider
                    methods={methods}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Stack spacing={3} className="py-4">
                      {!token ? (
                        <RHFTextField
                          name="oldPassword"
                          label="Old Password"
                          type="password"
                        />
                      ) : null}

                      <RHFTextField
                        name="newPassword"
                        label="New Password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <Iconify
                                  icon={
                                    showPassword
                                      ? 'eva:eye-fill'
                                      : 'eva:eye-off-fill'
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        className="changeBtn"
                        loading={isSubmitting}
                      >
                        {token ? 'Update Password' : 'Change Password'}
                      </LoadingButton>
                    </Stack>
                  </FormProvider>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
