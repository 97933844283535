import { Stack } from '@mui/system'
import * as Yup from 'yup'
import React, { useEffect, useState, useCallback } from 'react'
import Back from '../common/Back'
import { styled, alpha } from '@mui/material/styles'
import img from '../images/bannerimg.jpg'
import './packages.css'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, RHFTextField } from '../hook-form'
import { LoadingButton } from '@mui/lab'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import AddIcon from '@mui/icons-material/Add'
import AvTimerIcon from '@mui/icons-material/AvTimer'
import DoneIcon from '@mui/icons-material/Done'
import HomeIcon from '@mui/icons-material/Home'
import {
  MenuItem,
  Typography,
  Button,
  Menu,
  Box,
  Item,
  Modal,

} from '@mui/material'
import { useParams, useHistory } from 'react-router-dom'
import { login, generateOtp } from '../../api/login'
import { getAllPackages } from '../../api/package'
import { message } from '../../utils/message'
import { Link } from 'react-router-dom'
// ----------------------------------------------------------

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 3,
}
const Packages = () => {
  useEffect(() => {
    localStorage.removeItem('surgeryPrice')
  }, [])
  const history = useHistory()
  const [expanded, setExpanded] = React.useState('')
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  const [expanded2, setExpanded2] = React.useState('')
  const handleChange2 = (panel) => (event, newExpanded2) => {
    setExpanded2(newExpanded2 ? panel : false)
  }
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [showLoader, setShowLoader] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open2 = Boolean(anchorEl)
  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose2 = () => {
    setAnchorEl(null)
  }
  const [open, setOpen] = React.useState(false)
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const [childModalOpen, setChildModalOpen] = React.useState(false)
  const handleChildModalOpen = () => {
    setChildModalOpen(true)
  }
  const handleChildModalClose = () => {
    setChildModalOpen(false)
  }
  const [otp, setOtp] = useState(0)
  const [data, setData] = useState([])

  const RegisterSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    password: Yup.string(),
  })

  const defaultValues = {
    mobile: '',
    password: '',
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,

    formState: { isSubmitting },
  } = methods
  const values = watch()
  const [isloading, setIsLoading] = useState(true)
  const [packages, setPackages] = useState([])
  useEffect(() => {
    ; (async () => {
      const { data } = await getAllPackages()
      setIsLoading(false)
      setPackages(data.docs)
      console.log(data.docs)
    })()
  }, [])

  const [packageBooking, setPackageBooking] = useState({
    name: '',
    mobile: '',
    email: '',
    age: '',
    gender: '',
    address: {
      houseNo: '',
      streetNo: '',
      locality: '',
      landmark: '',
      city: '',
      district: '',
      state: '',
      pinCode: '',
    },
    status: 'pending',
    price: '',
    isPaid: false,
    discountPrice: '',
    packageId: '',
    slotTo: '',
    slotFrom: '',
  })

  const onSubmit = async (data) => {
    console.log(data)
    setData(data)
    const response = await login(data)
    console.log(data)
    if (response?.data?.token) {
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      window.location.reload()
      handleClose()
      handleChildModalClose()
    } else {
      alert(response.message)
    }
    setShowLoader(false)
  }

  const verify = async () => {
    const response = await login(otp, data)
    console.log(data)
    if (response?.data?.token) {
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      window.location.reload()
      handleClose()
      handleChildModalClose()
    } else {
      alert(response.message)
    }
  }

  return (
    <>
      <section className="packages">
        <Back name="Packages" title="EXPLORE OUR PACKAGES" cover={img} />
        {isloading ? <LoadingPage /> :
          <div className="container py-5">
            {packages.length === 0 ? (
              <Typography className="contactL" variant="h4">
                {message}
              </Typography>
            ) : (
              packages?.map((pack, index) => (
                <Accordion
                  className="pckCard"
                  expanded={expanded === `panel${index + 1}`}
                  onChange={handleChange(`panel${index + 1}`)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <div className="packageAcc">
                      <h3 className="w-50">{pack?.name}</h3>
                      <div className="addBottom w-25">
                        {/* <div className="addressBottom">
                      <h3>Address -</h3>
                      <p>Staplegrove Elm Taunton, Somerset <br></br>TA2 6AN</p>
                    </div> */}
                        {userInfo ? (
                          <Button
                            className="bookNowBtn"
                            onClick={() => {
                              //if user is logged in then redirect to book now page
                              //else redirect to login page
                              if (pack.pathLabTest.length === 0) {
                                alert('No test available under this package')
                                return
                              }
                              if (!localStorage.getItem('userInfo')) {
                                history.push('/login')
                                return
                              } else {
                                const packageBookingDetails = {
                                  ...packageBooking,
                                  price: pack?.price,
                                  status: 'pending',
                                  isPaid: false,
                                  discountPrice: pack?.price,
                                  paymentStatus: 'postpaid',
                                  packageId: pack?._id,
                                  packageName: pack?.name,
                                  customerId: userInfo?.data?._id,
                                }
                                setPackageBooking(packageBookingDetails)
                                localStorage.setItem(
                                  'packageBooking',
                                  JSON.stringify(packageBookingDetails),
                                )
                                history.push('/packageBooking')
                              }
                            }}
                          >
                            Book Now
                          </Button>
                        ) : (
                          <div>
                            <button
                              className="bookNowBtn"
                              onClick={() => {
                                handleOpen()
                              }}
                            >
                              Please Login
                            </button>
                            <Modal
                              className="modalpa"
                              open={open}
                              onClose={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                handleClose()
                              }}
                              aria-labelledby="parent-modal-title"
                              aria-describedby="parent-modal-description"
                            >
                              <Box sx={{ ...style, width: 400 }}>
                                <FormProvider
                                  methods={methods}
                                  onSubmit={handleSubmit(onSubmit)}
                                >
                                  <Stack spacing={3} className="py-4">
                                    <RHFTextField
                                      type="number"
                                      size="small"
                                      name="mobile"
                                      label="Enter Mobile"
                                    />
                                    <RHFTextField
                                      type="password"
                                      size="small"
                                      name="password"
                                      label="Password"
                                    />
                                    <LoadingButton
                                      fullWidth
                                      size="medium"
                                      type="submit"
                                      className="signBtn"
                                      loading={isSubmitting}
                                    >
                                      Login
                                    </LoadingButton>
                                    <div className="text-center">
                                      <p>
                                        Don't have an account?{' '}
                                        <span
                                          style={{
                                            color: '#FF8021',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            history.push('/signRegister')
                                          }}
                                        >
                                          Sign Up
                                        </span>
                                      </p>
                                    </div>
                                    <div className='text-center'>
                                      <Link to="/forgotPassword">Forgot Password?</Link>
                                    </div>
                                  </Stack>
                                </FormProvider>
                              </Box>
                            </Modal>
                            <Modal
                              open={childModalOpen}
                              onClose={handleChildModalClose}
                              aria-labelledby="child-modal-title"
                              aria-describedby="child-modal-description"
                            >
                              <Box className="changeChildPak">
                                <FormProvider className="text-center">
                                  <div className="otpSec2">
                                    <input
                                      type="text"
                                      placeholder="OTP"
                                      onChange={(e) => setOtp(e.target.value)}
                                    />
                                  </div>
                                  <div className="text-center">
                                    <Button
                                      className="otpBtn"
                                      onClick={() => verify()}
                                    >
                                      Login
                                    </Button>
                                  </div>
                                </FormProvider>
                              </Box>
                            </Modal>
                          </div>
                        )}
                      </div>
                      <div className="text-end w-25">
                        <h6 className="mrpDesign">
                          Mrp - <span>₹ {pack?.mrp}</span>
                        </h6>
                        <h4>
                          Offered Price - <span>₹ {pack?.price}</span>
                        </h4>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ fontWeight: '600', color: 'green' }}>{pack?.description}</Typography>
                    <div className="bloodAnalysis">
                      {/* <h3>Blood analysis</h3> */}
                      <div className="widthAccor">
                        {pack?.pathLabTest?.map((test, index) => (
                          <Accordion
                            className="pckCard"
                            expanded={expanded2 === `panel${index + 1}`}
                            onChange={handleChange2(`panel${index + 1}`)}
                          >
                            <AccordionSummary
                              aria-controls="panel2d-content"
                              id="panel2d-header"
                            >
                              <div className="miniAccordian">
                                <h3>{test?.name}</h3>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails className="testDes">
                              {test?.description}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                      {/* <div className="textDetailList">
                    <div className="packageDe">
                      <div className="iconSec">
                        <AddIcon />
                      </div>
                      <p>18 Test</p>
                    </div>
                    <div className="packageDe">
                      <div className="iconSec">
                        <AvTimerIcon />
                      </div>
                      <p>Duration - 3days</p>
                    </div>
                    <div className="packageDe">
                      <div className="iconSec">
                        <DoneIcon />
                      </div>
                      <p>NABL - <span>Approved</span></p>
                    </div>
                    <div className="packageDe">
                      <div className="iconSec">
                        <HomeIcon />
                      </div>
                      <p>Home sample</p>
                    </div>
                  </div> */}
                      <div className="addBottom">
                        {/* <div className="addressBottom">
                      <h3>Address -</h3>
                      <p>Staplegrove Elm Taunton, Somerset <br></br>TA2 6AN</p>
                    </div> */}
                        {userInfo ? (
                          <Button
                            className="bookNowBtn"
                            onClick={() => {
                              //if user is logged in then redirect to book now page
                              //else redirect to login page
                              if (pack.pathLabTest.length === 0) {
                                alert('No test available under this package')
                                return
                              }
                              if (!localStorage.getItem('userInfo')) {
                                history.push('/login')
                                return
                              } else {
                                const packageBookingDetails = {
                                  ...packageBooking,
                                  price: pack?.price,
                                  status: 'pending',
                                  isPaid: false,
                                  discountPrice: pack?.price,
                                  paymentStatus: 'postpaid',
                                  packageId: pack?._id,
                                  packageName: pack?.name,
                                  customerId: userInfo?.data?._id,
                                }
                                setPackageBooking(packageBookingDetails)
                                localStorage.setItem(
                                  'packageBooking',
                                  JSON.stringify(packageBookingDetails),
                                )
                                history.push('/packageBooking')
                              }
                            }}
                          >
                            Book Now
                          </Button>
                        ) : (
                          <div>
                            <button
                              className="bookNowBtn"
                              onClick={() => {
                                handleOpen()
                              }}
                            >
                              Please Login
                            </button>
                            <Modal
                              className="modalpa"
                              open={open}
                              onClose={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                handleClose()
                              }}
                              aria-labelledby="parent-modal-title"
                              aria-describedby="parent-modal-description"
                            >
                              <Box sx={{ ...style, width: 400 }}>
                                <FormProvider
                                  methods={methods}
                                  onSubmit={handleSubmit(onSubmit)}
                                >
                                  <Stack spacing={3} className="py-4">
                                    <RHFTextField
                                      type="number"
                                      size="small"
                                      name="mobile"
                                      label="Enter Mobile"
                                    />
                                    <RHFTextField
                                      type="password"
                                      size="small"
                                      name="password"
                                      label="Password"
                                    />
                                    <LoadingButton
                                      fullWidth
                                      size="medium"
                                      type="submit"
                                      className="signBtn"
                                      loading={isSubmitting}
                                    >
                                      Login
                                    </LoadingButton>
                                    <div className="text-center">
                                      <p>
                                        Don't have an account?{' '}
                                        <span
                                          style={{
                                            color: '#FF8021',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            history.push('/signRegister')
                                          }}
                                        >
                                          Sign Up
                                        </span>
                                      </p>
                                    </div>
                                    <div className='text-center'>
                                      <Link to="/forgotPassword">Forgot Password?</Link>
                                    </div>
                                  </Stack>
                                </FormProvider>
                              </Box>
                            </Modal>
                            <Modal
                              open={childModalOpen}
                              onClose={handleChildModalClose}
                              aria-labelledby="child-modal-title"
                              aria-describedby="child-modal-description"
                            >
                              <Box className="changeChildPak">
                                <FormProvider className="text-center">
                                  <div className="otpSec2">
                                    <input
                                      type="text"
                                      placeholder="OTP"
                                      onChange={(e) => setOtp(e.target.value)}
                                    />
                                  </div>
                                  <div className="text-center">
                                    <Button
                                      className="otpBtn"
                                      onClick={() => verify()}
                                    >
                                      Login
                                    </Button>
                                  </div>
                                </FormProvider>
                              </Box>
                            </Modal>
                          </div>
                        )}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))
            )}
            {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <div className="packageAcc">
                <h3>Redcliff Pathlab</h3>
                <h4>Price - <span>₹ 2200</span></h4>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Vivamus eget aliquam dui. Integer eu arcu vel arcu suscipit ultrices quis non mauris. Aenean scelerisque, sem eu dictum commodo, velit nisi blandit magna, quis scelerisque ipsum lectus ut libero.
              </Typography>
              <div className="bloodAnalysis">
                <h3>Blood analysis</h3>
                <div className="widthAccor">
                  <Accordion expanded={expanded2 === 'panel1'} onChange={handleChange2('panel1')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                      <div className="miniAccordian">
                        <h3>CBC (Complete blood count)</h3>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>Red blood cells</p>
                      <p>White blood cells</p>
                      <p>Hemoglobin</p>
                      <p>Hematocrit</p>
                      <p>Platelets</p>
                      <p>Mean Platelet Volume (MPV)</p>
                      <p>Mean corpuscular volume (MCV)</p>
                      <p>Mean corpuscular hemoglobin concentration (MCHC)</p>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded2 === 'panel2'} onChange={handleChange2('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                      <div className="miniAccordian">
                        <h3>Lipid profile</h3>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>Red blood cells</p>
                      <p>White blood cells</p>
                      <p>Hemoglobin</p>
                      <p>Hematocrit</p>
                      <p>Platelets</p>
                      <p>Mean Platelet Volume (MPV)</p>
                      <p>Mean corpuscular volume (MCV)</p>
                      <p>Mean corpuscular hemoglobin concentration (MCHC)</p>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="textDetailList">
                  <div className="packageDe">
                    <div className="iconSec">
                      <AddIcon />
                    </div>
                    <p>18 Test</p>
                  </div>
                  <div className="packageDe">
                    <div className="iconSec">
                      <AvTimerIcon />
                    </div>
                    <p>Duration - 3days</p>
                  </div>
                  <div className="packageDe">
                    <div className="iconSec">
                      <DoneIcon />
                    </div>
                    <p>NABL - <span>Approved</span></p>
                  </div>
                  <div className="packageDe">
                    <div className="iconSec">
                      <HomeIcon />
                    </div>
                    <p>Home sample</p>
                  </div>
                </div>
                <div className="addBottom">
                  <div className="addressBottom">
                    <h3>Address -</h3>
                    <p>Staplegrove Elm Taunton, Somerset <br></br>TA2 6AN</p>
                  </div>
                  <button className="bookNowBtn">Book Now</button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion> */}
          </div>
        }
      </section>
    </>
  )
}
const LoadingPage = () => {
  return (
    <div style={{
      display: 'Grid',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src='../../../../images/giphy.gif' alt='' />
    </div>

  )
}
export default Packages
