import React from "react";
import Back from "../common/Back";
import Heading from "../common/Heading";
import img from "../images/bannerimg.jpg";
import "./privacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="privacyPolicy">
        <Back name="Privacy Policy" title="Privacy Policy" cover={img} />
        <div className="container row py-4">
          <div className="left col-md-12">
            <Heading title="PRIVACY AND POLICY" />
            {/* <p className="py-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <p className="py-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p> */}
            {/* <h5>Last updated __________ </h5> */}
            <p>
              This privacy notice for VAJ HEALTHTECH PRIVATE LIMITED (clickyourhospital.com) ("we," "us," or
              "our"), describes how and why we might collect, store, use, and/or share ("process") your information
              when you use our services ("Services"), such as when you:
              Questions or concerns? Reading this privacy notice will help you understand your privacy rights and
              choices. If you do not agree with our policies and practices, please do not use our Services.
            </p>
            <br />
            <h1>SUMMARY OF KEY POINTS</h1>
            <p>
              <strong>
                This summary provides key points from our privacy notice, but you can find out more details about
                any of these topics by clicking the link following each key point or by using our table of contents
                below to find the section you are looking for.
              </strong>
            </p>
            <br />
            <p>
              <strong>What personal information do we process? </strong>
              When you visit, use, or navigate our Services, we may
              process personal information depending on how you interact with us and the Services, the choices you
              make, and the products and features you use. Learn more about personal information you disclose to us.
              Do we process any sensitive personal information? We do not process sensitive personal information.
              Do we receive any information from third parties? We may receive information from public databases,
              marketing partners, social media platforms, and other outside sources. Learn more about information
              collected from other sources.
            </p>
            <br />
            <p>
              <strong>How do we process your information?</strong>
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud prevention, and to comply with law. We may
              also process your information for other purposes with your consent. We process your information only
              when we have a valid legal reason to do so. Learn more about how we process your information.
            </p><br />
            <p>
              <strong>In what situations and with which parties do we share personal information?</strong>
              We may share information in specific situations and with specific third parties. Learn more about when and with whom we
              share your personal information.
            </p>
            <br />
            <p>
              <strong>What are your rights?</strong>
              Depending on where you are located geographically, the applicable privacy law
              may mean you have certain rights regarding your personal information. Learn more about your privacy
              rights.
            </p>
            <br />
            <p>
              <strong>How do you exercise your rights? </strong>
              The easiest way to exercise your rights is by submitting a data
              subject access request, or by contacting us. We will consider and act upon any request in accordance with
              applicable data protection laws.
            </p>
            <br />
            <p>Want to learn more about what we do with any information we collect?</p>
            <br />
            <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
            <p>
              <strong>Personal information you disclose to us </strong><br />
              We collect personal information that you provide to us.<br />
              We collect personal information that you voluntarily provide to us when you registeron the Services,
              express an interest in obtaining information about us or our productsand Services, when you participate in
              activities on the Services, or otherwise whenyou contact us.
            </p>
            <p>
              <strong>Information automatically collected</strong>
              Some information — such as your Internet Protocol (IP) address and/orbrowser and device
              characteristics — is collected automatically when you visit ourServices.<br />
              We automatically collect certain information when you visit, use, or navigate theServices. This information
              does not reveal your specific identity (like your name orcontact information) but may include device and
              usage information, such as your IPaddress, browser and device characteristics, operating system,
              languagepreferences, referring URLs, device name, country, location, information about howand when you
              use our Services, and other technical information. This information isprimarily needed to maintain the
              security and operation of our Services, and for ourinternal analytics and reporting purposes.<br />
              Like many businesses, we also collect information through cookies and similartechnologies.
            </p>
            <br />
            <h3> 2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
            <p>
              <strong> In Short: </strong>We process your information to provide, improve, and administer ourServices, communicate with
              you, for security and fraud prevention, and to complywith law. We may also process your information for
              other purposes with your consent.We process your personal information for a variety of reasons,
              depending onhow you interact with our Services, including:
            </p>
            <br />
            <h3>
              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h3>
            <p>
              <strong>Business Transfers. </strong> We may share or transfer your information in connection with, or during negotiations
              of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to
              another company.
            </p>
            <p>
              <strong> Affiliates. </strong> We may share your information with our affiliates, in which case we will require those affiliates
              to honor this privacy notice. Affiliates include ourparent company and any subsidiaries, joint venture
              partners, or othercompanies that we control or that are under common control with us.
            </p>
            <p>
              <strong> Business Partners. </strong> We may share your information with our business partners to offer you certain
              products, services, or promotions.
            </p>
            <br />
            <h3> 4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? </h3>
            <p>
              <strong> In Short:</strong> We may use cookies and other tracking technologies to collect and storeyour information.
              We may use cookies and similar tracking technologies (like web beacons and pixels)to access or store
              information. Specific information about how we use suchtechnologies and how you can refuse certain
              cookies is set out in our Cookie Notice.
            </p>
            <br />
            <h3> 5. HOW DO WE HANDLE YOUR SOCIAL LOGINS? </h3>
            <p>
              <strong>In Short: </strong> If you choose to register or log in to our Services using a social mediaaccount, we may have
              access to certain information about you.
              Our Services offer you the ability to register and log in using your third-party socialmedia account details
              (like your Facebook or Twitter logins). Where you choose to dothis, we will receive certain profile
              information about you from your social mediaprovider. The profile information we receive may vary
              depending on the social mediaprovider concerned, but will often include your name, email address, friends
              list, andprofile picture, as well as other information you choose to make public on such asocial media
              platform.<br />
              We will use the information we receive only for the purposes that are described in thisprivacy notice or that
              are otherwise made clear to you on the relevant Services.Please note that we do not control, and are not
              responsible for, other uses of your personal information by your third-party social media provider. We
              recommend thatyou review their privacy notice to understand how they collect, use, and share
              your personal information, and how you can set your privacy preferences on their sitesand apps.
            </p>
            <br />
            <h3>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? </h3>
            <p>
              <strong> In Short: </strong> We may transfer, store, and process your information in countries otherthan your own.
              Our servers are located in. If you are accessing our Services from outside, please beaware that your
              information may be transferred to, stored, and processed by us inour facilities and by those third parties
              with whom we may share your personalinformation (see "WHEN AND WITH WHOM DO WE SHARE
              YOUR PERSONALINFORMATION?" above), in and other countries.<br />
              If you are a resident in the European Economic Area (EEA), United Kingdom (UK), orSwitzerland, then
              these countries may not necessarily have data protection laws orother similar laws as comprehensive as
              those in your country. However, we will takeall necessary measures to protect your personal information in
              accordance with thisprivacy notice and applicable law.
            </p>
            <br />
            <h3> 7. HOW LONG DO WE KEEP YOUR INFORMATION? </h3>
            <p>
              <strong> In Short: </strong> We keep your information for as long as necessary to fulfill the purposesoutlined in this privacy
              notice unless otherwise required by law.<br />
              We will only keep your personal information for as long as it is necessary for thepurposes set out in this
              privacy notice, unless a longer retention period is required orpermitted by law (such as tax, accounting, or
              When we have no ongoing legitimate business need to process your personalinformation, we will either
              delete or anonymize such information, or, if this is notpossible (for example, because your personal
              information has been stored in backuparchives), then we will securely store your personal information and
              isolate it fromany further processing until deletion is possible.
            </p>
            <br />
            <h3> 8. DO WE COLLECT INFORMATION FROM MINORS? </h3>
            <p>
              <strong> In Short: </strong> We do not knowingly collect data from or market to children under 18 years of age.
              We do not knowingly solicit data from or market to children under 18 years of age. Byusing the Services,
              you represent that you are at least 18 or that you are the parentor guardian of such a minor and consent to
              such minor dependent’s use of theServices. If we learn that personal information from users less than 18
              years of agehas been collected, we will deactivate the account and take reasonable measures topromptly
              delete such data from our records. If you become aware of any data we may have collected from children
              under age 18, please contact us at support@clickyourhospital.com.
            </p>
            <br />
            <h3> 9. WHAT ARE YOUR PRIVACY RIGHTS? </h3>
            <p>
              <strong> In Short: </strong>
              You may review, change, or terminate your account at any time.
              <br />
              <strong> Withdrawing your consent: </strong>  If we are relying on your consent to process your personal information, which
              may be express and/or implied consent depending on the applicable law, you have the right to withdraw
              your consent at any time. You can withdraw your consent at any time by contacting us by using the contact
              details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"below.
              However, please note that this will not affect the lawfulness of the processing beforeits withdrawal nor,
              when applicable law allows, will it affect the processing of your personal information conducted in reliance
              on lawful processing grounds other thanconsent.<br />
              <strong> Account Information </strong> <br /> If you would at any time like to review or change the information in your account orterminate your account,
              you can:<br />
              Upon your request to terminate your account, we will deactivate or delete youraccount and information
              from our active databases. However, we may retain someinformation in our files to prevent fraud,
              troubleshoot problems, assist with anyinvestigations, enforce our legal terms and/or comply with applicable
              legalrequirements.
            </p>
            <br />
            <h3> 10. CONTROLS FOR DO-NOT-TRACK FEATURES </h3>
            <p>
              Most web browsers and some mobile operating systems and mobile applicationsinclude a Do-Not-Track
              ("DNT") feature or setting you can activate to signal yourprivacy preference not to have data about your
              online browsing activities monitoredand collected. At this stage no uniform technology standard for
              recognizing andimplementing DNT signals has been finalized. As such, we do not currently respondto DNT
              browser signals or any other mechanism that automatically communicatesyour choice not to be tracked
              online. If a standard for online tracking is adopted thatwe must follow in the future, we will inform you about
              that practice in a revisedversion of this privacy notice.
            </p>
            <br />
            <h3> 11. DO WE MAKE UPDATES TO THIS NOTICE? </h3>
            <p>
              Yes, we will update this notice as necessary to stay compliant with relevantlaws.
              We may update this privacy notice from time to time. The updated version will beindicated by an updated
              "Revised" date and the updated version will be effective assoon as it is accessible. If we make material
              changes to this privacy notice, we maynotify you either by prominently posting a notice of such changes or
              by directlysending you a notification. We encourage you to review this privacy notice frequentlyto be
              informed of how we are protecting your information.
            </p>
            <br />
            <h3> 12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
            <p>
              If you have questions or comments about this notice, you may contact us by post at:
              <br /> 1. support@clickyourhospital.com
              <br /> 2. +91- 8130972217 <br />
              3. Plot no. 139/2, Samneghat Road Lanka Varanasi-221005
            </p>
            <h3>
              13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THEDATA WE COLLECT FROM YOU?
            </h3>
            <p>
              Based on the applicable laws of your country, you may have the right to request access to the personal
              information we collect from you, change that information, or delete it. To request to review, update, or
              delete your personal information, please fill out and submit a data subject access request.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
