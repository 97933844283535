import { api } from '../utils/request';



export const getLabDetails = async (query = {}) => {
    const res = await api({ endpoint: '/vendor/labDetail', method: 'GET', query });
    return res;
};

// export const getSingleVender = async (id) => {
//     const res = await api({ endpoint: `/vendor/${id}`, method: 'GET' });
//     return res;
// }