import React, { useEffect, useState } from 'react'
import Back from '../common/Back'
import img from '../images/bannerimg.jpg'
import './bloodBank.css'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Stack, Pagination, Typography } from '@mui/material'
import CallIcon from '@mui/icons-material/Call'
import { getAllBloodBank } from '../../api/myBloodBank'
import { getAllBanner } from '../../api/banner'
import { message } from '../../utils/message'
import CircleIcon from '@mui/icons-material/Circle'
// ---------------------------------------------

const limit = 5

const BloodBank = () => {
  useEffect(() => {
    localStorage.removeItem('surgeryPrice')
  }, [])
  const [allBloodBank, setAllBloodBank] = useState([])
  const [bloodList, setBloodList] = useState([])
  const [paginationData, setPaginationData] = useState({
    total: 0,
    pageCount: 0,
    currentPage: 0,
  })
  const [selectLocation, setSelectLocation] = useState(
    JSON.parse(localStorage.getItem('selectLocation')),
  )

  const [url, setUrl] = useState()

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllBanner()
      const tempBanner = data.docs.find((banner) => {
        return banner.page === 'bloodBankList'
      })
      setUrl(tempBanner.url)
      console.log(tempBanner)
    })()
  }, [])

  useEffect(() => {
    window.onLocationChange = () => {
      console.log('location changed')
      setSelectLocation(JSON.parse(localStorage.getItem('selectLocation')))
    }
    return () => {
      window.onLocationChange = () => { }
    }
  }, [])
  const [isloading, setIsLoading] = useState(true)
  useEffect(() => {
    ; (async () => {
      const { data } = await getAllBloodBank({
        limit,
        skip: 0,
        bloodGroup: bloodList,

        'address.state': selectLocation.state,
        'address.district': selectLocation.districtName,
      })
      setIsLoading(false)
      setAllBloodBank(data.docs)
      setPaginationData({
        total: data.totalDocs,
        pageCount: data.totalPages,
        currentPage: data.page,
      })
    })()
  }, [bloodList, selectLocation.state, selectLocation.districtName])

  // const handleChange = event => {
  //   setBloodList(event.target.value);
  //   console.log(event.target.value);
  // };

  const handleChange = (bloodG, shouldAdd) => {
    if (shouldAdd) {
      let list = [...bloodList]
      setBloodList([...new Set([...bloodList, bloodG])])
    } else {
      setBloodList(bloodList.filter((bloodG) => bloodG !== bloodG))
    }
  }

  console.log(bloodList)

  return (
    <>
      <section className="bloodBank">
        <Back
          name="Blood Bank"
          title="FIND THE BLOOD FROM BLOOD BANK"
          cover={url || img}
        />
        {isloading ? <LoadingPage /> :
          <div className="container py-5">
            <h3 className="pageTitle">
              Showing Listing of <span>Blood</span> Bank
            </h3>
            <p className="pageSubTitle">
              Choose from the list of top-selling packages tailored for you. We
              care for your health
            </p>
            <div className="row py-3">
              <div className="col-md-3">
                <div className="bloodBankFilter">
                  <div className="bloodBankFilterItem">
                    {/* <button className='bloodBankFilterBtn'>
                    <p>Sort By</p>
                    <img src='/images/sort.png' alt='Sort' />
                  </button> */}
                    <div className="bloodBankFilterItemContent pt-3">
                      <strong>Blood Group</strong>
                      <div className='filterBlo'>
                        <div className="bloodBankFilterItemContentItem">
                          <input
                            type="checkbox"
                            id="A+"
                            name="A+"
                            value="A+"
                            onClick={(e) => {
                              handleChange('A+', e.target.checked)
                            }}
                          />
                          <label for="A+">A Positive</label>
                        </div>
                        <div className="bloodBankFilterItemContentItem">
                          <input
                            type="checkbox"
                            id="A-"
                            name="A-"
                            value="A-"
                            onClick={(e) => {
                              handleChange('A-', e.target.checked)
                            }}
                          />
                          <label for="A-">A Negative</label>
                        </div>
                        <div className="bloodBankFilterItemContentItem">
                          <input
                            type="checkbox"
                            id="B+"
                            name="B+"
                            value="B+"
                            onClick={(e) => {
                              handleChange('B+', e.target.checked)
                            }}
                          />
                          <label for="B+">B Positive</label>
                        </div>
                        <div className="bloodBankFilterItemContentItem">
                          <input
                            type="checkbox"
                            id="B-"
                            name="B-"
                            value="B-"
                            onClick={(e) => {
                              handleChange('B-', e.target.checked)
                            }}
                          />
                          <label for="B-">B Negative</label>
                        </div>
                        <div className="bloodBankFilterItemContentItem">
                          <input
                            type="checkbox"
                            id="AB+"
                            name="AB+"
                            value="AB+"
                            onClick={(e) => {
                              handleChange('AB+', e.target.checked)
                            }}
                          />
                          <label for="AB+">AB Positive</label>
                        </div>
                        <div className="bloodBankFilterItemContentItem">
                          <input
                            type="checkbox"
                            id="AB-"
                            name="AB-"
                            value="AB-"
                            onClick={(e) => {
                              handleChange('AB-', e.target.checked)
                            }}
                          />
                          <label for="AB-">AB Negative</label>
                        </div>
                        <div className="bloodBankFilterItemContentItem">
                          <input
                            type="checkbox"
                            id="O+"
                            name="O+"
                            value="O+"
                            onClick={(e) => {
                              handleChange('O+', e.target.checked)
                            }}
                          />
                          <label for="O+">O Positive</label>
                        </div>
                        <div className="bloodBankFilterItemContentItem">
                          <input
                            type="checkbox"
                            id="O-"
                            name="O-"
                            value="O-"
                            onClick={(e) => {
                              handleChange('O-', e.target.checked)
                            }}
                          />
                          <label for="O-">O Negative</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                {allBloodBank.length === 0 ? (
                  <Typography className="contactL" variant="h4">
                    {message}
                  </Typography>
                ) : (
                  allBloodBank.map((item) => (
                    <div className="row justify-content-end pb-3">
                      <div className="col-md-11">
                        <div className="bloodBankItemContent">
                          <div className="bloodBankItem">
                            <img src="/images/clinic.png" alt="Clinic" />
                          </div>
                          <div className="contentBlood">
                            <div className="bloodSp">
                              <div>
                                <h3>{item.name}</h3>
                                <div className="bloodTi">
                                  <img
                                    src="/images/location.svg"
                                    alt="Location"
                                  />
                                  <p>
                                    {item.address.city}, {item.address.state}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex facilityI">
                              {item.facilityData.slice(0, 3).map((facility) => {
                                return (
                                  <p>
                                    <CircleIcon
                                      color="error"
                                      sx={{
                                        width: '10px',
                                        height: '10px',
                                        marginRight: '8px',
                                      }}
                                    />
                                    {facility.name}
                                  </p>
                                )
                              })}
                            </div>
                            <TableContainer>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Blood Gr.</TableCell>
                                    {item.bloodBankVendorData.map((item) => (
                                      <TableCell>{item.bloodGroup}</TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Blood Unit
                                    </TableCell>
                                    {item.bloodBankVendorData.map((item) => (
                                      <TableCell>{item.bloodUnitCount}</TableCell>
                                    ))}
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Platelet
                                    </TableCell>
                                    {item.bloodBankVendorData.map((item) => (
                                      <TableCell>
                                        {item.plateletsUnitCount}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      FFP
                                    </TableCell>
                                    {item.bloodBankVendorData.map((item) => (
                                      <TableCell>{item.ffpUnitCount}</TableCell>
                                    ))}
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <div className="bloodBankItemContentBtn">
                              <div className="footerBank">
                                <div className="bloodU">
                                  <p>Blood Unit</p>
                                  <p>
                                    <span>{item.bloodUnitPrice}/</span> Per Unit
                                  </p>
                                </div>
                                <div className="bloodU">
                                  <p>Platelet</p>
                                  <p>
                                    <span>{item.plateletsUnitPrice}/</span> Per
                                    Unit
                                  </p>
                                </div>
                                <div className="bloodU">
                                  <p>FFP (Fresh Frozen Plasma)</p>
                                  <p>
                                    <span>{item.ffpUnitPrice}/</span> Per Unit
                                  </p>
                                </div>
                              </div>
                              <button className="bookNowBtn">
                                <CallIcon /> {item.mobile}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            {paginationData.pageCount > 1 && (
              <Stack
                spacing={2}
                sx={{
                  pt: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Pagination
                  count={paginationData.pageCount}
                  onChange={async (e, page) => {
                    const { data } = await getAllBloodBank({
                      limit,
                      skip: (page - 1) * limit,
                    })
                    setAllBloodBank(data.docs)
                  }}
                  color="primary"
                />
              </Stack>
            )}
          </div>
        }
      </section>
    </>
  )
}
const LoadingPage = () => {
  return (
    <div style={{
      display: 'Grid',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src='../../../../images/giphy.gif' alt='' />
    </div>

  )
}
export default BloodBank
