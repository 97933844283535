import React from 'react'
import Back from '../common/Back'
import Heading from '../common/Heading'
import img from '../images/bannerimg.jpg'
import './howToWork.css'

const HowToWork = () => {
  return (
    <>
      <section className="howToWork">
        <Back title="Careers" cover={img} />
        <div className="container row py-4">
          <div className="left col-md-12">
            <Heading title="Careers" />
            <p className="py-2">
              We are waiting to Welcome you as our part. Start your
              career/job/internship/ journey with us.{' '}
            </p>
            <strong>
              We are a family inside, join our family of career growth.
            </strong>
            <p className="py-2">
              *Clean and hygiene work place and joyful culture
            </p>
            <p className="py-2">
              *Care assistance, equality, training, more helping hands.
            </p>
            <p className="py-2">*Young and progressive team</p>
            <p className="py-2">
              Male, female, or any other gender get full respect and helping
              hands to do best
            </p>
            {/* <p className="py-2">
              *You can choose the hospital of your choice, post that we make
              arrangement for your admission in the hospital.
            </p> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default HowToWork
