import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, TextField } from '@mui/material'
import img from '../images/bannerimg.jpg'
import Back from '../common/Back'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import Autocomplete from '@mui/lab/Autocomplete'
import { FormProvider, RHFTextField, RHFSelect } from '../hook-form'
import './joinUs.css'
import 'bootstrap/dist/css/bootstrap.css'
import { getAllBanner } from '../../api/banner'
import {
  getDistrictByState,
  getCityByDistrict,
  getAllState,
} from '../../api/locationApi'
import { joinUs } from '../../api/joinUs'
// ----------------------------------------------------------------------

const businessType = ['', 'hospital', 'ayurveda', 'lab', 'clinic', 'bloodBank']

export default function JoinUs() {
  const history = useHistory()
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [showError, setShowError] = useState(false)

  const RegisterSchema = Yup.object().shape({
    contactPersonName: Yup.string().required('Contact Person Name is required'),
    businessName: Yup.string().required('Business Name is required'),
    businessType: Yup.string().required('Business Type is required'),
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    address: Yup.object().shape({
      address1: Yup.string().required('Address is required'),
      address2: Yup.string().required('Address is required'),
      state: Yup.string(),
      district: Yup.string(),
      city: Yup.string(),
      pinCode: Yup.number()
        .positive()
        .min(100000, 'Please enter valid Pincode')
        .max(999999, 'Please enter valid Pincode')
        .required('Pincode is required'),
    }),
  })

  const defaultValues = {
    contactPersonName: '',
    businessName: '',
    businessType: '',
    mobile: '',
    address: {
      address1: '',
      address2: '',
      state: '',
      district: '',
      city: '',
      pinCode: '',
    },
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,

    formState: { isSubmitting },
  } = methods
  const values = watch()

  const [url, setUrl] = useState()

  useEffect(() => {
    ;(async () => {
      const { data } = await getAllBanner()
      const tempBanner = data.docs.find((banner) => {
        return banner.page === 'joinUs'
      })
      setUrl(tempBanner.url)
      console.log(tempBanner)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const { data } = await getAllState()
      setStateList(data.docs)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const { data: district } = await getDistrictByState(values.address.state)
      setDistrictList(district.docs)
    })()
  }, [values.address.state])

  useEffect(() => {
    ;(async () => {
      const { data: city } = await getCityByDistrict(values.address.district)
      setCityList(city.docs)
    })()
  }, [values.address.district])

  const onSubmit = async (data) => {
    if(data.address.state === '' || data.address.district === '' || data.address.city === ''){
      alert('Please select state, district and city')
      return
    }
    console.log(data)
    console.log('we are here')
    const response = await joinUs(data)
    if (response.code === 201) {
      alert(
        'Thank you for submitting your request . We will contact you shortly',
      )
      history.push('/', { replace: true })
    } else {
      setShowError(true)
      alert(response.message)
    }
    setShowLoader(false)
  }

  return (
    <>
      <Back cover={url || img} />
      <section className="py-5">
        <div className="container">
          <div className="joinNow">
            <div className="jointitle pb-3">
              You need to fulfill all the details
            </div>
            <p>
              Aenean sed nibh a magna posuere tempor. Nunc faucibus pellentesque
              nunc in aliquet. Donec congue, nunc vel tempor congue, enim sapien
              lobortis ipsum, in volutpat sem ex in ligula.
            </p>
            <Box sx={{ width: '100%' }}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3} className="py-4">
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <RHFTextField
                      size="small"
                      name="contactPersonName"
                      label="Contact Person Name"
                    />
                    <RHFTextField
                      size="small"
                      name="businessName"
                      label="Business Name"
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <RHFSelect
                      name="businessType"
                      size="small"
                      label="Business Type"
                    >
                      {businessType.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFTextField size="small" name="mobile" label="Mobile" />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <RHFTextField
                      size="small"
                      name="address.address1"
                      label="Address 1"
                    />
                    <RHFTextField
                      size="small"
                      name="address.address2"
                      label="address 2"
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Autocomplete
                      name="address.state"
                      size="small"
                      id="combo-box-demo"
                      options={stateList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        setValue('address.state', newValue.name)
                      }}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField {...params} label="State" />
                      )}
                    />
                    <Autocomplete
                      name="address.district"
                      size="small"
                      id="combo-box-demo"
                      options={districtList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        setValue('address.district', newValue.name)
                      }}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField {...params} label="District" />
             
                      )}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Autocomplete
                      name="address.city"
                      size="small"
                      id="combo-box-demo"
                      options={cityList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        setValue('address.city', newValue.name)
                      }}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField {...params} label="Area" />
                      )}
                    />
                    <RHFTextField
                      size="small"
                      type="number"
                      name="address.pinCode"
                      label="Enter area pinCode"
                    />
                  </Stack>
                  <div className="w-25">
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      className="signBtn"
                      loading={isSubmitting}
                    >
                      Submit Now
                    </LoadingButton>
                  </div>
                </Stack>
              </FormProvider>
            </Box>
          </div>
        </div>
      </section>
    </>
  )
}
