import { Stack } from '@mui/system'
import * as Yup from 'yup'
import React, { useEffect, useState, useCallback } from 'react'
import Back from '../common/Back'
import { styled, alpha } from '@mui/material/styles'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, RHFTextField } from '../hook-form'
import { LoadingButton } from '@mui/lab'
import img from '../images/bannerimg.jpg'
import { useParams, useHistory } from 'react-router-dom'
import './hospitalDetailPage.css'
import { MenuItem, Button, Menu, Box, Item, Modal } from '@mui/material'
import { getVendorDetail } from '../../api/vendorDetail'
import { getSurgeryById } from '../../api/surgery'
import { getAllBanner } from '../../api/banner'
import { login, generateOtp } from '../../api/login'
import { getSurgeryDetails } from '../../api/topSurgery'
import { Link } from 'react-router-dom'
// -------------------------------------------------------
const topSurgery = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 3,
}
const HospitalDetailPage = () => {
  const [vendorDetail, setVendorDetail] = useState([])
  const subSurgeryId = JSON.parse(localStorage.getItem('subSurgeryId'))
  const surgeryPrice = JSON.parse(localStorage.getItem('surgeryPrice'))
  const [surgeryDetails, setSurgeryDetails] = useState({})
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [extraPrice, setExtraPrice] = useState(null)
  const [attributeInfo, setAttributeInfo] = useState(null)
  const [surgeryName, setSurgeryName] = useState('')
  const params = useParams()
  const history = useHistory()
  const [showLoader, setShowLoader] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open2 = Boolean(anchorEl)
  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose2 = () => {
    setAnchorEl(null)
  }
  const [open, setOpen] = React.useState(false)
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const [childModalOpen, setChildModalOpen] = React.useState(false)
  const handleChildModalOpen = () => {
    setChildModalOpen(true)
  }
  const handleChildModalClose = () => {
    setChildModalOpen(false)
  }
  const [otp, setOtp] = useState(0)
  const [data, setData] = useState([])

  const RegisterSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    password: Yup.string(),
  })

  const defaultValues = {
    mobile: '',
    password: '',
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,

    formState: { isSubmitting },
  } = methods
  const values = watch()

  const [surgeryBooking, setSurgeryBooking] = useState({
    name: '',
    mobile: '',
    address: {
      houseNo: '',
      streetNo: '',
      locality: '',
      landmark: '',
      city: '',
      district: '',
      state: '',
      pinCode: '',
    },
    treatment: '',
    price: '',
    consultationTime: '',
    status: 'pending',
    paymentStatus: 'unpaid',
    couponCode: '',
    remark: '',
    discountPrice: '',
    vendorId: '',
  })

  const [url, setUrl] = useState()

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllBanner()
      const tempBanner = data.docs.find((banner) => {
        return banner.page === 'surgeryDetail'
      })
      setUrl(tempBanner.url)
      console.log(tempBanner)
    })()
  }, [])
  const [isloading, setIsLoading] = useState(true)
  useEffect(() => {
    localStorage.removeItem('surgeryAttributeValues')
      ; (async () => {
        const { data } = await getVendorDetail({
          vendorId: params.id,
          surgeryId: subSurgeryId,
        })
        setVendorDetail(data)

        const { data: surgeryDetailData } = await getSurgeryDetails(subSurgeryId)
        console.log(surgeryDetailData)
        setSurgeryName(surgeryDetailData[0].name)
        console.log('we are here')

        let attributeNameObj = {}

        for (let i = 0; i < data[0].hospitalSurgeryAttributeData.length; i++) {
          const firstHospitalAttribute = data[0].hospitalSurgeryAttributeData?.[i]

          if (
            !attributeNameObj[firstHospitalAttribute.surgeryAttributeData.name]
          ) {
            attributeNameObj[
              firstHospitalAttribute.surgeryAttributeData.name
            ] = []
          }
          attributeNameObj[firstHospitalAttribute.surgeryAttributeData.name] =
            attributeNameObj[firstHospitalAttribute.surgeryAttributeData.name] ||
            []
          attributeNameObj[firstHospitalAttribute.surgeryAttributeData.name].push(
            {
              value: firstHospitalAttribute.dataValues,
              price: firstHospitalAttribute.price || 0,
            },
          )
        }

        setAttributeInfo(attributeNameObj)

        const { data: surgeryData } = await getSurgeryById(subSurgeryId)

        setSurgeryDetails(surgeryData[0])
        setIsLoading(false)
      })()
  }, [])

  const onSubmit = async (data) => {
    const response = await login(data)
    console.log(data)
    if (response?.data?.token) {
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      window.location.reload()
      handleClose()
      handleChildModalClose()
    } else {
      alert(response.message)
    }
    setShowLoader(false)
  }

  const verify = async () => {
    const response = await login(otp, data)
    console.log(data)
    if (response?.data?.token) {
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      window.location.reload()
      handleClose()
      handleChildModalClose()
    } else {
      alert(response.message)
    }
  }

  return (
    <>
      <section className="hospitalDetailPage">
        <Back
          name="Choose Hospital"
          title="CHOOSE OR CHANGE TO THE RIGHT HOSPITAL"
          cover={url || img}
        />
        {isloading ? <LoadingPage /> :
          <div className="container py-5">
            <div className="row">
              <div className="col-md-9">
                <h3 className="pageTitle">{vendorDetail[0]?.name}</h3>
                <p>{vendorDetail[0]?.type}</p>
                {/* <h6 className="titleYear">Year of Establishment <span>1940</span></h6> */}
                <h5 className="titleFacilities">Facilities -</h5>
                <div className="facilitiesList">
                  {vendorDetail[0]?.facilityData?.map((item, index) => (
                    <div className="row py-1">
                      <div className="col-md-6">
                        <h6>{item.name}</h6>
                      </div>
                      <div className="col-md-6">
                        <p className="availableColor">Available</p>
                      </div>
                    </div>
                  ))}
                  {/* <Link to='/' className='freeOpd'>Free OPD</Link> */}
                </div>

                <h5 className="titleFacilities">
                  Surgery Details - {surgeryName} Surgery
                </h5>
                <div className="facilitiesList">
                  {attributeInfo &&
                    Object.keys(attributeInfo).map((item, index) => (
                      <div className="row py-1">
                        <div className="col-md-6">
                          <h6>{item}</h6>
                        </div>
                        <div className="col-md-6">
                          {attributeInfo[item].length > 1 ? (
                            <select
                              className="form-control form-select"
                              onChange={(e) => {
                                console.log(e.target.value)
                                setExtraPrice({
                                  ...(extraPrice || {}),
                                  [item]: e.target.value,
                                })
                              }}
                            >
                              {/* sort by price */}
                              {attributeInfo[item]
                                .sort(({ price: price1 }, { price: price2 }) => {
                                  return price1 - price2
                                })
                                .map((item) => (
                                  <option value={item.price}>{item.value}</option>
                                ))}
                            </select>
                          ) : (
                            <p>
                              {attributeInfo[item].map((item) => (
                                <span>{item.value}</span>
                              ))}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                </div>

                <h5 className="titleFacilities">Address -</h5>
                <div>
                  <p>
                    {/* <div className="py-1 d-flex align-items-center">
                    <div className="pr">Locality - </div>
                    {vendorDetail[0]?.address?.locality}
                  </div> */}
                    <div className="py-1 d-flex align-items-center">
                      <div className="pr">Pincode - </div>
                      {vendorDetail[0]?.address?.pinCode}
                    </div>
                    <div className="py-1 d-flex align-items-center">
                      <div className="pr">Area - </div>
                      {vendorDetail[0]?.address?.city}
                    </div>
                    <div className="py-1 d-flex align-items-center">
                      <div className="pr">District - </div>
                      {vendorDetail[0]?.address?.district}
                    </div>
                    <div className="py-1 d-flex align-items-center">
                      <div className="pr">State - </div>
                      {vendorDetail[0]?.address?.state}
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="detailBook">
                  <h2>
                    Price -{' '}
                    <span>
                      ₹
                      {Number(surgeryPrice) +
                        Object.values(extraPrice || {}).reduce(
                          (partialSum, a) => partialSum + Number(a),
                          0,
                        )}
                    </span>
                  </h2>
                  {userInfo ? (
                    <button
                      className="bookNowBtn"
                      onClick={() => {
                        const surgeryBookingDetails = {
                          ...surgeryBooking,
                          price:
                            Number(surgeryPrice) +
                            Object.values(extraPrice || {}).reduce(
                              (partialSum, a) => partialSum + Number(a),
                              0,
                            ),
                          status: 'pending',
                          paymentStatus: 'unpaid',
                          couponCode: '',
                          remark: '',
                          discountPrice: surgeryPrice,
                          vendorId: vendorDetail[0]._id,
                          surgeryName: surgeryDetails.name,
                        }
                        setSurgeryBooking(surgeryBookingDetails)

                        // surgeryAttributeValues: [
                        //   {
                        //     attributeName: {
                        //       type: String
                        //     },
                        //     attributeValue: {
                        //       type: Schema.Types.Mixed
                        //     },
                        //     attributePrice: {
                        //       type: Number
                        //     }
                        //   }
                        // ]

                        const surgeryAttributeValues = Object.keys(
                          attributeInfo,
                        ).map((item) => ({
                          attributeName: item,
                          attributePrice: Number(
                            extraPrice?.[item] || attributeInfo[item][0].price,
                          ),
                          attributeValue: attributeInfo[item]?.find(
                            (attrObj) =>
                              Number(attrObj.price) ===
                              Number(
                                extraPrice?.[item] ||
                                attributeInfo[item][0].price,
                              ),
                          ).value,
                        }))

                        localStorage.setItem(
                          'surgeryAttributeValues',
                          JSON.stringify(surgeryAttributeValues),
                        )

                        localStorage.setItem(
                          'surgeryBooking',
                          JSON.stringify(surgeryBookingDetails),
                        )
                        history.push('/surgeryBooking')
                      }}
                    >
                      Book Now
                    </button>
                  ) : (
                    // <button
                    //   className="bookNowBtn"
                    //   onClick={() => {
                    //     history.push('/hospitalLogin');
                    //   }}
                    // >
                    //   Please Login
                    // </button>
                    <div>
                      <button
                        className="bookNowBtn"
                        onClick={() => {
                          handleOpen()
                        }}
                      >
                        Please Login
                      </button>
                      <Modal
                        className="modalL"
                        open={open}
                        onClose={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          handleClose()
                        }}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        <Box sx={{ ...style, width: 400 }}>
                          <FormProvider
                            methods={methods}
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <Stack spacing={3} className="py-4">
                              <RHFTextField
                                type="number"
                                size="small"
                                name="mobile"
                                label="Mobile"
                              />
                              <RHFTextField
                                type="password"
                                size="small"
                                name="password"
                                label="Password"
                              />
                              <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                className="signBtn"
                                loading={isSubmitting}
                              >
                                Login
                              </LoadingButton>
                              <div className="text-center">
                                <p>
                                  Don't have an account?{' '}
                                  <span
                                    style={{
                                      color: '#FF8021',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      history.push('/signRegister')
                                    }}
                                  >
                                    Sign Up
                                  </span>
                                </p>
                              </div>
                              <div className='text-center'>
                                <Link to="/forgotPassword">Forgot Password?</Link>
                              </div>
                            </Stack>
                          </FormProvider>
                        </Box>
                      </Modal>
                      <Modal
                        open={childModalOpen}
                        onClose={handleChildModalClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                      >
                        <Box className="changeChildModal">
                          <FormProvider
                            sx={{
                              textAlign: 'center',
                            }}
                            className="text-center"
                          >
                            <div className="otpSec2">
                              <input
                                type="text"
                                placeholder="OTP"
                                onChange={(e) => setOtp(e.target.value)}
                              />
                            </div>
                            <div className="text-center">
                              <Button className="otpBtn" onClick={() => verify()}>
                                Login
                              </Button>
                            </div>
                          </FormProvider>
                        </Box>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Carousel
              responsive={topSurgery}
              infinite={true}
              // autoPlay={true}
              autoPlaySpeed={2000}
            >
              <div
                className="content"
                onClick={() => {
                  history.push('/packages')
                }}
              >
                <div className="box">
                  <div className="book_rightSec">
                    <div className="p-2">
                      <img src="/images/hospital_icon.png" alt="" />
                      <p>Blood Lab Tests From Top and Trusted Brands</p>
                    </div>
                    <div className="bookingfooter">
                      <p>Offers</p>
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Lab Test</h4>
                    <h5># 01</h5>
                    <h6>Total Checkup Solutions for your Health.</h6>
                  </div>
                </div>
              </div>
              <div
                className="content"
                onClick={() => {
                  history.push('/ayurveda')
                }}
              >
                <div className="box">
                  <div className="book_rightSec">
                    <div className="p-2">
                      <img src="/images/hospital_icon.png" alt="" />
                      <p>
                        Now Begin Your Journey Towards Natural Health By Top and Trusted Ayurvedic Treatment Clinics
                      </p>
                    </div>
                    <div className="bookingfooter">
                      <p>Ayurvedic Treatment</p>
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Ayurvedic Treatment</h4>
                    <h5># 02</h5>
                    <h6>Top and Trusted Ayurvedic Brand In your City</h6>
                  </div>
                </div>
              </div>
              <div
                className="content"
                onClick={() => {
                  history.push('/ayurveda')
                }}
              >
                <div className="box">
                  <div className="book_rightSec">
                    <div className="p-2">
                      <img src="/images/hospital_icon.png" alt="" />
                      <p>Consult India's Top Doctors Dieticians From the comforts of your home.</p>
                    </div>
                    <div className="bookingfooter">
                      <p>Nearest</p>
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Book Nearest Clinic</h4>
                    <h5># 03</h5>
                    <h6>Book Your Specialist Doctors and Dieticians</h6>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        }
      </section>
    </>
  )
}
const LoadingPage = () => {
  return (
    <div style={{
      display: 'Grid',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src='../../../../images/giphy.gif' alt='' />
    </div>

  )
}
export default HospitalDetailPage
