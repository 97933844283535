import React from "react";
import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/css/bootstrap.css";
import "./aboutSection.css";
// --------------------------------------------------------

const AboutSection = () => {
  return (
    <>
      <section className="py-5 aboutSection border-top">
        <div className="container">
          <img src={"../../../../images/bg_3.svg"} alt="" className="icon_2" />
          {/* <h2 className="title_h pb-5">Our best services for you<span></span></h2> */}
          <div className="row">
            <div className="col-md-6">
              <div className="aboutSection_img">
                <img src={"../../../../images/about.png"} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="aboutSection">
                <div className="mainTitle">
                  <span>
                    <img src={"../../../../images/line.png"} alt="" />
                    Get Know About Us
                  </span>
                  <h2>About us</h2>
                </div>
                <p className="aboutSection_p">
                  We clickyourhospital.com’’ team came under the parents company
                  Vaj healthtech pvt. Ltd. Which was founded by Dr. Shiva(A
                  healthcare expert) Dr. R.k. gupta and Vineet Agarwal (A delhi
                  university alumni) and a serial entrepreneur with the help
                  of Jitendra kumar (M.B.A) and Neha gupta (DELHI UNIVERSITY
                  Alumni) <br /> <br />
                  <strong>Our Mission : - </strong>
                  Bringing the best health care services to everyone at best
                  price with full of transparency every body have right to know
                  how he get and how he pay. <br /> <br />
                  <strong>Our vision: -</strong>
                  We are committed to make better of every one health care
                  journey through our clear transparent services and latest
                  technology <br /> <br />
                  <strong>Our values: -</strong>
                  Everyone is like our families, and it is our duty that our
                  family never fell hassle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection;
