import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import 'bootstrap/dist/css/bootstrap.css'
import './home.css'
import { getAllBanner } from '../../../api/banner'
// --------------------------------------------------------
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}
const Home = () => {
  const [url, setUrl] = React.useState([])

  // useEffect(() => {
  //   (async () => {
  //     const data = await getAllBanner();
  //     setBanner(data);
  //   })();
  // }, []);

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllBanner()
      const tempBanner = data.docs.filter((banner) => {
        return banner.page === 'homePage'
      })
      setUrl(tempBanner)
      console.log(tempBanner)
    })()
  }, [])
  console.log(url)
  useEffect(() => {
    localStorage.removeItem('surgeryBooking')
    localStorage.removeItem('ayurvedaBooking')
    localStorage.removeItem('labBooking')
    localStorage.removeItem('compareHospitals')
    localStorage.removeItem('subSurgeryId')
    // localStorage.removeItem("selectLocation");
    localStorage.removeItem('bookSurgery')
    localStorage.removeItem('surgeryPrice')
    localStorage.removeItem('bookCLinic')
    localStorage.removeItem('clinicBooking')
    localStorage.removeItem('bookAyurveda')
    localStorage.removeItem('bookLabs')
    localStorage.removeItem('packageBooking')
    localStorage.removeItem('bookPackage')
  }, [])

  return (
    <>
      <section className="home">
        <div className="container position-relative">
          <div className="zoom-in-out-box"></div>
          <div className="zoom-in-out-box2"></div>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2500}
          >
            <div className="content">
              <div className="box">
                <div className="bannerContent">
                  <h1>
                    Serving Your <span>Health</span> Needs Is Our <br></br>
                    <span>Priority.</span>
                  </h1>
                  <p>
                    Get Approx /Estimates cost for hospital/ Surgery/Doctor/Lab/Ayurveda/Panchkarma/<br></br>
                    <strong>Surgery Treatment and their Costs.</strong><br></br>
                    <strong>Best/Top Hospital,Lab and Doctor In your City</strong>
                  </p>
                </div>
                <div className="img_banner">
                  {url[0] && <img src={url[0].url} alt="" />}
                </div>
              </div>
            </div>
            <div className="content">
              <div className="box">
                <div className="bannerContent">
                  <h1>
                    Detail <span>Information</span> about treatment <br></br>
                    <span>Hospital and Doctor.</span>
                  </h1>
                  <p>
                    We clickyourhospital are a passionate and professionally
                    managed organization, helping take care of their
                    healthcare/treatment/wellness needs
                  </p>
                </div>
                <div className="img_banner">
                  {url[1] && <img src={url[1].url} alt="" />}
                </div>
              </div>
            </div>
            <div className="content">
              <div className="box">
                <div className="bannerContent">
                  <h1>
                    Patient can choose<span> Hospital</span> of their <br></br>
                    <span>choice.</span>
                  </h1>
                  <p>
                    We offer comprehensive and cost effective health and
                    wellness services to people in India
                  </p>
                </div>
                <div className="img_banner">
                  {url[2] && <img src={url[2].url} alt="" />}
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </section>
    </>
  )
}

export default Home
