import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, TextField } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFTextField } from '../../hook-form'
import 'react-multi-carousel/lib/styles.css'
import 'bootstrap/dist/css/bootstrap.css'
import './bookSurgery.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Autocomplete from '@mui/lab/Autocomplete'
import { getAllCity } from '../../../api/locationApi'
import { getSurgery } from '../../../api/surgery'
import { lead } from '../../../api/lead'
// --------------------------------------------------------

const BookSurgery = () => {
  const [showLoader, setShowLoader] = useState(false)
  const [showError, setShowError] = useState(false)
  const [value] = useState('')
  const history = useHistory()

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    // city: Yup.string().required('Area required'),
    // surgery: Yup.string().required('Surgery required'),
    email: Yup.string().required('Email required'),
    address: Yup.object().shape({
      city: Yup.string(),
      district: Yup.string(),
      state: Yup.string(),
    }),
  })

  const defaultValues = {
    name: '',
    mobile: '',
    // city: '',
    // surgery: '',
    email: '',
    address: {
      city: '',
      district: '',
      state: '',
    },
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = methods
  const values = watch()

  // useEffect(() => {
  //   (async () => {
  //     const { data } = await getAllCity();
  //     setCityList(data.docs);
  //   })();
  // }, []);

  // useEffect(() => {
  //   (async () => {
  //     const { data } = await getSurgery();
  //     setSurgeryList(data.docs);
  //   })();
  // }, []);

  const onSubmit = async (data) => {
    console.log(data)
    const response = await lead(data)
    if (response.code === 201) {
      alert('Submitted Successfully')
      window.location.reload()
    } else {
      setShowError(true)
      alert(response.message)
    }
    setShowLoader(false)
  }

  // const optionList = cityList.map((city) => ({
  //   label: `${city.name} - ${city.districtName} - ${city.stateName}`,
  //   value: city.name
  // }))

  // const surgeryOptionList = surgeryList?.map((surgery) => ({
  //   label: `${surgery.name}`,
  //   value: surgery._id
  // }))

  console.log(errors)
  return (
    <>
      <section className="py-5 bookSurgeryS border-top">
        <div className="container">
          {/* <h2 className="title_h pb-5">Our best services for you<span></span></h2> */}
          <div className="row">
            <div className="col-md-6">
              <div className="bookSurgery mt-3">
                <div className="mainTitle">
                  <span>
                    <img src={'../../../../images/line.png'} alt="" />
                    Enquire Now
                  </span>
                  <h2>Book your surgery to easy way</h2>
                </div>
                <div className="pb-3">
                  <strong>Fill the all details</strong>
                </div>
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Stack spacing={3}>
                    <RHFTextField size="small" name="name" label="Full Name" />
                    <RHFTextField
                      size="small"
                      name="mobile"
                      label="Phone Number"
                    />
                    <RHFTextField size="small" name="email" label="Email" />
                    <RHFTextField
                      size="small"
                      name="address.city"
                      label="Area"
                    />
                    <RHFTextField
                      size="small"
                      name="address.district"
                      label="District"
                    />
                    <RHFTextField
                      size="small"
                      name="address.state"
                      label="State"
                    />
                    {/* <Autocomplete
                      name="city"
                      className="font_Loc"
                      size='small'
                      value={optionList.find((option) => option.value === value)}
                      id="combo-box-demo"
                      options={optionList}
                      onChange={(event, newValue) => {
                        setValue('city', newValue.value);
                        localStorage.setItem('selectLocation', JSON.stringify(
                          {
                            name: newValue.value,
                            districtName: newValue.label.split(' - ')[1],
                            state: newValue.label.split(' - ')[2]
                          }
                        ))
                      }}
                      style={{ width: '100%' }}
                      renderInput={(params) => <TextField {...params} label="Select Area" />}
                    /> */}
                    {/* <Autocomplete
                      name="surgery"
                      className="font_Loc"
                      size='small'
                      value={surgeryOptionList.find((option) => option.value === surgery)}
                      id="combo-box-demo"
                      options={surgeryOptionList}
                      onChange={(event, newValue) => {
                        setValue('surgery', newValue.value);
                      }}
                      style={{ width: '100%' }}
                      renderInput={(params) => <TextField {...params} label="Surgery" />}
                    /> */}
                    <Stack direction={{ xs: 'column', sm: 'row' }}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        className="submitBtn"
                        loading={isSubmitting}
                      >
                        Submit Now <ArrowForwardIcon />
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </FormProvider>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bookSurgery_img pt-5">
                <img src={'../../../../images/bookSurgery.png'} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BookSurgery
