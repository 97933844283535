import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, IconButton, InputAdornment } from '@mui/material'
import img from '../images/bannerimg.jpg'
import Back from '../common/Back'
import { Box, Modal, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import Iconify from '../Iconify'
import { FormProvider, RHFTextField } from '../hook-form'
import './signIn.css'
import 'bootstrap/dist/css/bootstrap.css'
import { login, generateOtp } from '../../api/login'
// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
}
export default function RegisterForm() {
  const history = useHistory()
  const [showLoader, setShowLoader] = useState(false)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [otp, setOtp] = useState(0)
  const [data, setData] = useState([])

  const RegisterSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    password: Yup.string().required('Password is required'),
  })

  const defaultValues = {
    mobile: '',
    password: '',
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,

    formState: { isSubmitting },
  } = methods
  const values = watch()

  const onSubmit = async (data) => {
    console.log(data)
    const response = await login(data)
    console.log(data)
    if (response?.data?.token) {
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      history.push('/')
      window.location.reload()
    } else {
      alert(response.message)
    }
    setShowLoader(false)
  }

  const verify = async () => {
    const response = await login(data)
    console.log(data)
    if (response?.data?.token) {
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      history.push('/')
      window.location.reload()
    } else {
      alert(response.message)
    }
  }

  return (
    <section className="signInn">
      <Back title="Login" cover={img} />
      <section className="formBg py-5">
        <div className="container row justify-content-center">
          <div className="col-md-12 b-x p-5">
            <div className="row">
              <div className="col-md-6 register_page">
                <div className="maintitle pb-3">Sign in to Account</div>
                <p>Welcome back ! Please enter your details for continue</p>
                <Box sx={{ width: '100%' }}>
                  <FormProvider
                    methods={methods}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Stack spacing={3} className="py-4">
                      <RHFTextField
                        type="number"
                        size="small"
                        name="mobile"
                        label="Enter Mobile"
                      />
                      <RHFTextField
                        type="password"
                        size="small"
                        name="password"
                        label="Enter Password"
                      />
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        className="signBtn"
                        loading={isSubmitting}
                      >
                        Login
                      </LoadingButton>
                      <div>
                        <Link to="/forgotPassword">Forgot Password?</Link>
                      </div>
                    </Stack>
                  </FormProvider>
                </Box>
              </div>
              <div className="col-md-6">
                {/* <img src={"../../../images/register.png"} alt='' /> */}
                <div className="signInleftSection">
                  <img
                    src={'../../../images/bg_1.svg'}
                    className="bg_1"
                    alt=""
                  />
                  <img
                    src={'../../../images/bg_2.svg'}
                    className="bg_2"
                    alt=""
                  />
                  <img
                    src={'../../../images/bg_3.svg'}
                    className="bg_3"
                    alt=""
                  />
                  <div className="needHelpSty text-center">
                    <div className="title">Hello, Friend!</div>
                    <h6>
                      Fill up personal information and<br></br> start journey
                      with us.
                    </h6>
                    <div className='mt-5'>
                      <Link to="/signRegister" className="signIn">Sign Up</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}
