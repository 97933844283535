import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Back from '../common/Back'
import img from '../images/bannerimg.jpg'
import { useHistory } from 'react-router-dom'
import {
  Stack,
  Slider,
  Pagination,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material'
import { message } from '../../utils/message'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import './chooseHospital.css'
import { styled, useTheme } from '@mui/material/styles'
import { getAllVendorBySurgery } from '../../api/vendorBySurgery'
import { getPincode } from '../../api/getPincode'
import { getAllBanner } from '../../api/banner'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { getSurgeryDetails } from '../../api/topSurgery'
import VerifiedIcon from '@mui/icons-material/Verified'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'
// -------------------------------------------------------------

const limit = 5

const drawerWidth = 400

// reduce width of drawer
const Drawer = styled(
  MuiDrawer,
  {},
)(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    // position: "relative",
    // whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
      }),
      width: 0,

      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}))

let stepperValue = null

const ChooseHospital = () => {
  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setOpen(false)
  }
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    localStorage.removeItem('surgeryPrice')
  }, [])
  const history = useHistory()
  const [surgeryName, setSurgeryName] = useState('')
  const [pinCode, setPinCode] = useState([])
  const [cityList, setCityList] = useState([])
  const [isSort, setIsSort] = useState(false)
  const [vendorBySurgery, setVendorBySurgery] = useState([])
  const [selectLocation, setSelectLocation] = useState(
    JSON.parse(localStorage.getItem('selectLocation')),
  )

  const compareHospi = JSON.parse(localStorage.getItem('compareHospitals'))
  const [sliderRange, setSliderRange] = useState([0, 100000])
  const id = window.location.pathname.split('/')[2]
  const [compareHospitals, setCompareHospitals] = useState([])
  const [paginationData, setPaginationData] = useState({
    total: 0,
    pageCount: 0,
    currentPage: 0,
  })
  const [isloading, setIsLoading] = useState(true)
  const [url, setUrl] = useState()

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllBanner()
      const tempBanner = data.docs.find((banner) => {
        return banner.page === 'hospitalSurgeryListing'
      })
      setUrl(tempBanner.url)
      localStorage.removeItem('compareHospitals')
    })()

    return () => {
      // console.log('cleanup')
    }
  }, [])

  useEffect(() => {
    window.onLocationChange = () => {
      // console.log('location changed')
      setSelectLocation(JSON.parse(localStorage.getItem('selectLocation')))
    }
    return () => {
      window.onLocationChange = () => { }
      console.log('we are here2')
    }
  }, [])

  useEffect(() => {
    ; (async () => {
      const { data } = await getPincode({
        districtName: selectLocation.districtName,
        stateName: selectLocation.state,
      })

      const uniquePincode = []
      data.forEach((item) => {
        const isExist = uniquePincode.find((value) => {
          return value.pinCode === item.pinCode
        })
        if (!isExist) {
          uniquePincode.push(item)
        }
      })
      setPinCode(uniquePincode)
      //remove duplicate city
      const city = data.map((item) => item.cityName)
      const uniqueCity = [...new Set(city)]
      // console.log(uniqueCity)
      setCityList(uniqueCity)
    })()
  }, [selectLocation.districtName, selectLocation.state])

  useEffect(() => {
    ; (async () => {
      const { data: surgeryData } = await getSurgeryDetails(id)
      console.log(surgeryData)
      setSurgeryName(surgeryData[0].name)
      console.log('we are here')

      setSurgeryFilters({
        price: [0, 500000],
        city: [],
        pinCode: [],
      })

      const { data } = await getAllVendorBySurgery({
        limit,
        skip: 0,
        surgeryId: id,

        state: selectLocation.state,
        district: selectLocation.districtName,
      })
      setIsLoading(false)
      data.docs.forEach((item) => {
        item.isCompare = false
      })
      setVendorBySurgery(data.docs)
      const priceArray = data.docs.map((item) => {
        return (
          item?.hospitalSurgeryData?.hospitalSurgeryAttributeData?.price || 0
        )
      })

      const minimumPrice = Math.min(...priceArray)
      const maximumPrice = Math.max(...priceArray)

      stepperValue = Math.ceil((maximumPrice - minimumPrice) / 100) * 10
      stepperValue = 1000

      // console.log(stepperValue)

      const sliderRange = [
        Math.floor(minimumPrice / stepperValue) * stepperValue,
        Math.ceil(maximumPrice / stepperValue) * stepperValue,
      ]

      setSliderRange(sliderRange)
      setValue1(sliderRange)
      console.log('we are here3')
      localStorage.removeItem('compareHospitals')
      setPaginationData({
        total: data.totalDocs,
        pageCount: data.totalPages || 1,
        currentPage: data.page,
      })
    })()
  }, [id, selectLocation.state, selectLocation.districtName])

  const getAllVendors = async () => {
    const { data } = await getAllVendorBySurgery({
      limit,
      skip: 0,
      surgeryId: id,
      city: [selectLocation.name],
      state: selectLocation.state,
      district: selectLocation.districtName,
    })
    data.docs.forEach((item) => {
      item.isCompare = false
    })
    setVendorBySurgery(data.docs)
    const priceArray = data.docs.map((item) => {
      return item?.hospitalSurgeryData?.hospitalSurgeryAttributeData?.price || 0
    })
    // console.log(priceArray)
    const minimumPrice = Math.min(...priceArray)
    const maximumPrice = Math.max(...priceArray)

    stepperValue = Math.ceil((maximumPrice - minimumPrice) / 100) * 10
    stepperValue = 1000

    // console.log(stepperValue)

    const sliderRange = [
      Math.floor(minimumPrice / stepperValue) * stepperValue,
      Math.ceil(maximumPrice / stepperValue) * stepperValue,
    ]

    setSliderRange(sliderRange)
    setValue1(sliderRange)

    setPaginationData({
      total: data.totalDocs,
      pageCount: data.totalPages || 1,
      currentPage: data.page,
    })
  }

  useEffect(() => {
    ; (async () => {
      const { data } = await getPincode({
        cityName: selectLocation.name,
        districtName: selectLocation.districtName,
        stateName: selectLocation.state,
      })

      const uniquePincode = []
      data.forEach((item) => {
        const isExist = uniquePincode.find((value) => {
          return value.pinCode === item.pinCode
        })
        if (!isExist) {
          uniquePincode.push(item)
        }
      })
      setPinCode(uniquePincode)
    })()
  }, [selectLocation.name, selectLocation.districtName])

  const shortByPrice = () => {
    if (isSort) {
      const sorted = [...vendorBySurgery]
      sorted.sort((a, b) => {
        return (
          b.hospitalSurgeryData.hospitalSurgeryAttributeData.price -
          a.hospitalSurgeryData.hospitalSurgeryAttributeData.price
        )
      })
      setVendorBySurgery(sorted)
      setIsSort(false)
    } else {
      const sorted = [...vendorBySurgery]
      sorted.sort((a, b) => {
        return (
          a.hospitalSurgeryData.hospitalSurgeryAttributeData.price -
          b.hospitalSurgeryData.hospitalSurgeryAttributeData.price
        )
      })
      setVendorBySurgery(sorted)
      setIsSort(true)
    }
  }

  const handleCompare = () => {
    const compareHospitals = vendorBySurgery.filter((item) => item.isCompare)
    if (compareHospitals.length > 3) {
      alert('You can compare only 3 hospitals')
      return
    }
    setCompareHospitals(compareHospitals)
    localStorage.setItem('compareHospitals', JSON.stringify(compareHospitals))
  }

  const [surgeryFilters, setSurgeryFilters] = useState({
    price: [0, 500000],
    city: [],
    pinCode: [],
  })
  function valuetext(value) {
    return `${value}°C`
  }
  const minDistance = stepperValue
  const [value1, setValue1] = React.useState([0, 10000])
  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0]), value1[1]])
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)])
    }
  }

  useEffect(() => {
    setSurgeryFilters((surgeryFilters) => ({
      ...surgeryFilters,
      price: [value1],
    }))
  }, value1)

  const handleCityChange = (event) => {
    if (event.target.checked) {
      setSurgeryFilters({
        ...surgeryFilters,
        city: [...new Set([...surgeryFilters.city, event.target.value])],
      })
    } else {
      setSurgeryFilters({
        ...surgeryFilters,
        city: surgeryFilters.city.filter((city) => {
          return city != event.target.value
        }),
      })
    }
  }

  const handlePinCodeChange = (event) => {
    if (event.target.checked) {
      setSurgeryFilters({
        ...surgeryFilters,
        pinCode: [...new Set([...surgeryFilters.pinCode, event.target.value])],
      })
    } else {
      setSurgeryFilters({
        ...surgeryFilters,
        pinCode: surgeryFilters.pinCode.filter((pinCode) => {
          return pinCode != event.target.value
        }),
      })
    }
  }

  const filterBtnClick = async (data) => {
    const response = await getAllVendorBySurgery({
      surgeryId: id,
      city: surgeryFilters.city,
      pinCode: surgeryFilters.pinCode,
      from: surgeryFilters.price[0][0],
      to: surgeryFilters.price[0][1],
      state: selectLocation.state,
      district: selectLocation.districtName,
    })
    if (response.code === 200) {

      setVendorBySurgery(response.data.docs)
      handleDrawerClose()
    } else {
      alert(response.message)
    }
  }
  console.log(isloading)
  return (
    <>
      <section className="chooseHospital">
        <Back
          name="Choose Hospital"
          title="CHOOSE OR CHANGE TO THE RIGHT HOSPITAL"
          cover={url || img}
        />
        {isloading ? <LoadingPage /> :
          <div className="container py-5">
            <h3 className="pageTitle">
              Showing best <span>Hospitals</span> for {surgeryName} surgery
            </h3>
            <div className="row justify-content-end">
              <div className="col-md-6">
                <div className="shortingBtn">
                  <Box open={open} sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <IconButton onClick={handleDrawerOpen} className="filterBtn">
                      Filter
                      <img src="/images/filter.png" alt="filter" />
                    </IconButton>
                    <div>
                      <React.Fragment>
                        <Drawer
                          anchor={'right'}
                          open={open}
                          onClose={toggleDrawer()}
                          className="mainDra"
                        >
                          <Box className="drawerrr">
                            <h6 className="titleF">Filter By</h6>

                            <Box className="px-4">
                              <h6 className="py-2 filter_title">Specific Area</h6>

                              {cityList?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  className="inputSe"
                                  control={
                                    <Checkbox
                                      checked={surgeryFilters.city.includes(item)}
                                    />
                                  }
                                  label={item}
                                  value={item}
                                  onChange={handleCityChange}
                                >
                                  {item}
                                </FormControlLabel>
                              ))}

                              <h6 className="pt-2 pb-5 filter_title">
                                Price(In Rupees)
                              </h6>
                              {stepperValue && (
                                <Slider
                                  min={sliderRange[0]}
                                  max={sliderRange[1]}
                                  step={stepperValue}
                                  getAriaLabel={() => 'Minimum distance'}
                                  value={value1}
                                  onChange={handleChange1}
                                  valueLabelDisplay="auto"
                                  getAriaValueText={valuetext}
                                  disableSwap
                                  valueLabelDisplay="on"
                                />
                              )}
                              <input
                                className="priceSec"
                                size="small"
                                value={value1[0]}
                                onChange={(e) =>
                                  setValue1([e.target.value, value1[1]])
                                }
                                name="min"
                                placeholder="Min Price"
                              />
                              <input
                                className="priceSec"
                                size="small"
                                value={value1[1]}
                                name="max"
                                onChange={(e) =>
                                  setValue1([value1[0], e.target.value])
                                }
                                placeholder="Max Price"
                              />

                              <h6 className="py-2 filter_title">Pincode</h6>

                              {pinCode?.map((item, index) => (
                                <FormControlLabel
                                  className="inputSe"
                                  control={
                                    <Checkbox
                                      checked={surgeryFilters.pinCode.includes(
                                        item.pinCode.toString(),
                                      )}
                                    />
                                  }
                                  label={item.pinCode}
                                  value={item.pinCode}
                                  onClick={handlePinCodeChange}
                                >
                                  {item.pinCode}
                                </FormControlLabel>
                              ))}
                              <Stack spacing={2} direction="row" sx={{ pt: 3 }}>
                                <Button
                                  className="filterBtn text-white mx-auto mt-3"
                                  onClick={filterBtnClick}
                                >
                                  Submit
                                </Button>
                                <Button
                                  className="filterBtn text-white mx-auto mt-3"
                                  onClick={() => {
                                    getAllVendors()
                                    setSurgeryFilters({
                                      price: [0, 500000],
                                      city: [],
                                      pinCode: [],
                                    })
                                  }}
                                >
                                  Clear
                                </Button>
                              </Stack>
                            </Box>
                          </Box>
                        </Drawer>
                      </React.Fragment>
                    </div>
                  </Box>
                  <Button
                    variant={'outlined'}
                    sx={{
                      color: '#4E5360',
                      borderColor: '#4E5360',
                    }}
                    className="sortBtn"
                    onClick={shortByPrice}
                    endIcon={
                      isSort ? (
                        <KeyboardDoubleArrowUpIcon />
                      ) : (
                        <KeyboardDoubleArrowDownIcon />
                      )
                    }
                  >
                    Sort by Price
                  </Button>
                  {Array.isArray(compareHospi) && compareHospi.length ? (
                    <Button
                      className="compareBtn"
                      onClick={() => {
                        if (compareHospi.length >= 2) {
                          history.push('/compareHospital')
                        } else {
                          alert('Please select atleast 2 hospitals')
                        }
                      }}
                    >
                      <img src="/images/compare.png" alt="compare" />
                      Compare
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <Stack sx={{ py: 5 }}>
              {vendorBySurgery?.length ? (
                <div>
                  {vendorBySurgery?.map((item, index) => (
                    <div className="row pb-3">
                      <div className="col-md-12">
                        <div className="hospitalAndTreatment">
                          <div className="row">
                            <Tooltip title="Image can be real/unreal">
                              <div className="col-md-2 borderSe">
                                <div className="hospitalImg">
                                  <img
                                    src={
                                      item.logo ? item.logo : '/images/knee_1.png'
                                    }
                                    alt="hospital"
                                  />
                                </div>
                              </div>
                            </Tooltip>
                            <div className="col-md-10">
                              <div className="treatment">
                                <div className="treatmentTitle">
                                  <div className='d-flex align-items-center'>
                                    <Tooltip title="Best Hospital in Your City">
                                      <h3>{item?.name}</h3>
                                    </Tooltip>
                                    <div className="treatmentRating px-2">
                                      {item?.isVerified ? (
                                        <div className="clinicDItem">
                                          <div className="clinicDicon">
                                            <VerifiedIcon />
                                          </div>
                                          <p>
                                            {item?.isVerified
                                              ? 'Verified'
                                              : 'Not Verified'}
                                          </p>
                                        </div>
                                      ) : null}
                                      {item?.isTrusted ? (
                                        <div className="clinicDItem px-2">
                                          <div className="clinicDicon">
                                            <VerifiedUserOutlinedIcon />
                                          </div>
                                          <p>
                                            {item?.isTrusted ? 'Trusted' : ''}
                                          </p>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <button
                                    className="compareBtn2"
                                    onClick={() => {
                                      // console.log(compareHospitals)
                                      const updated = [...vendorBySurgery]
                                      if (compareHospitals.length > 2) {
                                        alert('You can compare only 3 hospitals')
                                      } else {
                                        updated[index].isCompare = !updated[index]
                                          .isCompare
                                        setVendorBySurgery(updated)
                                        handleCompare()
                                      }
                                    }}
                                  >
                                    <img
                                      src="/images/compare_2.png"
                                      alt="compare"
                                    />
                                    {item.isCompare
                                      ? 'Remove to Compare'
                                      : 'Add to Compare'}
                                  </button>
                                </div>
                                <div className="treatmentDetail">
                                  {item?.facilityData?.map((item) => (
                                    <div className="treatmentCol">
                                      <h4>{item?.name}</h4>
                                    </div>
                                  ))}
                                </div>
                                <div className="treatmentDetail">
                                  <div className="treatmentCol">
                                    <button
                                      onClick={() => {
                                        localStorage.setItem(
                                          'surgeryPrice',
                                          JSON.stringify(
                                            item.hospitalSurgeryData
                                              .hospitalSurgeryAttributeData.price,
                                          ),
                                        )
                                        history.push(
                                          `/hospitalDetailPage/${item._id}`,
                                        )
                                      }}
                                      className="bookNowBtn"
                                    >
                                      View more details
                                    </button>
                                  </div>
                                  <div className="treatmentCol">
                                    <Tooltip title="Price may vary. Depends on Patient condition">
                                      <h2>
                                        Price -{' '}
                                        <span>
                                          ₹{' '}
                                          {
                                            item?.hospitalSurgeryData
                                              ?.hospitalSurgeryAttributeData
                                              ?.price
                                          }
                                        </span>
                                      </h2>
                                    </Tooltip>
                                  </div>
                                  <div className="treatmentCol">
                                    <Tooltip title="Price may vary. Depends on Patient condition">
                                      <button
                                        onClick={() => {
                                          localStorage.setItem(
                                            'surgeryPrice',
                                            JSON.stringify(
                                              item?.hospitalSurgeryData
                                                ?.hospitalSurgeryAttributeData
                                                ?.price,
                                            ),
                                          )
                                          history.push(
                                            `/hospitalDetailPage/${item._id}`,
                                          )
                                        }}
                                        className="bookNowBtn"
                                      >
                                        Book Now
                                      </button>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <Typography className="contactL" variant="h4">
                  {message}
                </Typography>
                // <div className="text-center mt-3">
                //   <img
                //     src={'../../../../images/no-result.gif'}
                //     alt=""
                //     className="w-50"
                //   />
                // </div>
              )}
            </Stack>

            {paginationData.pageCount > 1 && (
              <Stack
                spacing={2}
                sx={{
                  pt: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Pagination
                  count={paginationData.pageCount || 1}
                  onChange={async (e, page) => {
                    const { data } = await getAllVendorBySurgery({
                      limit,
                      skip: (page - 1) * limit,
                      surgeryId: id,
                      city: selectLocation.name,
                      state: selectLocation.state,
                      district: selectLocation.districtName,
                    })
                    setVendorBySurgery(data.docs)
                  }}
                  color="primary"
                />
              </Stack>
            )}
          </div>
        }
      </section>
    </>
  )
}


const LoadingPage = () => {
  return (
    <div style={{
      display: 'Grid',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src='../../../../images/giphy.gif' alt='' />
    </div>

  )
}

export default ChooseHospital
