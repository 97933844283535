import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import DateFnsUtils from '@date-io/date-fns'
import {
  TextField,
  Autocomplete,
  CircularProgress,
  Chip,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
}

export default function RHFTextField({
  name,
  autocompletecallBack,
  autocompleteMatchingKey,
  defaultValues,
  ...other
}) {
  const { control } = useFormContext()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const result = (await autocompletecallBack?.()) || []
      setOptions(result)
      setLoading(false)
    })()
  }, [open])

  if (other.type === 'dateTime') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                size={'small'}
                renderInput={(params) => (
                  <TextField size={'small'} {...params} />
                )}
                dateAdapter={DateFnsUtils}
                {...field}
                fullWidth
                value={
                  typeof field.value === 'number' && field.value === 0
                    ? ''
                    : field.value
                }
                error={!!error}
                helperText={error?.message}
                {...other}
              />
            </LocalizationProvider>
          )
        }}
      />
    )
  }

  if (other.type === 'date') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              renderInput={(params) => <TextField size={'small'} {...params} />}
              dateAdapter={DateFnsUtils}
              {...field}
              fullWidth
              value={
                typeof field.value === 'number' && field.value === 0
                  ? ''
                  : field.value
              }
              error={!!error}
              helperText={error?.message}
              {...other}
            />
          </LocalizationProvider>
        )}
      />
    )
  }

  if (other.type === 'autocomplete') {
    const { onChange } = other
    delete other.onChange

    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            fullWidth
            open={open}
            onChange={onChange}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            isOptionEqualToValue={(option, value) =>
              option[autocompleteMatchingKey || 'name'] ===
              value[autocompleteMatchingKey || 'name']
            }
            getOptionLabel={(option) =>
              option.code
                ? `${option[autocompleteMatchingKey]} - ${option.code}`
                : option[autocompleteMatchingKey || 'name']
            }
            options={options}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                {...field}
                error={!!error}
                helperText={error?.message}
                label={other.label}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && open ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />
    )
  }

  if (other.type === 'autocompletemutiple') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            multiple
            freeSolo
            defaultValue={defaultValues}
            onChange={(event, newValue) => field.onChange(newValue)}
            options={options.map((option) => option)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option}
                  size="small"
                  label={option}
                />
              ))
            }
            renderInput={(params) => (
              <TextField label={other.label} {...params} />
            )}
          />
        )}
      />
    )
  }

  if (other.type === 'regex') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { onChange, ...rest } = field
          return (
            <TextField
              {...rest}
              fullWidth
              onChange={(e) => {
                if (e.target.value.length < rest.value.length) {
                  onChange(e.target.value)
                }
                if (new RegExp(other.pattern).test(e.target.value)) {
                  onChange(e.target.value)
                }
              }}
              {...other}
            />
          )
        }}
      />
    )
  }

  if (other.type === 'time') {
    // let maxTime = new Date(
    //   0,
    //   0,
    //   0,
    //   parseInt(other.maxTime.split(':')[0]),
    //   parseInt(other.maxTime.split(':')[1]),
    //   59,
    // )
    // let minTime = new Date(
    //   0,
    //   0,
    //   0,
    //   parseInt(other.minTime.split(':')[0]),
    //   parseInt(other.minTime.split(':')[1]),
    //   0,
    // )

    // delete other.maxTime
    // delete other.minTime

    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                size={'small'}
                // maxTime={maxTime}
                // minTime={minTime}
                renderInput={(params) => (
                  <TextField size={'small'} {...params} />
                )}
                dateAdapter={DateFnsUtils}
                {...field}
                fullWidth
                value={
                  typeof field.value === 'number' && field.value === 0
                    ? ''
                    : field.value
                }
                error={!!error}
                helperText={error?.message}
                {...other}
              />
            </LocalizationProvider>
          )
        }}
      />
    )
  }

  if (other.type === 'password') {
    delete other.type;
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            {...other}
            fullWidth
            type={showPassword ? 'text' : 'password'}
            error={!!error}
            helperText={error?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    )
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={
            typeof field.value === 'number' && field.value === 0
              ? ''
              : field.value
          }
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  )
}
