import { api } from '../utils/request'

export const generateOtp = async (mobile) => {
  const res = await api({
    endpoint: `/otp/generateOtp`,
    method: 'POST',
    data: { mobile },
  })
  return res
}

export const register = async (otp, data) => {
  const res = await api({
    endpoint: `/users/register`,
    method: 'POST',
    data: {
      otp: otp,
      ...data,
    },
  })
  return res
}

export const verifyPhoneNumber = async (mobile) => {
  const res = await api({
    endpoint: `/users/checkUserByMobile/${mobile}`,
    method: 'GET',
  })
  return res
}
