import React, { useEffect, useState } from 'react'
import './header.css'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import { useHistory } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList'
import LogoutIcon from '@mui/icons-material/Logout'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import Autocomplete from '@mui/lab/Autocomplete'
import { TextField, Typography } from '@mui/material'
import { getAllDistrict } from '../../../api/locationApi'
import { getAllSurgeryCategory } from '../../../api/surgeryCategory'
import { getAllSurgery } from '../../../api/allSurgery'
import Modal from '@mui/material/Modal'
import { Box, Stack, Avatar, Button } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFTextField } from '../../hook-form'
import withStyles from '@mui/styles/withStyles'
import Iconify from '../../Iconify'
import { lead } from '../../../api/lead'
import IconButton from '@mui/material/IconButton';
// ----------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '25px',
  boxShadow: 24,
  // overflow: 'hidden',
  outline: '0',
}

const CssTextFieldWithNoBorder = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'transparent',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  },
})(TextField)

const Header = () => {
  const selectLocation = JSON.parse(localStorage.getItem('selectLocation'))
  const [surgeryCategory, setSurgeryCategory] = useState([])
  const [surgery, setSurgery] = useState([])
  const [navList, setNavList] = useState(false)
  const [menuList, setMenuList] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [districtList, setDistrictList] = useState([])
  const [value, setValue] = useState(
    selectLocation ? selectLocation.districtName : '',
  )
  const [stateName, setStateName] = useState(
    selectLocation ? selectLocation.state : '',
  )

  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)
  const [openModalCancel, setOpenModalCancel] = React.useState(false)
  const handleOpenModalCancel = () => setOpenModalCancel(true)
  const handleCloseModalCancel = () => setOpenModalCancel(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const open = Boolean(anchorEl)
  const history = useHistory()
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const leadBook = JSON.parse(sessionStorage.getItem('leadBook'))
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    localStorage.clear()
    window.location.replace('/')
    // window.location.reload();
    // setIsLoggedIn(false);
    // history.push("/");
  }

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllSurgeryCategory()
      setSurgeryCategory(data.docs)
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllSurgery()
      setSurgery(data.docs)
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      const { data } = await getAllDistrict()
      setDistrictList(data.docs)
    })()
  }, [])

  const optionList = districtList.map((district) => ({
    label: `${district.name} - ${district.stateName}`,
    value: district.name,
  }))

  const [showLoader, setShowLoader] = useState(false)
  const [showError, setShowError] = useState(false)

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    email: Yup.string().required('Email required'),
    address: Yup.object().shape({
      city: Yup.string(),
      district: Yup.string(),
      state: Yup.string(),
    }),
  })

  const defaultValues = {
    name: '',
    mobile: '',
    email: '',
    address: {
      city: '',
      district: '',
      state: '',
    },
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods
  const values = watch()

  const onSubmit = async (data) => {
    console.log(data)
    const response = await lead(data)
    if (response.code === 201) {
      sessionStorage.setItem('leadBook', JSON.stringify(data))
      alert('Submitted Successfully')
      // window.location.reload()
      handleCloses()
    } else {
      setShowError(true)
      alert(response.message)
    }
    setShowLoader(false)
  }
  const [opens, setOpens] = useState(false)
  const [openM, setOpenM] = useState(true)
  const handleCloses = () => {
    setOpens(false)
    setOpenM(false)
  }

  setTimeout(() => {
    setOpens(true)
  }, 5000)

  const [hover, setHover] = useState(false)

  return (
    <>
      {leadBook ? (
        <></>
      ) : (
        <Modal
          className="homeModal"
          open={openM && opens}
          onClose={handleCloses}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="modalMain">
            <Typography
              className="modalHe"
              sx={{
                px: 4,
                py: 2,
                color: '#212529',
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 600,
                borderRadius: '25px 25px 0 0',
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              <i class="fa-solid fa-phone"></i> Get a call back from Expert.
              <Button className="modalClose" onClick={() => handleCloses()}>
                <i class="fa-solid fa-xmark"></i>
              </Button>
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, px: 4, pb: 2 }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <RHFTextField size="small" name="name" label="Full Name" />
                  <RHFTextField
                    size="small"
                    name="mobile"
                    label="Phone Number"
                  />
                  <RHFTextField size="small" name="email" label="Email" />
                  <RHFTextField size="small" name="address.city" label="Area" />
                  <RHFTextField
                    size="small"
                    name="address.district"
                    label="District"
                  />
                  <RHFTextField
                    size="small"
                    name="address.state"
                    label="State"
                  />
                  <Stack direction={{ xs: 'column', sm: 'row' }}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      className="submitBtn"
                      loading={isSubmitting}
                    >
                      Submit Now <ArrowForwardIcon />
                    </LoadingButton>
                  </Stack>
                </Stack>
              </FormProvider>
            </Typography>
          </Box>
        </Modal>
      )}
      <header>
        <div className="container flex">
          <div className="logo">
            <Link to="" className="m-0">
              <img src="/images/logo.svg" alt="" />
            </Link>
            <IconButton
              onClick={() => {
                history.push(`/`)
              }}
            >
              <Iconify icon={'bx:home'} color={'#009EE0'} />
            </IconButton>
          </div>
          <div className="locationSele">
            <div style={{ border: '1px solid #4e53605e' }} id="locationwrapper">
              <span className="loIocon">
                <Iconify icon={'openmoji:location-indicator-red'} />
              </span>
              <span className="autoCompl">
                <Autocomplete
                  style={{ float: 'left' }}
                  key={districtList.length}
                  name="city"
                  className="font_Loc"
                  size="small"
                  value={optionList.find(
                    (option) =>
                      option.value === value &&
                      option.label.split(' - ')[1] === stateName,
                  )}
                  id="combo-box-demo"
                  options={optionList}
                  onChange={(event, newValue) => {
                    setValue(newValue.value)
                    setStateName(newValue.label.split(' - ')[1])
                    localStorage.setItem(
                      'selectLocation',
                      JSON.stringify({
                        districtName: newValue.value,
                        state: newValue.label.split(' - ')[1],
                      }),
                    )

                    if (window.onLocationChange) {
                      window.onLocationChange()
                    }
                  }}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <CssTextFieldWithNoBorder
                      {...params}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       <LocationOnIcon />
                      //     </InputAdornment>
                      //   ),
                      // }}
                      label="Select Dist. / State"
                    />
                  )}
                />
              </span>
            </div>
          </div>
          <div className="navBar">
            <div className="nav">
              <ul

                className={navList ? 'small' : 'flex'}>
                <li
                  onMouseEnter={() => {
                    setHover(true)
                  }}
                  onMouseLeave={() => {
                    setHover(false)
                  }}

                  className="megamenu mobileDiNone">
                  <Link

                  >Surgeries</Link>
                  <div
                    onMouseLeave={() => {
                      setHover(false)
                    }}
                    className={hover === false ? "menuList dNone" : "menuList dBlock"}>
                    <div className="menuItem">
                      {surgeryCategory.map((item) => (
                        <div className="menuItemList">
                          <h4>{item.name}</h4>
                          <ul>
                            {/* {surgery.map((surgery) => (
                              surgery.surgeryCategoryId === item._id && <li><Link to={`/hospitalDetailPage`}>{surgery.name}</Link></li>
                            ))} */}
                            {surgery.map(
                              (surgery) =>
                                surgery.surgeryCategoryId === item._id && (
                                  <li>
                                    <Link
                                      onClick={() => {

                                        setNavList(false)
                                        const location = JSON.parse(
                                          localStorage.getItem(
                                            'selectLocation',
                                          ),
                                        )
                                        if (!location) {
                                          handleOpenModal()
                                        } else {
                                          history.push(
                                            `/chooseHospital/${surgery._id}`,
                                          )
                                          setHover(false)
                                          localStorage.setItem(
                                            'subSurgeryId',
                                            JSON.stringify(surgery._id),
                                          )
                                        }
                                      }}
                                    >
                                      {surgery.name}
                                    </Link>
                                  </li>
                                ),
                            )}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </li>
                <li className="menu mobileDiNone">
                  <Link to="/">Labs</Link>
                  <ul>
                    <li>
                      <Link
                        // to="/testListing"
                        onClick={() => {
                          setNavList(false)
                          const location = JSON.parse(
                            localStorage.getItem('selectLocation'),
                          )
                          if (!location) {
                            handleOpenModal()
                          } else {
                            history.push(`/testListing`)
                          }
                        }}
                      >
                        Pathology
                      </Link>
                    </li>
                    <li>
                      <Link
                        // to="/radiologyListing"
                        onClick={() => {
                          setNavList(false)
                          const location = JSON.parse(
                            localStorage.getItem('selectLocation'),
                          )
                          if (!location) {
                            handleOpenModal()
                          } else {
                            history.push(`/radiologyListing`)
                          }
                        }}
                      >
                        Radiology
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="mobileDiNone">
                  <Link
                    // to="/clinic"
                    onClick={() => {
                      setNavList(false)
                      const location = JSON.parse(
                        localStorage.getItem('selectLocation'),
                      )
                      if (!location) {
                        handleOpenModal()
                      } else {
                        history.push(`/clinic`)
                      }
                    }}
                  >
                    Clinics
                  </Link>
                </li>
                <li className="menu mobileDiNone">
                  <Link
                    // to="/ayurveda"
                    onClick={() => {
                      setNavList(false)
                      const location = JSON.parse(
                        localStorage.getItem('selectLocation'),
                      )
                      if (!location) {
                        handleOpenModal()
                      } else {
                        history.push(`/ayurveda`)
                      }
                    }}
                  >
                    Ayurveda
                  </Link>
                  {/* <ul>
                    <li>
                      <Link to='/ayurveda' onClick={() => { setNavList(false) }}>Fever</Link>
                    </li>
                    <li>
                      <Link to='/ayurveda' onClick={() => { setNavList(false) }}>Lungs</Link>
                    </li>
                    <li>
                      <Link to='/ayurveda' onClick={() => { setNavList(false) }}>Cat Treatment</Link>
                    </li>
                    <li>
                      <Link to='/ayurveda' onClick={() => { setNavList(false) }}>Liver Traetment</Link>
                    </li>
                    <li>
                      <Link to='/ayurveda' onClick={() => { setNavList(false) }}>Piles Treatment</Link>
                    </li>
                  </ul> */}
                </li>
                <li>
                  <Link
                    // to="/bloodBank"
                    onClick={() => {
                      setNavList(false)
                      const location = JSON.parse(
                        localStorage.getItem('selectLocation'),
                      )
                      if (!location) {
                        handleOpenModal()
                      } else {
                        history.push(`/bloodBank`)
                      }
                    }}
                  >
                    Blood Bank
                  </Link>
                </li>
                <li>
                  <Link
                    to="/packages"
                    onClick={() => {
                      setNavList(false)
                    }}
                  >
                    Top Packages
                  </Link>
                </li>
                <li className="bbb">
                  {userInfo ? (
                    <></>
                  ) : (
                    <Link to="/signIn">
                      <img src="/images/login.svg" alt="" />
                      Login
                    </Link>
                  )}
                </li>
                {/* <li className="bbb">
                  {
                    userInfo ? (
                      <></>
                    ) : (
                      <Link to='/signRegister' onClick={() => { setNavList(false) }}>Register</Link>
                    )
                  }
                </li> */}
                {userInfo ? (
                  <li className="myProfileBtn bbb">
                    <Link
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <Avatar
                        alt={userInfo.userName}
                        src="/static/images/avatar/3.jpg"
                      />
                    </Link>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setNavList(false)
                          handleClose(false)
                        }}
                      >
                        <div className="profile_sec">
                          <img src="/images/avatar.jpeg" alt="" />
                          <div>
                            <p>Hello,</p>
                            <span className="ml-2">{userInfo.userName}</span>
                            <p>
                              <Link to="/editProfile">
                                View and edit profile
                              </Link>
                            </p>
                          </div>
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setNavList(false)
                          handleClose(false)
                        }}
                        className="myAccountList"
                      >
                        <Link to="/bookingHistory">
                          <FeaturedPlayListIcon />
                          My Booking History
                        </Link>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setNavList(false)
                          handleOpenModalCancel()
                        }}
                        className="myAccountList"
                      >
                        <Box>
                          <FeaturedPlayListIcon />
                          Cancel Booking
                        </Box>
                      </MenuItem>
                      <Modal
                        open={openModalCancel}
                        onClose={handleCloseModalCancel}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              cursor: 'pointer',
                              p: 4,
                            }}
                          >
                            <strong>To Cancel Your Booking : </strong>
                            <Typography sx={{ pt: 2 }} variant={'p'}>
                              Please keep ready Your Patient ID , Registered
                              Mobile no. , Surgery booking Date and Surgery Date
                              ...
                            </Typography>
                            <a
                              href="tel:+918004604946"
                              className="mt-3 text-center"
                            >
                              <i class="fa-solid fa-phone mx-2"></i>
                              8004604946
                            </a>
                          </Box>
                        </Box>
                      </Modal>
                      <MenuItem
                        onClick={() => {
                          setNavList(false)
                          handleClose(false)
                        }}
                        className="myAccountList"
                      >
                        <Link to="/changePassword">
                          <LockPersonIcon />
                          Change Password
                        </Link>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setNavList(false)
                          handleClose(false)
                        }}
                        className="myAccountList"
                      >
                        <Link
                          to="/"
                          onClick={() => {
                            handleLogout()
                            // history.push("/");
                          }}
                        >
                          <LogoutIcon />
                          Logout
                        </Link>
                      </MenuItem>
                    </Menu>
                  </li>
                ) : (
                  <></>
                )}
                <li>
                  <div className="btnJoin">
                    <Link
                      to="/joinUs"
                      className="btnJoinNow"
                      onClick={() => {
                        setNavList(false)
                      }}
                    >
                      Join us as Business
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="toggle">
            <button onClick={() => setNavList(!navList)}>
              {navList ? (
                <i className="fa fa-times"></i>
              ) : (
                <i className="fa fa-bars"></i>
              )}
            </button>
          </div>
        </div>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                cursor: 'pointer',
                p: 1,
                alignItems: 'center',
              }}
            >
              <Typography variant={'h5'}>Please Select Location </Typography>
              <Button
                sx={{
                  mt: 2,
                  width: '100px',
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleCloseModal()
                }}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Modal>
      </header>
      <div id="mobileHeaderC">
        <div className="container">
          <div className="navBar" id="MobileMen">
            <div className="nav">
              <ul className="flex">
                <li
                  onMouseEnter={() => {
                    setHover(true)
                  }}

                  className="megamenu">
                  <Link

                  >Surgeries</Link>
                  <div
                    onMouseLeave={() => {
                      setHover(false)
                    }}
                    className={hover === false ? "menuList dNone" : "menuList dBlock"}>
                    <div className="menuItem">
                      {surgeryCategory.map((item) => (
                        <div className="menuItemList">
                          <h4>{item.name}</h4>
                          <ul>
                            {/* {surgery.map((surgery) => (
                              surgery.surgeryCategoryId === item._id && <li><Link to={`/hospitalDetailPage`}>{surgery.name}</Link></li>
                            ))} */}
                            {surgery.map(
                              (surgery) =>
                                surgery.surgeryCategoryId === item._id && (
                                  <li>
                                    <Link
                                      onClick={() => {

                                        setNavList(false)
                                        const location = JSON.parse(
                                          localStorage.getItem(
                                            'selectLocation',
                                          ),
                                        )
                                        if (!location) {
                                          handleOpenModal()
                                        } else {
                                          history.push(
                                            `/chooseHospital/${surgery._id}`,
                                          )
                                          setHover(false)
                                          localStorage.setItem(
                                            'subSurgeryId',
                                            JSON.stringify(surgery._id),
                                          )
                                        }
                                      }}
                                    >
                                      {surgery.name}
                                    </Link>
                                  </li>
                                ),
                            )}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </li>
                <li className="menu">
                  <Link to="/">Labs</Link>
                  <ul>
                    <li>
                      <Link
                        onClick={() => {
                          setNavList(false)
                          const location = JSON.parse(
                            localStorage.getItem('selectLocation'),
                          )
                          if (!location) {
                            handleOpenModal()
                          } else {
                            history.push(`/testListing`)
                          }
                        }}
                      >
                        Pathology
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setNavList(false)
                          const location = JSON.parse(
                            localStorage.getItem('selectLocation'),
                          )
                          if (!location) {
                            handleOpenModal()
                          } else {
                            history.push(`/radiologyListing`)
                          }
                        }}
                      >
                        Radiology
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nnnnn">
                  <Link
                    onClick={() => {
                      setNavList(false)
                      const location = JSON.parse(
                        localStorage.getItem('selectLocation'),
                      )
                      if (!location) {
                        handleOpenModal()
                      } else {
                        history.push(`/clinic`)
                      }
                    }}
                  >
                    Clinics
                  </Link>
                </li>
                <li className="menu">
                  <Link
                    onClick={() => {
                      setNavList(false)
                      const location = JSON.parse(
                        localStorage.getItem('selectLocation'),
                      )
                      if (!location) {
                        handleOpenModal()
                      } else {
                        history.push(`/ayurveda`)
                      }
                    }}
                  >
                    Ayurveda
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Header
