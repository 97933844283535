import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextField, Typography } from '@mui/material'
import { Stack, IconButton, InputAdornment, Grid } from '@mui/material'
import img from '../images/bannerimg.jpg'
import Back from '../common/Back'
import { Modal, Box, Button, Checkbox } from '@mui/material'
import { Link } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import Autocomplete from '@mui/lab/Autocomplete'
import Iconify from '../Iconify'
import {
  FormProvider,
  RHFTextField,
  RHFUploadAvatar,
  RHFSelect,
} from '../hook-form'
import './signRegister.css'
import 'bootstrap/dist/css/bootstrap.css'
import { register, generateOtp, verifyPhoneNumber } from '../../api/register'
import { fileUpload } from '../../utils/request'

import {
  getAllState,
  getDistrictByState,
  getCityByDistrict,
} from '../../api/locationApi'
// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
}

export default function RegisterForm() {
  const history = useHistory()
  const [isCheck, setIsCheck] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [image, setImage] = useState('')
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [otp, setOtp] = useState(0)
  const [data, setData] = useState([])
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [isMobileAlreadyExist, setIsMobileAlreadyExist] = useState(false)
  useEffect(() => {
    ; (async () => {
      const { data } = await getAllState()
      setStateList(data.docs)
    })()
  }, [])

  const RegisterSchema = Yup.object().shape({
    userName: Yup.string().required('User Name required'),
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    email: Yup.string().email('Email must be a valid email address'),
    password: Yup.string()
      .min(6, 'minimum length 6 chars')
      .required('Password is required'),
    address: Yup.object().shape({
      houseNo: Yup.string(),
      streetNo: Yup.string(),
      locality: Yup.string(),
      landmark: Yup.string(),
      city: Yup.string(),
      district: Yup.string(),
      state: Yup.string().required('State is required'),
      pinCode: Yup.string()
        .matches(/^[1-9][0-9]{5}$/, 'Must be a valid Pin code')
        .required('Pin Code is required'),
    }),
  })

  const defaultValues = {
    userName: '',
    mobile: '',
    email: '',
    address: {
      houseNo: '',
      streetNo: '',
      locality: '',
      landmark: '',
      city: '',
      district: '',
      state: '',
      pinCode: '',
    },
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods
  const values = watch()

  const onSubmit = async (data) => {
    if (isCheck) {
      if (isMobileAlreadyExist) {
        alert('User Already Exist. Please change mobile number')
        return
      }
      setData(data)
      const response = await generateOtp(data.mobile)
      if (response?.code === 201) {
        handleOpen()
      } else {
        alert(response.message)
      }
    } else {
      alert('Please Accept Terms and Conditions first')
    }

    setShowLoader(false)
  }

  useEffect(() => {
    ; (async () => {
      const { data: district } = await getDistrictByState(values.address.state)
      setDistrictList(district.docs)
    })()
  }, [values.address.state])

  useEffect(() => {
    ; (async () => {
      const { data: city } = await getCityByDistrict(values.address.district)
      setCityList(city.docs)
    })()
  }, [values.address.district])

  const verify = async () => {
    const response = await register(otp, data)
    console.log(data)
    if (response?.data?.token) {
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      history.push('/')
      window.location.reload()
    } else {
      alert(response.message)
    }
  }

  useEffect(() => {
    ; (async () => {
      if (values.mobile.length === 10) {
        const resp = await verifyPhoneNumber(values.mobile)
        console.log(resp)
        if (resp.data === "user exists") {
          alert('User Already Exist. Please change mobile number')
          setIsMobileAlreadyExist(true)


        } else {
          console.log("user not exist")
        }
      }
    })()
  }, [values.mobile])

  const setScroll = () => {
    window.scrollTo(0, localStorage.getItem('position'))
  }

  return (
    <section className="signUpp">
      <Back title="Register" cover={img} />
      <section className="formBg py-5">
        <div className="container row justify-content-center">
          <div className="col-md-12 b-x p-5">
            <div className="row">
              <div className="col-md-6 register_page">
                <div className="maintitle pb-3">Sign up to Account</div>
                <p>Welcome back ! Please enter your details for continue</p>
                <Box sx={{ width: '100%' }}>
                  <FormProvider
                    methods={methods}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Stack spacing={3} className="py-4">
                      <Typography>Basic details</Typography>
                      <RHFTextField
                        size="small"
                        name="userName"
                        label="User Name"
                      />
                      <RHFTextField size="small" name="email" label="Email" />
                      <RHFTextField
                        size="small"
                        name="mobile"
                        label="Phone Number"

                      />
                      <RHFTextField
                        type="password"
                        size="small"
                        name="password"
                        label="Password (min 6 chars)"
                      />
                      <Typography>Address details</Typography>
                      <Stack direction="row" spacing={2}>
                        {' '}
                        <RHFTextField
                          size="small"
                          name="address.houseNo"
                          label="House No"
                        />
                        <RHFTextField
                          size="small"
                          name="address.streetNo"
                          label="Street/Road"
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField
                          size="small"
                          name="address.landmark"
                          label="Landmark"
                        />

                        <RHFTextField
                          size="small"
                          name="address.locality"
                          label="Locality"
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Autocomplete
                          name="address.state"
                          size="small"
                          id="combo-box-demo"
                          options={stateList}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            setValue('address.state', newValue.name)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="State" />
                          )}
                        />

                        <Autocomplete
                          name="address.district"
                          size="small"
                          id="combo-box-demo"
                          options={districtList}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            setValue('address.district', newValue.name)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="District" />
                          )}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Autocomplete
                          name="address.city"
                          size="small"
                          id="combo-box-demo"
                          options={cityList}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            setValue('address.city', newValue.name)
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField {...params} label="Area" />
                          )}
                        />

                        <RHFTextField
                          size="small"
                          name="address.pinCode"
                          label="PinCode"
                          type="number"
                        />
                      </Stack>

                      <div className='font-12'>
                        <Checkbox size="small"
                          onChange={(e) => {
                            setIsCheck(e.target.value)
                          }}
                        />
                        I've read and accept the
                        <Link
                          className="px-1"
                          to='/termsConditions'
                          onClick={() => {
                            setScroll()
                          }}
                        >Terms and Conditions</Link>,
                        <Link
                          className="px-1"
                          to='/privacyPolicy'
                          onClick={() => {
                            setScroll()
                          }}
                        >Privacy Policy</Link>,
                        <Link
                          className="px-1"
                          to='/refundPolicy'
                          onClick={() => {
                            setScroll()
                          }}
                        >Refund Policy</Link>
                      </div>

                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        className="signBtn"
                        loading={isSubmitting}
                      >
                        Sign Up
                      </LoadingButton>
                      <div>
                        <Modal
                          className="modalL"
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style} className="changeModal container">
                            <h4 className="text-center">Verify Otp</h4>
                            <hr></hr>
                            <section className="pt-3 loginPage">
                              <div className="container">
                                <Box sx={{ width: '100%' }}>
                                  <FormProvider className="text-center">
                                    <div className="otpSec2">
                                      <input
                                        type="text"
                                        placeholder="OTP"
                                        onChange={(e) => setOtp(e.target.value)}
                                      />
                                    </div>
                                    <div className="text-center">
                                      <Button
                                        className="otpBtn"
                                        onClick={() => verify()}
                                      >
                                        Verify
                                      </Button>
                                    </div>
                                  </FormProvider>
                                </Box>
                              </div>
                            </section>
                          </Box>
                        </Modal>
                      </div>
                    </Stack>
                  </FormProvider>
                </Box>
              </div>
              <div className="col-md-6">
                {/* <img src={"../../../images/register.png"} alt='' /> */}
                <div className="signleftSection">
                  <img
                    src={'../../../images/bg_1.svg'}
                    className="bg_1"
                    alt=""
                  />
                  <img
                    src={'../../../images/bg_2.svg'}
                    className="bg_2"
                    alt=""
                  />
                  <img
                    src={'../../../images/bg_3.svg'}
                    className="bg_3"
                    alt=""
                  />
                  <div className="needHelpSty text-center">
                    <div className="title">Hello, Friend!</div>
                    <h6>
                      Fill up personal information and<br></br> start journey
                      with us.
                    </h6>
                    <div className='mt-5'>
                      <Link to="/signin" className="signIn">Sign In</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}
