import { Stack } from '@mui/system'
import * as Yup from 'yup'
import React, { useEffect, useState, useCallback } from 'react'
import Back from '../common/Back'
import { styled, alpha } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, RHFTextField } from '../hook-form'
import { LoadingButton } from '@mui/lab'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import img from '../images/bannerimg.jpg'
import './clinicDetailPage.css'
import { MenuItem, Button, Menu, Box, Item, Modal } from '@mui/material'
import { Link } from 'react-router-dom'
import { useParams, useHistory } from 'react-router-dom'
import { getSingleVender } from '../../api/singleVendor'
import { getAllBanner } from '../../api/banner'
import { login, generateOtp } from '../../api/login'
import { getAllSpeciality } from '../../api/speciality'
// -------------------------------------------------------
const topSurgery = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 3,
}
const ClinicDetailPage = () => {
  const [singleTreatment, setSingleTreatment] = useState([])
  const [specialityList, setSpecialityList] = useState([])
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const selectLocation = JSON.parse(localStorage.getItem('selectLocation'))
  const params = useParams()
  const history = useHistory()
  useEffect(() => {
    ; (async () => {
      const { data } = await getAllSpeciality()
      setSpecialityList(data.docs)
    })()
  }, [])
  const [showLoader, setShowLoader] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open2 = Boolean(anchorEl)
  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose2 = () => {
    setAnchorEl(null)
  }
  const [open, setOpen] = React.useState(false)
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const [childModalOpen, setChildModalOpen] = React.useState(false)
  const handleChildModalOpen = () => {
    setChildModalOpen(true)
  }
  const handleChildModalClose = () => {
    setChildModalOpen(false)
  }
  const [otp, setOtp] = useState(0)
  const [data, setData] = useState([])

  const RegisterSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[5-9]{1}[0-9]{9}$/, 'Must be valid mobile number')
      .required('Mobile is required'),
    password: Yup.string(),
  })

  const defaultValues = {
    mobile: '',
    password: '',
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,

    formState: { isSubmitting },
  } = methods
  const values = watch()

  const [clinicBooking, setClinicBooking] = useState({
    name: '',
    mobile: '',
    address: {
      houseNo: '',
      streetNo: '',
      locality: '',
      landmark: '',
      city: '',
      district: '',
      state: '',
      pinCode: '',
    },
    treatment: '',
    price: '',
    consultationTime: '',
    status: 'pending',
    paymentStatus: 'unpaid',
    couponCode: '',
    remark: '',
    discountPrice: '',
    vendorId: '',
    slotTo: '',
    slotFrom: '',
  })

  const [url, setUrl] = useState()
  const [isloading, setIsLoading] = useState(true)
  useEffect(() => {
    ; (async () => {
      const { data } = await getAllBanner()
      const tempBanner = data.docs.find((banner) => {
        return banner.page === 'clinicDetail'
      })
      setUrl(tempBanner?.url)
      console.log(tempBanner)
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      const { data } = await getSingleVender(params.id)
      setSingleTreatment(data)
      setIsLoading(false)
    })()
  }, [])

  const getYear = () => {
    const currentYear = new Date().getFullYear()
    return currentYear
  }

  const onSubmit = async (data) => {
    console.log(data)
    setData(data)
    const response = await login(data)
    console.log(data)
    if (response?.data?.token) {
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      window.location.reload()
      // window.location.reload()
      handleClose()
      handleChildModalClose()
    } else {
      alert(response.message)
    }
    setShowLoader(false)
  }

  const verify = async () => {
    const response = await login(otp, data)
    console.log(data)
    if (response?.data?.token) {
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      localStorage.setItem('token', response.data.token)
      window.location.reload()
      // window.location.reload()
      handleClose()
      handleChildModalClose()
    } else {
      alert(response.message)
    }
  }

  return (
    <>
      <section className="clinicDetailPage">
        <Back
          name="Clinic Detail Page"
          title="FIND A BEST CLINIC FOR AN APPOINTMENT"
          cover={url || img}
        />
        {isloading ? <LoadingPage /> :
          <div className="container py-5">
            <div className="row pb-3">
              <div className="col-md-9">
                <h3 className="pageTitle">{singleTreatment.name}</h3>
                <h4 className="pageTitle">
                  {singleTreatment.doctorName +
                    '-' +
                    singleTreatment.highestDegree}
                </h4>
                <p>{singleTreatment.description}</p>
                <h3 className="titleClinic">
                  Specialist{' '}
                  <span>
                    ({' '}
                    {
                      specialityList?.find(
                        (item) => item._id === singleTreatment?.specialist,
                      )?.name
                    }{' '}
                    )
                  </span>
                </h3>
                <div className="clinicDt">
                  <div className="packageDe">
                    <div className="iconSec">
                      <img src="/images/bag.png" alt="" />
                    </div>
                    <p>
                      {getYear() - singleTreatment.yearOfEstablishment} Year
                      Experience
                    </p>
                  </div>
                  {/* <div className="packageDe">
                  <div className="iconSec">
                    <img src="/images/hospital_icon.png" alt="" />
                  </div>
                  <p>Working with Area hospital</p>
                </div> */}
                </div>
                <h5 className="titleFacilities">Facilities -</h5>
                <div className="facilitiesList">
                  {singleTreatment?.facilityData?.map((item, index) => (
                    <div className="row py-1">
                      <div className="col-md-6">
                        <h6>{item.name}</h6>
                      </div>
                      <div className="col-md-6">
                        <p className="availableColor">Available</p>
                      </div>
                    </div>
                  ))}
                </div>
                <h5 className="titleFacilities">Address -</h5>
                <div>
                  <p>
                    {/* <div className="py-1 d-flex align-items-center">
                    <div className="pr">Locality - </div>{singleTreatment?.address?.locality}
                  </div> */}
                    <div className="py-1 d-flex align-items-center">
                      <div className="pr">Pincode - </div>
                      {singleTreatment?.address?.pinCode}
                    </div>
                    <div className="py-1 d-flex align-items-center">
                      <div className="pr">Area - </div>
                      {singleTreatment?.address?.city}
                    </div>
                    <div className="py-1 d-flex align-items-center">
                      <div className="pr">District - </div>
                      {singleTreatment?.address?.district}
                    </div>
                    <div className="py-1 d-flex align-items-center">
                      <div className="pr">State - </div>
                      {singleTreatment?.address?.state}
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="detailBook">
                  <h2>
                    Price - <span>₹ {singleTreatment.price}</span>
                  </h2>
                  {userInfo ? (
                    <Button
                      className="bookNowBtn"
                      onClick={() => {
                        const clinicBookingDetails = {
                          ...clinicBooking,
                          price: singleTreatment.price,
                          status: 'pending',
                          paymentStatus: 'unpaid',
                          couponCode: '',
                          remark: '',
                          paymentType: 'postpaid',
                          discountPrice: singleTreatment.price,
                          vendorId: singleTreatment._id,
                        }
                        setClinicBooking(clinicBookingDetails)
                        localStorage.setItem(
                          'clinicBooking',
                          JSON.stringify(clinicBookingDetails),
                        )
                        history.push('/clinicBooking')
                      }}
                    >
                      Book Now
                    </Button>
                  ) : (
                    <div>
                      <button
                        className="bookNowBtn"
                        onClick={() => {
                          handleOpen()
                        }}
                      >
                        Please Login
                      </button>
                      <Modal
                        className="modalL"
                        open={open}
                        onClose={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          handleClose()
                        }}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        <Box sx={{ ...style, width: 400 }}>
                          <FormProvider
                            methods={methods}
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <Stack spacing={3} className="py-4">
                              <RHFTextField
                                type="number"
                                size="small"
                                name="mobile"
                                label="Mobile"
                              />
                              <RHFTextField
                                type="password"
                                size="small"
                                name="password"
                                label="Password"
                              />
                              <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                className="signBtn"
                                loading={isSubmitting}
                              >
                                Login
                              </LoadingButton>
                              <div className="text-center">
                                <p>
                                  Don't have an account?{' '}
                                  <span
                                    style={{
                                      color: '#FF8021',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      history.push('/signRegister')
                                    }}
                                  >
                                    Sign Up
                                  </span>
                                </p>
                              </div>
                              <div className='text-center'>
                                <Link to="/forgotPassword">Forgot Password?</Link>
                              </div>
                            </Stack>
                          </FormProvider>
                        </Box>
                      </Modal>
                      <Modal
                        open={childModalOpen}
                        onClose={handleChildModalClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                      >
                        <Box className="changeChildModal">
                          <FormProvider className="text-center">
                            <div className="otpSec2">
                              <input
                                type="text"
                                placeholder="OTP"
                                onChange={(e) => setOtp(e.target.value)}
                              />
                            </div>
                            <div className="text-center">
                              <Button className="otpBtn" onClick={() => verify()}>
                                Login
                              </Button>
                            </div>
                          </FormProvider>
                        </Box>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Carousel
              responsive={topSurgery}
              infinite={true}
              // autoPlay={true}
              autoPlaySpeed={2000}
            >
              <div
                className="content"
                onClick={() => {
                  history.push('/packages')
                }}
              >
                <div className="box">
                  <div className="book_rightSec">
                    <div className="p-2">
                      <img src="/images/hospital_icon.png" alt="" />
                      <p>Blood Lab Tests From Top and Trusted Brands</p>
                    </div>
                    <div className="bookingfooter">
                      <p>Offers</p>
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Lab Test</h4>
                    <h5># 01</h5>
                    <h6>Total Checkup Solutions for your Health.</h6>
                  </div>
                </div>
              </div>
              <div
                className="content"
                onClick={() => {
                  history.push('/ayurveda')
                }}
              >
                <div className="box">
                  <div className="book_rightSec">
                    <div className="p-2">
                      <img src="/images/hospital_icon.png" alt="" />
                      <p>
                        Now Begin Your Journey Towards Natural Health By Top and Trusted Ayurvedic Treatment Clinics
                      </p>
                    </div>
                    <div className="bookingfooter">
                      <p>Ayurvedic Treatment</p>
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Ayurvedic Treatment</h4>
                    <h5># 02</h5>
                    <h6>Top and Trusted Ayurvedic Brand In your City</h6>
                  </div>
                </div>
              </div>
              <div
                className="content"
                onClick={() => {
                  history.push('/ayurveda')
                }}
              >
                <div className="box">
                  <div className="book_rightSec">
                    <div className="p-2">
                      <img src="/images/hospital_icon.png" alt="" />
                      <p>Consult India's Top Doctors Dieticians From the comforts of your home.</p>
                    </div>
                    <div className="bookingfooter">
                      <p>Nearest</p>
                    </div>
                  </div>
                  <div className="slideText">
                    <h4>Book Nearest Clinic</h4>
                    <h5># 03</h5>
                    <h6>Book Your Specialist Doctors and Dieticians</h6>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        }
      </section>
    </>
  )
}
const LoadingPage = () => {
  return (
    <div style={{
      display: 'Grid',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src='../../../../images/giphy.gif' alt='' />
    </div>

  )
}
export default ClinicDetailPage
