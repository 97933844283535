import React from "react";
import Back from "../common/Back";
import Heading from "../common/Heading";
import img from "../images/bannerimg.jpg";
import "./refundPolicy.css";

const RefundPolicy = () => {
  return (
    <>
      <section className="privacyPolicy">
        <Back name="Refund Policy" title="Refund Policy" cover={img} />
        <div className="container row py-4">
          <div className="left col-md-12">
            <Heading title="Refund Policy" />
            <strong>VAJ HEALTHTECH PRIVATE LIMITED ( clickyourhospital.com )   believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:-</strong>
            <ul className="listRefund">
              <li>Cancellations will be considered only if the request is made immediately after booking any services. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of serve them.</li>
              <li>If any misshapen done with your patient and with you during your treatment/surgery in our network Hospital/Lab/Clinic in case of this we or any of our team member will not be responsible for it and any refund will not be initiated.</li>
              <li>If you have booked any of our services with any of these discount/offer/or any campaign then any refund will not be initiated.</li>
              <li>In case you feel that the services you are getting by first day is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within same day of starting your services. The Customer Service Team after looking into your complaint will take an appropriate decision.</li>
              <li>In case of complaints regarding any of our network hospital/lab/clinic that come with any commitment of special services or any offered products from hospital/lab/clinic management/team, please refer the issue to them.</li>
              <li>In case of any Refunds approved by the VAJ HEALTHTECH PRIVATE LIMITED (clickyourhospital.com) , it’ll take 10-15 working days for the refund to be processed to the end customer. You will be issued a full refund of your purchase within 10-15 working days of approval. You will receive the refund through the same payment method, you used to make the purchase *( If any payments is deposited at hospital/lab/clinic counter in any mode of payment, will not be refund by our side, we only can do a request to the respected hospital/lab/clinic.</li>
              <li>If any reason, we are unable to refund via your initial payment method, we will provide the value in equivalent credit or provide an alternate method of refund.</li>
              <li>Refunds are designed to remove the risk from purchasing products or services not as a way to get free products and services. If it appears to us that you are abusing refunds we may stop offering them to you.</li>
              <li>You can request a refund or get other assistance with your purchase at our support team.</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default RefundPolicy;
