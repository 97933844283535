import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Box } from '@mui/material'
import img from '../images/bannerimg.jpg'
import Back from '../common/Back'
import dayjs from 'dayjs'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { LoadingButton } from '@mui/lab'
import { FormProvider, RHFTextField } from '../hook-form'
import './payment.css'
import 'bootstrap/dist/css/bootstrap.css'
import { getAvailability } from '../../api/availability'
import { createSurgeryBooking } from '../../api/bookings'
// ----------------------------------------------------------------------

export default function Payment() {
  const surgeryBooking = JSON.parse(localStorage.getItem('surgeryBooking'))
  const bookSurgery = JSON.parse(localStorage.getItem('bookSurgery'))
  const [value, setValue] = React.useState(new Date())
  const history = useHistory()
  const [availability, setAvailability] = useState([])
  const [isAvailabilityLoaded, setIsAvailabilityLoaded] = useState(false)

  const RegisterSchema = Yup.object().shape({
    date: Yup.string(),
  })

  const defaultValues = {
    date: '',
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = methods
  const values = watch()

  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    ;(async () => {
      const { data } = await getAvailability({
        month: currentMonth,
        year: currentYear,
        vendorId: bookSurgery.vendorId,
      })
      setAvailability(data)
      setIsAvailabilityLoaded(true)
    })()
  }, [])

  const onSubmit = async (data) => {
    bookSurgery.surgeryDate = new Date(value).toISOString()

    const isUnavailable = availability.find((unavaialabledate) => {
      return (
        new Date(unavaialabledate).toLocaleDateString() ===
        new Date(value).toLocaleDateString()
      )
    })
    if (isUnavailable) {
      alert('Date is not available')
      return
    }

    const response = await createSurgeryBooking(bookSurgery)
    if (response?.code === 201) {
      alert('Booking Successful')
      history.push('/')
    } else {
      alert(response.message)
    }
  }

  return (
    <section className="signInn">
      <Back title="Payment" cover={img} />
      <section className="formBg">
        <div className="container row justify-content-center">
          <div className="col-md-6 b-x p-4 register_page">
            <div className="maintitle pb-3">Payment</div>
            <p>Welcome back ! Please enter your details for continue</p>
            <Box sx={{ width: '100%' }}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3} className="py-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                      onMonthChange={(date) => {
                       
                        const month = new Date(date).getMonth() + 1
                        const year = new Date(date).getFullYear()
                        console.log(month, year)
                        ;(async () => {
                          const { data } = await getAvailability({
                            month: month,
                            year:  year,
                            vendorId: bookSurgery.vendorId,
                          })
                          setAvailability(data)
                        })()
                      }}
                      displayStaticWrapperAs="desktop"
                      openTo="day"
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue)
                      }}
                      disablePast
                      shouldDisableDate={(date) => {
                        return availability.find(
                          (unavaialabledate) =>
                            new Date(unavaialabledate).toLocaleDateString() ===
                            new Date(date).toLocaleDateString(),
                        )
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  {/* {surgeryBooking ? (
                    <></>
                  ) : (
                    <RHFTextField name="time" label="Time" type="time" placeholder="00/00" InputLabelProps={{ shrink: true }} />
                  )} */}
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    className="signBtn"
                    loading={!isAvailabilityLoaded || isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </FormProvider>
            </Box>
          </div>
        </div>
      </section>
    </section>
  )
}
